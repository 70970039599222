<template lang="pug">
include index.pug
</template>

<script>
export default {
  name: 'SwitchButton',
  props: {
    injectedValue: Boolean,
    onChange: Function,
    yesLabel: {
      type: String,
      default: 'JA',
      required: false
    },
    noLabel: {
      type: String,
      default: 'NEJ',
      required: false
    }
  }
}
</script>
