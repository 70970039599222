var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _vm.step === _vm.steps.ConnectDevice
        ? [
            _c("connect-device", {
              staticClass: "before-bottom-nav",
              attrs: { municipalityCode: _vm.municipalityCode },
            }),
            _c("navigation-buttons", {
              staticClass: "bottom-nav mt-2",
              attrs: {
                showNext: false,
                onPreviousClick: _vm.onPreviousStepClicked,
              },
            }),
          ]
        : _vm.step === _vm.steps.AwaitingPhotos
        ? [
            _c("awaiting-photos", { staticClass: "before-bottom-nav" }),
            _c("navigation-buttons", {
              staticClass: "bottom-nav mt-2",
              attrs: {
                showNext: false,
                onPreviousClick: _vm.onPreviousStepClicked,
                previousLabel: "Afbryd fotografering",
              },
            }),
          ]
        : _vm.step === _vm.steps.PhotosReceived
        ? [
            _c("photos-received", { staticClass: "before-bottom-nav" }),
            _c("navigation-buttons", {
              staticClass: "bottom-nav mt-2",
              attrs: {
                showPrevious: false,
                onNextClick: _vm.onNextStepClicked,
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }