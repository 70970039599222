<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
export default {
  name: 'EmailInput',
  props: {
    callback: Function,
    injectedValue: {
      type: String,
      default: ''
    }
  },
  Inject: ['$validator'],
  computed: {
    errorMessage: function () {
      return this.errors.first('emailinputfield') === null ? '-' : this.errors.first('emailinputfield')
    },
    errorMessagerepeat: function () {
      return this.errors.first('emailRepeat') === null ? '-' : this.errors.first('emailRepeat')
    }
  },
  data() {
    return {
      inputValue: '',
      inputValuerepeat: '',
      validCallbackSent: false,
      emailRepeat: '',
      email: ''
    }
  },
  mounted: function () {
    this.inputValue = this.injectedValue
    this.inputValuerepeat = this.injectedValue
  },
  watch: {
    injectedValue: function () {
      this.inputValue = this.injectedValue
      this.inputValuerepeat = this.injectedValue
      this.validCallbackSent = true
    },
    errorMessage: function (val) {
      if (
        this.errors.first('inputfield') === null &&
        this.inputValue !== '' &&
        this.errors.first('emailRepeat') === null &&
        this.inputValuerepeat !== ''
      ) {
        this.callback({
          fieldName: 'email',
          value: this.inputValue,
          validationAccepted: true
        })
        this.validCallbackSent = true
      } else if (this.validCallbackSent && (this.errors.first('inputfield') !== null || this.errors.first('emailRepeat') !== null)) {
        this.callback({
          fieldName: 'email',
          value: this.inputValue,
          validationAccepted: false
        })
        this.validCallbackSent = false
      }
    },
    errorMessagerepeat: function (val) {
      if (
        this.errors.first('inputfield') === null &&
        this.inputValue !== '' &&
        this.errors.first('emailRepeat') === null &&
        this.inputValuerepeat !== ''
      ) {
        this.callback({
          fieldName: 'email',
          value: this.inputValue,
          validationAccepted: true
        })
        this.validCallbackSent = true
      } else if (this.validCallbackSent && (this.errors.first('inputfield') !== null || this.errors.first('emailRepeat') !== null)) {
        this.callback({
          fieldName: 'email',
          value: this.inputValue,
          validationAccepted: false
        })
        this.validCallbackSent = false
      }
    }
  }
}
</script>
