import { ApplicationTypes, CaseTypes } from '@/models'
import drivingLicenseTypeUtils from '@/utils/drivingLicenseTypeUtils'
import Vue from 'vue'
import router from '@/router'

import { mutationTypes } from './mutations'
import RouteNames from '@/models/routes'
import { getterTypes } from './getters'
import { mutationTypes as globalMutationTypes } from './../globals/mutations'

const actionTypes = {
  LOAD_AVAILABLE_APPLICATION_TYPES: 'loadAvailableApplicationsTypes',
  INIT: 'init',
  CANCEL_EXISTING_CASE: 'cancelExistingCase',
  INIT_PERSON_APPLICATION: 'initPersonApplications',
  SET_CENTER: 'setCenter',
  GET_PHOTOS: 'getPhotos',
  SET_PHOTO_CHOICES: 'setPhotoChoices',
  LOAD_AVAILABLE_TIMES: 'getAvailableTimes',
  RESERVE_BOOKING: 'reserveBooking',
  SUBMIT: 'submit',
  LOAD_RECEIPT_DATA: 'loadReceiptData'
}

const actions = {
  [actionTypes.LOAD_AVAILABLE_APPLICATION_TYPES]: async ({ commit }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      const response = await Vue.axios.get('application/application-types')

      if (response.data) {
        let applicationTypes = []
        if (response.data.passportSettings.isAvailable === true) {
          applicationTypes.push('passport')
        }
        if (response.data.drivingLicenseSettings.isAvailable === true) {
          applicationTypes.push('drivinglicense')
        }
        commit(mutationTypes.SET_AVAILABLE_APPLICATION_TYPES, applicationTypes)

        let applicationUsageWarnings = []
        if (response.data.passportSettings.showWarning === true) {
          applicationUsageWarnings.push('passport')
        }
        if (response.data.drivingLicenseSettings.showWarning === true) {
          applicationUsageWarnings.push('drivinglicense')
        }
        commit(mutationTypes.SET_APPLICATION_USAGE_WARNINGS, applicationUsageWarnings)
      } else {
        throw new Error()
      }
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, { message: 'Kommunens indstillinger kunne ikke findes', retriable: false }, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.INIT]: async ({ commit, state }, applicationType) => {
    if (!state.availableApplicationsTypes.includes(applicationType)) {
      commit(
        `globals/${globalMutationTypes.SET_ERROR}`,
        {
          message: `Den valgte kommune tager ikke imod selvbetjeningssager omkring ${
            applicationType == ApplicationTypes.Passport ? 'pas' : 'kørekort'
          }`
        },
        { root: true }
      )

      throw new Error()
    }

    try {
      commit(mutationTypes.SET_LOADING, 'Henter dine personlige oplysninger. Vent et øjeblik...')

      const response = await Vue.axios.post(`application/${applicationType}`)
      commit(mutationTypes.SET_APPLICATION_INFO, response.data)
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, { message: 'Siden oplever i øjeblikket tekniske problemer' }, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.CANCEL_EXISTING_CASE]: async ({ commit }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      await Vue.axios.delete('application/cases')
      commit(mutationTypes.CLEAR_EXISTING_CASES)
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.INIT_PERSON_APPLICATION]: async ({ state }) => {
    await Vue.axios.post(`application/persons`, null, {
      params: { personIds: state.personApplications.map((i) => i.id) }
    })
  },
  [actionTypes.SET_CENTER]: async ({ commit, getters }, center) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      const response = await Vue.axios.put(`application/citizen-center/${center.centerCode}`)

      commit(mutationTypes.SET_CENTER, center)

      commit(mutationTypes.SET_SETTINGS, response.data)

      if (!getters[getterTypes.PAY_AT_ATTENDANCE_AVAILABLE]) {
        commit(mutationTypes.SET_PAY_ONLINE, true)
      }
      if (!getters[getterTypes.PAY_ONLINE_AVAILABLE]) {
        commit(mutationTypes.SET_PAY_ONLINE, false)
      }
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, { message: 'Centerets indstillinger kunne ikke findes' }, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.GET_PHOTOS]: async ({ commit }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')

      const response = await Vue.axios.get('photos/info')

      for (const photoChoice of response.data.items) {
        if (photoChoice.photoId) {
          commit(mutationTypes.SET_PHOTO, { id: photoChoice.id, photo: photoChoice.photo })
        }
      }
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.SET_PHOTO_CHOICES]: async ({ state }) => {
    const personsToPhotoChoices = state.personApplications.reduce(function (map, application) {
      map[application.id] = application.photoChoice
      return map
    }, {})
    await Vue.axios.put(`application/photo-choice`, personsToPhotoChoices)
  },
  [actionTypes.LOAD_AVAILABLE_TIMES]: async ({ state, commit, getters }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Henter ledige tider. Vent et øjeblik...')

      const params = {
        peopleAmount: getters[getterTypes.TIMEBOOKING_PEOPLE_AMMOUNT]
      }

      if (state.applicationType === ApplicationTypes.DrivingLicense) {
        params.drivingLicenseSituation = drivingLicenseTypeUtils.calculateDriverLicenseType(
          state.personApplications[0].situation.largeVehicleLicense ? 2 : 1,
          state.personApplications[0].situation.id
        )
      }

      const response = await Vue.axios.get('booking/available-times', { params: params })

      commit(mutationTypes.SET_AVAILABLE_TIMES, response.data)

      if (!Object.values(state.bookingTimes).length) {
        if (state.settings.timeBooking.required) {
          throw new Error()
        } else {
          commit(mutationTypes.SET_BOOK_TIME, false)
        }
      }
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, { message: 'Der kunne ikke findes nogle ledige tider.' }, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.RESERVE_BOOKING]: async ({ state, commit, getters }, timeBooking) => {
    const isReserved = state.timeBooking && state.timeBooking.id === timeBooking.id

    commit(mutationTypes.SET_LOADING, 'Din tid reserveres. Vent et øjeblik...')

    if (!isReserved) {
      try {
        const response = await Vue.axios.post('booking', state.person, {
          params: {
            bookingPeriodId: timeBooking.id,
            peopleAmount: getters[getterTypes.TIMEBOOKING_PEOPLE_AMMOUNT]
          }
        })
        commit(mutationTypes.SET_TIME_BOOKING, timeBooking)
        commit(mutationTypes.SET_BOOKING_RESERVATION, response.data)
      } catch (e) {
        router.push({ name: RouteNames.UnsuccessfulReservation })
        throw e
      } finally {
        commit(mutationTypes.RESET_LOADING)
      }
    }
  },
  [actionTypes.SUBMIT]: async ({ state, commit }, submitApplication) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Din sag behandles. Vent et øjeblik...')

      let cases = []
      for (const personApplication of state.personApplications) {
        let caseData = {
          id: personApplication.id,
          caseType: personApplication.caseType,
          person: personApplication.person,
          situation: personApplication.situation,
          photoChoice: personApplication.photoChoice,
          bookingReservation: personApplication.bookingReservation,
          photoId: personApplication.photo ? personApplication.photo.id : undefined
        }

        if (state.applicationType === ApplicationTypes.DrivingLicense) {
          caseData.situation = {
            ...caseData.situation,
            drivingLicenseActivity: drivingLicenseTypeUtils.calculateDriverLicenseType(
              caseData.situation.largeVehicleLicense ? 2 : 1,
              caseData.situation.id
            )
          }
        }

        if (personApplication.caseType === CaseTypes.ChildPassport) {
          caseData.childInfo = {
            guardian1: state.person,
            guardian2:
              personApplication.person.guardian2Info && personApplication.person.guardian2Info.hasFullCprNumber
                ? personApplication.person.guardian2Info
                : null
          }
        }

        cases.push(caseData)
      }

      const response = await Vue.axios.post(
        'application',
        {
          applicationId: state.id,
          centerCode: state.center.centerCode,
          person: state.person,
          cases: cases,
          payOnline: state.payment.payOnline
        },
        {
          params: {
            submitApplication: submitApplication
          }
        }
      )

      commit(mutationTypes.SET_ORDER, response.data.order)

      if (response.data.paymentCheckout) {
        commit(mutationTypes.SET_PAYMENT_CHECKOUT, response.data.paymentCheckout)
      }
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.LOAD_RECEIPT_DATA]: async ({ commit }, applicationId) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Henter kvittering. Vent et øjeblik...')

      const response = await Vue.axios.get(`application/receipt/${applicationId}`)

      commit(mutationTypes.SET_SETTINGS, response.data.settings)
      commit(mutationTypes.SET_CENTER, response.data.center)
      commit(mutationTypes.SET_ORDER, response.data.order)
      commit(mutationTypes.SET_TIME_BOOKING, { datetime: response.data.bookingTime })
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  }
}

export { actionTypes }
export default actions
