export default [
  {
    path: '/v2/secure/:municipalityCode',
    redirect: '/:municipalityCode'
  },
  {
    path: '/v2/secure/:municipalityCode/pas',
    redirect: '/:municipalityCode/passport'
  },
  {
    path: '/v2/secure/:municipalityCode/gruppepas',
    redirect: '/:municipalityCode/passport'
  },
  {
    path: '/v2/secure/:municipalityCode/koerekort',
    redirect: '/:municipalityCode/drivinglicense'
  },
  {
    path: '/v2/secure/verify-child/:municipalityCode/:caseId',
    redirect: '/:municipalityCode/verification'
  },
  {
    path: '/v2/secure/verify-child-lost-passport/:municipalityCode/:caseId',
    redirect: '/:municipalityCode/verification'
  },
  {
    path: '/v2/secure/:municipalityCode/pinkode',
    redirect: '/:municipalityCode/pinkode'
  },
  {
    path: '/v2/gdpr/:municipalityCode',
    redirect: '/:municipalityCode/gdpr'
  },
  {
    path: '/v2/salgsoghandelsbetingelser',
    redirect: '/salgsoghandelsbetingelser'
  },
  {
    path: '/:municipalityCode/pas',
    redirect: '/:municipalityCode/passport'
  },
  {
    path: '/:municipalityCode/koerekort',
    redirect: '/:municipalityCode/drivinglicense'
  }
]
