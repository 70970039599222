<template lang="pug">
  #app
    router-view
</template>

<style lang="sass">
  @import 'global-styles/site.scss'
</style>

<script>
export default {
  name: 'app',
  data: function () {
    return {
      transitionName: 'forward'
    }
  }
}
</script>
<script type="text/javascript" src="//cloudfront.loggly.com/js/loggly.tracker-latest.min.js" async></script>
<script>
  var _LTracker = _LTracker || []
  _LTracker.push({'logglyKey': '6a0d2ba3-4022-4715-9dc2-4f4ad493bb41',
    'sendConsoleErrors': true,
    'tag': 'Selvbetjening2'})

</script>
<script>
  history.pushState(null, null, location.href)
  window.onpopstate = function () {
    history.go(1)
  }
</script>

