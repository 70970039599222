<template lang="pug">
include timeBooking.pug
</template>

<style lang="scss" scoped>
@import 'timeBooking';
</style>

<script>
import TextInput from '@/components/shared/textInput'
import SwitchButton from '@/components/shared/switchButton'
import NavigationButtons from '@/components/shared/navigationButtons'
import { FocusLoop } from '@vue-a11y/focus-loop'
import { createNamespacedHelpers } from 'vuex'
import services from '@/services'

import { getterTypes, mutationTypes, actionTypes } from '@/store/application'
const globalStore = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')
var Moment = require('moment')

export default {
  name: 'BookTime',
  components: {
    FocusLoop,
    inputField: TextInput,
    navigationButtons: NavigationButtons,
    switchButton: SwitchButton
  },
  computed: {
    ...globalStore.mapState({
      texts: (state) => state.texts.timeBooking
    }),
    ...applictionStore.mapState({
      person: (state) => state.person,
      bookTime: (state) => state.bookTime,
      timeBooking: (state) => state.timeBooking,
      noTimeslots: (state) => !Object.values(state.bookingTimes).length,
      bookingTimes: (state) =>
        state.timeBooking ? { ...state.bookingTimes, [state.timeBooking.id]: state.timeBooking.datetime } : state.bookingTimes
    }),
    ...applictionStore.mapGetters({
      timeBookingRequired: getterTypes.TIMEBOOKING_REQUIRED,
      phoneRequired: getterTypes.REQUIRES_PERSON_PHONE_NUMBER
    }),
    availableDates: function () {
      return !this.noTimeslots ? Object.values(this.bookingTimes)
        .map((d) => new Moment(d).format('YYYY-MM-DD'))
        .filter((s, i, a) => a.indexOf(s) == i)
        .map(s => new Date(s))
        : []
    },
    availableTimeSlots: function () {
      return this.selectedDay
        ? Object.entries(this.bookingTimes)
          .filter(([id, datetime]) => new Moment(datetime).format('DD-MM-YYYY') === new Moment(this.selectedDay).format('DD-MM-YYYY'))
          .map((i) => ({
            id: i[0],
            datetime: i[1]
          }))
        : []
    },
    nextStepAllowed: function () {
      return (!this.bookTime || this.selectedSlotId !== undefined) && (!this.phoneRequired || this.person.phoneNumber !== undefined)
    }
  },
  data() {
    return {
      datePickerOpen: false,
      selectedDay: new Date(),
      selectedSlotId: undefined,
      displayAllTimeslots: false,
      phoneNumber: ''
    }
  },
  methods: {
    ...applictionStore.mapActions({
      getAvailableTimes: actionTypes.LOAD_AVAILABLE_TIMES,
      reserveBooking: actionTypes.RESERVE_BOOKING
    }),
    ...applictionStore.mapMutations({
      clearAvailableTimes: mutationTypes.CLEAR_AVAILABLE_TIMES,
      clearTimeBooking: mutationTypes.CLEAR_TIME_BOOKING,
      setTimeBooking: mutationTypes.SET_TIME_BOOKING,
      setPhoneNumber: mutationTypes.SET_PHONE_NUMBER,
      setBookTime: mutationTypes.SET_BOOK_TIME
    }),
    phonenumbercallback: function (phoneNumber) {
      this.phoneNumber = phoneNumber.value
      this.setPhoneNumber(phoneNumber.validationAccepted ? phoneNumber.value : undefined)
    },
    onChooseDate: function () {
      this.datePickerOpen = true
      this.$nextTick(() => {
        this.$refs.dp.$el.focus()
      })
    },
    closeDatePicker: function () {
      this.datePickerOpen = false
    },
    onTimeslotSelected: function (slotId) {
      this.selectedSlotId = slotId
    },
    showAllTimeSlots: function () {
      this.displayAllTimeslots = true
    },
    onPreviousStepClicked: function () {
      this.clearAvailableTimes()
      this.clearTimeBooking()
      services.application.previousStep()
    },
    onNextStepClicked: async function () {
      if (this.bookTime) {
        var timeBooking = this.availableTimeSlots.find((i) => i.id === this.selectedSlotId)
        await this.reserveBooking(timeBooking)
      } else {
        this.setTimeBooking(undefined)
      }

      services.application.nextStep()
    }
  },
  mounted: function () {
    this.phoneNumber = this.person.phoneNumber
    if (this.noTimeslots) {
      this.selectedSlotId = undefined
    } else {
      if (this.timeBooking) {
        this.selectedDay = new Moment(this.timeBooking.datetime).toDate()
        this.selectedSlotId = this.timeBooking.id
      } else {
        this.selectedDay = this.availableDates[0]
      }
    }
  },
  watch: {
    selectedDay: function () {
      this.datePickerOpen = false
      this.displayAllTimeslots = false
    }
  }
}
</script>
