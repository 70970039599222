var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row ma-0" },
    [
      _vm._l(_vm.order.items, function (orderItem) {
        return [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s8 xl9" }, [
              _c("span", { staticClass: "text-info normal" }, [
                _vm._v(_vm._s(orderItem.name)),
              ]),
            ]),
            _c("div", { staticClass: "col s2 flex-end-align" }, [
              _c("span", { staticClass: "text-value" }, [
                _vm._v(_vm._s(orderItem.quantity) + " x"),
              ]),
            ]),
            _c("div", { staticClass: "col s2 xl1 flex-end-align" }, [
              _c("span", { staticClass: "text-value" }, [
                _vm._v(_vm._s(_vm._f("amountFormat")(orderItem.price))),
              ]),
            ]),
          ]),
        ]
      }),
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 flex-end-align" }, [
          _c("span", { staticClass: "text-value" }, [
            _vm._v(
              "Heraf moms: " +
                _vm._s(_vm._f("amountFormat")(_vm.order.vatAmount))
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 flex-end-align" }, [
          _c("span", { staticClass: "text-value bold" }, [
            _vm._v(
              "Total DKK: " +
                _vm._s(_vm._f("amountFormat")(_vm.order.totalAmount))
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12 pa-1" }, [_c("hr")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }