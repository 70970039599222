var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    { staticClass: "progressbar" },
    [
      _c("progress-bar-item", {
        attrs: {
          icon: "/static/center.svg",
          label: "Start",
          selected: _vm.currentStep === "start",
        },
      }),
      _vm.isGroupOrder &&
      (_vm.personApplication !== undefined || _vm.isFlowStartStep)
        ? _vm._l(_vm.persons, function (person, index) {
            return _c("progress-bar-item", {
              key: person.id,
              attrs: {
                icon: "/static/situation.svg",
                label: person.name,
                selected: _vm.currentStep === person.id,
              },
            })
          })
        : [
            _c("progress-bar-item", {
              attrs: {
                icon: "/static/situation.svg",
                label: "Situation",
                selected: _vm.currentStep === "situation",
              },
            }),
          ],
      !_vm.isGroupOrder ||
      (_vm.allSituationCompleted && _vm.personApplication === undefined)
        ? [
            _vm.photoChoiceAvailable || _vm.isFlowStartStep
              ? [
                  _c("progress-bar-item", {
                    attrs: {
                      icon: "/static/camera.svg",
                      label: "Foto valg",
                      selected: _vm.currentStep === "photochoice",
                    },
                  }),
                ]
              : _vm._e(),
            _vm.timeBookingAvailable || _vm.isFlowStartStep
              ? [
                  _c("progress-bar-item", {
                    attrs: {
                      icon: "/static/booking.svg",
                      label: "Book tid",
                      selected: _vm.currentStep === "timebooking",
                    },
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
      _c("progress-bar-item", {
        attrs: {
          icon: "/static/finish.svg",
          label: "Afslut",
          selected: _vm.currentStep === "summary",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }