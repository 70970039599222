import Vue from 'vue'
import Vuex from 'vuex'
import globals from './globals'
import verification from './verification'
import photo from './photo'
import application from './application'
import securityCabinet from './securityCabinet'
import oidc from './oidc'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    oidc: oidc,
    application: application,
    globals: globals,
    photo: photo,
    verification: verification,
    securityCabinet: securityCabinet
  }
})
