const Moment = require('moment')

export default {
  getBirthDateFromCpr: function (cprNumber) {
    const d = parseInt(cprNumber.substring(0, 2))
    const m = parseInt(cprNumber.substring(2, 4))
    let y = parseInt(cprNumber.substring(4, 6))

    if (cprNumber.length > 6 && parseInt(cprNumber.substring(6, 7)) != undefined) {
      const l = parseInt(cprNumber.substring(6, 7))

      if (l <= 3) {
        y += 1900
      } else if (l == 4 || l == 9) {
        y += y <= 36 ? 2000 : 1900
      } else {
        y += y <= 57 ? 2000 : 1800
      }
    } else if (y > Moment().year - 2000) {
      y += 1900
    } else {
      y += 2000
    }
    return Moment(`${String(d).padStart(2, '0')}${String(m).padStart(2, '0')}${y}`, 'DDMMYYYY')
  },
  getAgeFromCpr: function (cprNumber) {
    return Moment().diff(this.getBirthDateFromCpr(cprNumber), 'years')
  },
  turnsEighteenInNextfourtytwoDays: function (cprString) {
    const eightteenBirtdayMoment = this.getBirthDateFromCpr(cprString).add(18, 'years')
    return eightteenBirtdayMoment.isBetween(Moment(), Moment().add(42, 'days'), 'days', '(]')
  }
}
