var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _vm.tokenExpiresIn > -1
                ? [
                    _vm._t("default", function () {
                      return [
                        _vm._v(
                          "Af sikkerhedsmæssige årsager bliver du automatisk logget ud om " +
                            _vm._s(_vm.tokenExpiresIn) +
                            " sekunder. Ønsker du at fortsætte ansøgningen?"
                        ),
                      ]
                    }),
                  ]
                : [
                    _vm._t("default", function () {
                      return [_vm._v("Logger ud...")]
                    }),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm.tokenExpiresIn > -1
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "scandi-btn scandi-button-wrapper",
                        attrs: {
                          role: "button",
                          "aria-label": "Ja",
                          tabIndex: "0",
                        },
                        on: {
                          click: _vm.contniueApplication,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.contniueApplication.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("JA")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "scandi-btn scandi-button-wrapper",
                        attrs: {
                          role: "button",
                          "aria-label": "Nej",
                          tabIndex: "0",
                        },
                        on: {
                          click: _vm.signOut,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.signOut.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Nej, log mig ud")]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }