<template lang="pug">
include index.pug
</template>

<script>
import Error from './error'
import Forbidden from './forbidden'
import OpenOnMobile from './openOnMobile'
import GdprAccept from './gdpr'
import services from '@/services'
import { createNamespacedHelpers } from 'vuex'
import { actionTypes as globalActionTypes } from '@/store/globals/actions'
import ageUtils from '@/utils/ageUtils'

const globalStore = createNamespacedHelpers('globals')
const applicationStore = createNamespacedHelpers('application')
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'Municipality',
  components: {
    Error,
    Forbidden,
    OpenOnMobile,
    GdprAccept
  },
  computed: {
    ...globalStore.mapState({
      error: (state) => state.error,
      gdprStatus: (state) => state.gdprStatus
    }),
    ...applicationStore.mapState({
      applicationId: (state) => state.id
    }),
    ...oidcStore.mapGetters({
      isAuthenticated: 'oidcIsAuthenticated',
      user: 'oidcUser'
    }),
    lowLoa: function () {
      return this.isAuthenticated && !JSON.parse(this.user.nem_login_loa_valid.toLowerCase())
    },
    employyeLogin: function () {
      return this.isAuthenticated && this.user.nem_login_type == 'Employee'
    },
    childLogin: function () {
      return this.isAuthenticated && ageUtils.getAgeFromCpr(this.user.nem_login_cpr_cvr) < 18
    },
    validLogin: function () {
      return this.isAuthenticated && !this.lowLoa && !this.employyeLogin && !this.childLogin
    },
    requiresMobile: function () {
      return this.$route.meta.requiresMobile && !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    requiredGdprAcceptance: function () {
      return this.validLogin && this.$route.meta.requiresGDPR && !this.gdprStatus
    }
  },
  methods: {
    ...globalStore.mapActions({
      loadTexts: globalActionTypes.LOAD_TEXTS
    })
  },
  mounted() {
    this.loadTexts()

    if (this.$route.meta.requiresHubConnection && this.applicationId && !this.requiresMobile) {
      services.hub.initialize()
    }
  },
  watch: {
    async $route(to, from) {
      if (to.meta.requiresHubConnection && this.applicationId) {
        await services.hub.initialize()
      }
    }
  }
}
</script>
