<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
import { FocusLoop } from '@vue-a11y/focus-loop'
import TermsCheckbox from '@/components/shared/termsCheckbox'
import NavigationButtons from '@/components/shared/navigationButtons'
import { createNamespacedHelpers } from 'vuex'
import LogoutModal from '@/components/shared/modals/logoutModal'
import { mutationTypes as globalMutationTypes, actionTypes as globalActionTypes } from '@/store/globals'

const globalStore = createNamespacedHelpers('globals')

export default {
  name: 'gdpr-accept',
  components: {
    FocusLoop,
    TermsCheckbox,
    NavigationButtons,
    LogoutModal
  },
  computed: {
    ...globalStore.mapState({
      texts: (state) => state.texts.gdprAcceptance,
      gdprText: (state) => state.gdprText
    }),
    nextStepAllowed: function () {
      return this.termsAccepted && this.gdprText !== undefined
    },
    municipalityTexts: function () {
      return this.gdprText ? this.gdprText : 'Henter kommunens betingelser'
    }
  },
  data() {
    return {
      termsAccepted: false,
      atBottomScroll: false,
      promtLogout: false
    }
  },
  methods: {
    ...globalStore.mapMutations({
      setGdprStatus: globalMutationTypes.SET_GDPR_STATUS
    }),
    ...globalStore.mapActions({
      loadGdprText: globalActionTypes.LOAD_GDPR_TEXT
    }),
    onLogOffClicked: function () {
      this.promtLogout = true
    },
    onLogOffCancelled: function () {
      this.promtLogout = false
    },
    onNextStepClicked: async function () {
      if (this.nextStepAllowed) {
        this.setGdprStatus(this.termsAccepted)
      }
    },
    handleScroll: function (element) {
      this.atBottomScroll = false
      if (element.scrollHeight <= element.clientHeight + 5) {
        this.atBottomScroll = true
        return
      }
      if (element.srcElement && element.srcElement.scrollTop >= element.srcElement.scrollHeight - element.srcElement.offsetHeight - 25) {
        this.atBottomScroll = true
      }
    },
    handleResize: function () {
      this.handleScroll(this.$refs.gdprContainer)
    },
    updateTermsAcceptance: function () {
      this.termsAccepted = !this.termsAccepted
    }
  },
  mounted: function () {
    this.loadGdprText()
    this.handleScroll(this.$refs.gdprContainer)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    municipalityTexts: function () {
      setTimeout(
        function () {
          this.atBottomScroll = false
          this.handleScroll(this.$refs.gdprContainer)
        }.bind(this),
        1
      )
    }
  }
}
</script>
