<template lang="pug">
include situation-details.pug
</template>

<script>
import SwitchButton from '@/components/shared/switchButton'
import TextInput from '@/components/shared/textInput'
import { createNamespacedHelpers } from 'vuex'

import { getterTypes, mutationTypes } from '@/store/application'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'PassportSituationDetails',
  components: {
    inputField: TextInput,
    switchButton: SwitchButton
  },
  computed: {
    ...applictionStore.mapGetters({
      personApplication: getterTypes.CURRENT,
      requiresPersonHeigth: getterTypes.REQUIRES_PERSON_HEIGHT
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.situationDetailsPassport
    })
  },
  data: function () {
    return {
      height: ''
    }
  },
  methods: {
    ...applictionStore.mapMutations({
      setHasBeenCitizenOfAnotherCountry: mutationTypes.SET_HAS_BEEN_CITIZEN_OF_OTHER_COUNTRY,
      setInPossessionOfOldPassport: mutationTypes.SET_IN_POSESSION_OF_OLD_PASSPORT,
      setHeight: mutationTypes.SET_HEIGHT
    }),
    heightcallback: function (height) {
      this.height = height.value
      this.setHeight(height.validationAccepted ? height.value : undefined)
    }
  },
  mounted: function () {
    this.height = this.personApplication.situation.height
  }
}
</script>
