import Store from '@/store'
import * as signalR from '@microsoft/signalr'
import store from '@/store'
import { mutationTypes as globalMutationTypes } from '@/store/globals'

export default {
  initialize: async function () {
    if (this.connection !== undefined && this.connection.state !== signalR.HubConnectionState.Disconnected) {
      return
    }
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`/api/hub?applicationId=${Store.state.application.id}`, {
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => Store.state.oidc.access_token
      })
      //.withAutomaticReconnect([0, 1000, 5000, null])
      .build()

    this.connection.onclose((error) => {
      if (error) {
        store.commit(`globals/${globalMutationTypes.SET_ERROR}`, {
          message: 'Kunne ikke forbinde. Sørg for at du har en aktiv forbindelse på din computer og kun har én forbundet enhed.',
          retriable: false
        })
      }
    })

    this.connection.on('connection-aborted', async function () {
      await this.connection.stop()
      store.commit(`globals/${globalMutationTypes.SET_ERROR}`, {
        message: 'Kunne ikke oprette forbindelse',
        retriable: false
      })
    })

    await this.connection.start()
  },
  subscribeEvent: function (event, onEvent) {
    this.connection.on(event, function (data) {
      onEvent(data)
    })
  },
  unsubscribeEvent: function (event) {
    this.connection.off(event)
  },
  abortSession: async function () {
    if (this.connection !== undefined) {
      await this.connection.send('AbortSession')
      await this.connection.stop()
    }
  },
  abortDeviceSession: async function () {
    await this.connection.send('AbortDeviceSession')
  }
}
