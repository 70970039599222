<template lang="pug">
include situation-lost.pug
</template>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import SwitchButton from '@/components/shared/switchButton'
import services from '@/services'
import { createNamespacedHelpers } from 'vuex'
import { getterTypes, mutationTypes } from '@/store/application'

const applictionStore = createNamespacedHelpers('application')
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'SituationLost',
  components: {
    navigationButtons: NavigationButtons,
    switchButton: SwitchButton
  },
  computed: {
    reason: {
      get: function () {
        return this.personApplication.situation.lostReason
      },
      set: function (lostReason) {
        this.setLostReason(lostReason)
      }
    },
    ...applictionStore.mapState({
      isAttendanceAtLostIdRequired: (state) => state.settings.isAttendanceAtLostIdRequired
    }),
    ...applictionStore.mapGetters({
      personApplication: getterTypes.CURRENT
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.situationLost
    }),
    nextStepAllowed: function () {
      return this.personApplication.situation.newDocument !== undefined && !!this.reason
    }
  },
  methods: {
    onPreviousStepClicked: function () {
      this.setPreviousDocumentLost(false)
    },
    onNextStepClicked: function () {
      services.application.nextStep()
    },
    ...applictionStore.mapMutations({
      setNewDocument: mutationTypes.SET_NEW_DOCUMENT,
      setPreviousDocumentLost: mutationTypes.SET_PREVIOUS_DOCUMENT_LOST,
      setLostReason: mutationTypes.SET_LOST_REASON
    })
  }
}
</script>
