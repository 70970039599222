import application from './application'
import maintenance from './maintenance'
import scroll from './scroll'

import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store'

export default {
  application: application,
  maintenance: maintenance,
  authentication: vuexOidcCreateRouterMiddleware(store, 'oidc'),
  scroll: scroll
}
