<template lang="pug">
include index.pug
</template>

<script>
export default {
  name: 'OpenOnMobile',
  computed: {
    link: function () {
      return window.location.href
    }
  },
  data: function () {
    return {
      size: 300
    }
  }
}
</script>
