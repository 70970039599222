var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c(
        "focus-loop",
        { attrs: { "is-visible": _vm.promtLogout } },
        [_c("logout-modal", { attrs: { onCancel: _vm.onLogOffCancelled } })],
        1
      ),
      _c("div", { staticClass: "row panes" }, [
        _c("div", { staticClass: "pane col s12 l6 pa-0" }, [
          _c("div", { staticClass: "pane-triangle" }),
          _c("div", { staticClass: "steps" }, [
            _c(
              "div",
              {
                ref: "gdprContainer",
                staticClass: "text-container",
                class: [
                  _vm.atBottomScroll === false
                    ? "text-container--shadowBoxBottom"
                    : "",
                ],
                on: { scroll: _vm.handleScroll },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col s12 center-align",
                      attrs: { "aria-label": "GDPR Informationstekst" },
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.municipalityTexts) },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col s12 l6 pane stretch-children-horizontal" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "scandi-nav logout-section" }, [
                _c("div", { staticClass: "nav-row" }, [
                  _c(
                    "a",
                    {
                      staticClass: "user logout",
                      attrs: { tabindex: "0" },
                      on: {
                        click: _vm.onLogOffClicked,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onLogOffClicked.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Log af")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("terms-checkbox", {
                  attrs: { checkBoxText: _vm.texts.accept },
                  on: {
                    callback: function ($event) {
                      return _vm.updateTermsAcceptance()
                    },
                  },
                }),
              ],
              1
            ),
            _c("navigation-buttons", {
              staticClass:
                "bottom-nav-static-bottom desktop hide-on-med-and-down",
              attrs: {
                showPrevious: false,
                nextEnabled: _vm.nextStepAllowed,
                onNextClick: _vm.onNextStepClicked,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row before-bottom-nav hide-on-large-only" }),
      _c("navigation-buttons", {
        staticClass: "bottom-nav hide-on-large-only",
        attrs: {
          showPrevious: false,
          nextEnabled: _vm.nextStepAllowed,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }