var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content bottom-nav-container" }, [
    _vm._m(0),
    _c("div", { staticClass: "row before-bottom-nav" }, [
      _c("div", { staticClass: "col center-align" }, [
        _c("div", { staticClass: "image-upload" }, [
          _c(
            "label",
            { staticClass: "nameLabel", attrs: { for: "cameraFileInput" } },
            [
              _vm._v("Tryk på billedet for at tage et nyt:"),
              _vm.possiblePhoto
                ? _c("img", {
                    attrs: {
                      src: "data:image/jpeg;base64," + _vm.possiblePhoto.data,
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onTakePhoto()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onTakePhoto()
                      },
                    },
                  })
                : _c("img", {
                    attrs: { src: "/static/silhouette.jpg", alt: "ukendt" },
                    on: {
                      click: function ($event) {
                        return _vm.onTakePhoto()
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onTakePhoto()
                      },
                    },
                  }),
            ]
          ),
          _c("input", {
            attrs: {
              id: "cameraFileInput",
              type: "file",
              accept: "image/*",
              capture: "environment",
            },
            on: { change: _vm.onFileChange },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col center-align" }, [
        _c("h2", { staticClass: "text-view-title mb-1 mt-2" }, [
          _vm._v("Omfotografering"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }