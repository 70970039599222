var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c(
        "focus-loop",
        { attrs: { "is-visible": _vm.showRestrictedPhotoWarning } },
        [
          _c("restricted-photo-warning-modal", {
            attrs: { onAccept: _vm.acceptRestrictedPhotoWarning },
          }),
        ],
        1
      ),
      _c("notification", {
        staticStyle: { position: "fixed" },
        attrs: {
          show: _vm.childPhotoBlocked,
          text: _vm.ageRestrictionNotificationText,
          icon: "/static/camerawhite.svg",
        },
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col s10 offset-s1 center-align my-1",
            class: { "my-3": _vm.childPhotoBlocked },
          },
          [
            _c("h2", { staticClass: "text-view-title" }, [
              _vm._v(_vm._s(_vm.texts.title)),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row before-bottom-nav" }, [
        _c(
          "div",
          {
            staticClass: "col s10 offset-s1 l8 offset-l2 xl4 offset-xl4 my-1",
            attrs: { role: "group", "aria-label": _vm.texts.title },
          },
          [
            _c(
              "ol",
              { staticClass: "scandi-list always-vertical" },
              _vm._l(_vm.people, function (person) {
                return _c(
                  "li",
                  {
                    staticClass: "scandi-list-item",
                    class: { selected: _vm.isPersonSelected(person.id) },
                    attrs: {
                      tabindex: "0",
                      "aria-label": person.name,
                      role: "checkbox",
                      "aria-checked": _vm.isPersonSelected(person.id),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onPersonToggled(person.id)
                      },
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onPersonToggled(person.id)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "scandi-list-title" }, [
                      _vm._v(_vm._s(person.name)),
                    ]),
                    _c("span", { staticClass: "scandi-list-text" }, [
                      _vm._v(_vm._s(_vm._f("hideCpr")(person.cprNumber))),
                    ]),
                    _vm.isPersonSelected(person.id) &&
                    _vm.isSoonEighteen(person.cprNumber)
                      ? _c("span", { staticClass: "scandi-list-text" }, [
                          _vm._v(
                            "Ansøgningen skal gennemføres i Borgerservice inden barnets 18 års fødselsdag"
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          showPrevious: _vm.previousStepAvailable,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }