<template lang="pug">
include center.pug
</template>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import { createNamespacedHelpers } from 'vuex'
import services from '@/services'

import { actionTypes } from '@/store/application'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applicationStore = createNamespacedHelpers('application')

export default {
  name: 'Center',
  components: {
    navigationButtons: NavigationButtons
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.center
    }),
    ...applicationStore.mapState({
      applicationType: (state) => state.applicationType,
      centers: (state) => state.centers,
      center: (state) => state.center
    }),
    nextStepAllowed: function () {
      return this.selectedCenterCode !== undefined
    }
  },
  data: function () {
    return {
      selectedCenterCode: undefined
    }
  },
  methods: {
    ...applicationStore.mapActions({
      setCenter: actionTypes.SET_CENTER
    }),
    onCenterSelected: function (centerCode) {
      this.selectedCenterCode = centerCode
    },
    onNextStepClicked: async function () {
      if (!this.center || this.selectedCenterCode !== this.center.centerCode) {
        const selectedCenter = this.centers.find((center) => center.centerCode === this.selectedCenterCode)
        await this.setCenter(selectedCenter)
      }

      services.application.nextStep()
    }
  },
  mounted: function () {
    if (this.centers.length > 0) {
      if (this.center) {
        this.selectedCenterCode = this.center.centerCode
      }
    }
  }
}
</script>
