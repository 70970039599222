var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mt-1" }, [
      _c(
        "div",
        {
          staticClass: "input-wrapper",
          class: { invalid: _vm.errors.has("emailinputfield") },
        },
        [
          _c("div", { staticClass: "input-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
              ],
              ref: "email",
              staticClass: "validate",
              attrs: {
                id: "inputdescriptionemail",
                name: "emailinputfield",
                type: "email",
                tabindex: "0",
                "data-vv-as": "Email",
                autocomplete: "on",
                "aria-label": "Email adresse",
              },
              domProps: { value: _vm.inputValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                },
              },
            }),
            _c(
              "span",
              {
                staticClass: "error",
                attrs: { role: "alert", "aria-label": _vm.errorMessage },
              },
              [_vm._v(_vm._s(_vm.errorMessage))]
            ),
            _c(
              "label",
              {
                class: { active: _vm.inputValue != "" },
                attrs: { for: "inputdescriptionemail" },
              },
              [_vm._v("E-mail")]
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "row mt-1" }, [
      _c(
        "div",
        {
          staticClass: "input-wrapper",
          class: { invalid: _vm.errors.has("emailRepeat") },
        },
        [
          _c("div", { staticClass: "input-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValuerepeat,
                  expression: "inputValuerepeat",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email|confirmed:emailinputfield",
                  expression: "'required|email|confirmed:emailinputfield'",
                },
              ],
              staticClass: "validate",
              attrs: {
                id: "inputdescriptionrepeat",
                name: "emailRepeat",
                type: "email",
                tabindex: "0",
                "data-vv-as": "Gentag Email",
                autocomplete: "on",
                "aria-label": "Gentag email adresse",
              },
              domProps: { value: _vm.inputValuerepeat },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValuerepeat = $event.target.value
                },
              },
            }),
            _c(
              "span",
              {
                staticClass: "error",
                attrs: { role: "alert", "aria-label": _vm.errorMessagerepeat },
              },
              [_vm._v(_vm._s(_vm.errorMessagerepeat))]
            ),
            _c(
              "label",
              {
                class: { active: _vm.inputValuerepeat != "" },
                attrs: { for: "inputdescriptionrepeat" },
              },
              [_vm._v("Gentag E-mail")]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }