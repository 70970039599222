import VeeValidate from 'vee-validate'

const phoneNumberOrEmailValidator = {
  messages: {
    da: (field) => field + ' er ikke gyldigt.'
  },
  getMessage: {
    da: (field) => field + ' er ikke gyldigt.'
  },
  validate(value) {
    return VeeValidate.Rules.email(value) || /^[0-9]{8}$/.test(value)
  }
}
export default phoneNumberOrEmailValidator
