<template lang="pug">
include index.pug
</template>

<script>
import ProgressBarItem from './progressBarItem'
import { ApplicationSteps } from '@/models'
import { createNamespacedHelpers } from 'vuex'

import { getterTypes } from '@/store/application'
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'ProgressBar',
  components: {
    progressBarItem: ProgressBarItem
  },
  computed: {
    ...applictionStore.mapState({
      centerSelected: (state) => state.center !== undefined
    }),
    ...applictionStore.mapGetters({
      personApplication: getterTypes.CURRENT,
      personsCount: getterTypes.PERSONS_COUNT,
      persons: getterTypes.PERSONS,
      photoChoiceAvailable: getterTypes.PHOTO_CHOICE_AVAILABLE,
      timeBookingAvailable: getterTypes.TIMEBOOKING_AVAILABLE,
      allSituationCompleted: getterTypes.ARE_ALL_SITUATIONS_COMPLTED
    }),
    isFlowStartStep: function () {
      return this.personsCount === 0 || (this.personApplication === undefined && !this.allSituationCompleted)
    },
    isGroupOrder: function () {
      return this.personsCount > 1
    }
  },
  data() {
    return {
      currentStep: undefined
    }
  },
  methods: {
    setCurrentStep: function (key) {
      if (this.personsCount > 1 && key === ApplicationSteps.Situation && this.personApplication) {
        this.currentStep = this.personApplication.id
      } else {
        this.currentStep = key
      }
    }
  },
  watch: {
    $route(to, from) {
      if (to.meta.applicationStep) {
        this.setCurrentStep(to.meta.applicationStep)
      }
    }
  },
  mounted: function () {
    this.setCurrentStep(this.$route.meta.applicationStep ? this.$route.meta.applicationStep : ApplicationSteps.Start)
  }
}
</script>
