var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-content-centered" },
    [
      _vm.loader.isLoading
        ? [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "row" },
                [_c("loader", { attrs: { message: _vm.loader.message } })],
                1
              ),
            ]),
          ]
        : [
            _vm.isSubmitting && !_vm.anySelected
              ? [
                  _c("transition", { attrs: { name: "slide-fade" } }, [
                    _c("div", { staticClass: "modal-mask" }, [
                      _c("div", { staticClass: "modal-wrapper" }, [
                        _c("div", { staticClass: "modal-container" }, [
                          _c(
                            "div",
                            { staticClass: "modal-header" },
                            [
                              _vm._t("default", function () {
                                return [
                                  _vm._v(
                                    "Der er ikke afvist eller godkendt nogen ansøgninger"
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "modal-body" }, [
                            _c(
                              "button",
                              {
                                staticClass: "scandi-btn scandi-button-wrapper",
                                attrs: { role: "button", "aria-label": "Ja" },
                                on: { click: _vm.cancelSubmitting },
                              },
                              [_vm._v("OK")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm.isSubmitting && _vm.anySelected && !_vm.allSelected
              ? [
                  _c("transition", { attrs: { name: "slide-fade" } }, [
                    _c("div", { staticClass: "modal-mask" }, [
                      _c("div", { staticClass: "modal-wrapper" }, [
                        _c("div", { staticClass: "modal-container" }, [
                          _c(
                            "div",
                            { staticClass: "modal-header" },
                            [
                              _vm._t("default", function () {
                                return [
                                  _vm._v(
                                    'Der ikke er taget stilling til at alle ansøgninger. Tryk "Videre", hvis dette er meningen. Tryk "Afbryd" hvis du ønsker at tage stilling til de resterende ansøgninger.'
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "modal-body" }, [
                            _c(
                              "button",
                              {
                                staticClass: "scandi-btn scandi-button-wrapper",
                                attrs: { role: "button", "aria-label": "Ja" },
                                on: { click: _vm.continueSubmitting },
                              },
                              [_vm._v("Videre")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "scandi-btn scandi-button-wrapper",
                                attrs: { role: "button", "aria-label": "Nej" },
                                on: { click: _vm.cancelSubmitting },
                              },
                              [_vm._v("Afbryd")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col s12 l6 offset-l3 center-align my-2" },
                    [
                      _c("span", { staticClass: "text-view-title normal" }, [
                        _vm._v(_vm._s(_vm.texts.title)),
                      ]),
                    ]
                  ),
                ]),
                _vm.anyItems
                  ? [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col s12 l6 offset-l3 center-align mb-1",
                          },
                          [
                            _c("span", { staticClass: "text-info" }, [
                              _vm._v(_vm._s(_vm.texts.instructions)),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "row bg-light pb-2 my-3" },
                        [
                          _vm._l(_vm.children, function (child) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col s12 l8 offset-l2 xl4 offset-xl4 center-align my-1",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-view-title" },
                                    [_vm._v(_vm._s(child.name))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-view-subtitle" },
                                    [_vm._v(_vm._s(child.situation))]
                                  ),
                                  child.isLocked
                                    ? [
                                        _c(
                                          "span",
                                          { staticClass: "text-view-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                child.status
                                                  ? "Tilladt"
                                                  : "Afvist"
                                              ) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c("div", { staticClass: "row mt-1" }, [
                                          _c("div", { staticClass: "col s6" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "scandi-btn",
                                                class: {
                                                  selected:
                                                    child.status === true,
                                                },
                                                attrs: {
                                                  role: "button",
                                                  tabindex: "0",
                                                  "aria-label": "Tillad",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setStatus({
                                                      cprNumber:
                                                        child.cprNumber,
                                                      status: true,
                                                    })
                                                  },
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.setStatus({
                                                      cprNumber:
                                                        child.cprNumber,
                                                      status: true,
                                                    })
                                                  },
                                                },
                                              },
                                              [_vm._v("Tillad")]
                                            ),
                                          ]),
                                          _c("div", { staticClass: "col s6" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "scandi-btn",
                                                class: {
                                                  selected:
                                                    child.status === false,
                                                },
                                                attrs: {
                                                  role: "button",
                                                  tabindex: "0",
                                                  "aria-label": "Afvis",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setStatus({
                                                      cprNumber:
                                                        child.cprNumber,
                                                      status: false,
                                                    })
                                                  },
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.setStatus({
                                                      cprNumber:
                                                        child.cprNumber,
                                                      status: false,
                                                    })
                                                  },
                                                },
                                              },
                                              [_vm._v("Afvis")]
                                            ),
                                          ]),
                                        ]),
                                      ],
                                ],
                                2
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  : [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col s12 l6 offset-l3 center-align mb-1",
                          },
                          [
                            _c("span", { staticClass: "text-info" }, [
                              _vm._v(_vm._s(_vm.texts.noData)),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                _c("div", { staticClass: "row mt-2" }, [
                  _vm.anyPending
                    ? _c("div", { staticClass: "col s12 l4 offset-l4" }, [
                        _c(
                          "div",
                          {
                            staticClass: "scandi-btn mb-2",
                            attrs: {
                              role: "button",
                              "aria-label": "Indsend",
                              tabindex: "0",
                            },
                            on: {
                              click: _vm.trySubmit,
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.trySubmit.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Indsend")]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "col s12 l4 offset-l4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "scandi-btn",
                        attrs: {
                          role: "button",
                          "aria-label": "Log af",
                          tabindex: "0",
                        },
                        on: {
                          click: _vm.signOut,
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.signOut.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Log af")]
                    ),
                  ]),
                ]),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }