const heightValidator = {
  messages: {
    da: (field) => field + ' skal være et helt tal mellem 40 og 240 cm.'
  },
  getMessage: {
    da: (field) => field + ' skal være et helt tal mellem 40 og 240 cm.'
  },
  validate(value) {
    if (value.match(/^[0-9]+$/)) {
      const intValue = parseInt(value)
      return intValue > 39 && intValue < 241
    }

    return false
  }
}
export default heightValidator
