var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("notification", {
        staticStyle: { position: "fixed" },
        attrs: {
          show: _vm.centerPhotoBlocked,
          text: _vm.texts.centerPhotoBlockedNotification,
          icon: "/static/camerawhite.svg",
        },
      }),
      _c("div", { staticClass: "row hide-on-large-only" }, [
        _c(
          "div",
          {
            staticClass: "col s12 center-align",
            class: { "my-3": _vm.centerPhotoBlocked },
          },
          [
            _c("h2", { staticClass: "text-view-title" }, [
              _vm._v(_vm._s(_vm.texts.title)),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "row panes panes-auto-height" }, [
        _c(
          "div",
          { staticClass: "col s12 l6 pa-0 pane hide-on-med-and-down" },
          [
            _c("div", { staticClass: "pane-triangle" }),
            _c(
              "div",
              { staticClass: "steps" },
              [
                _c(
                  "vue-perfect-scrollbar",
                  { staticClass: "steps-container" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col s12 center-align pb-2" }, [
                        _c("h2", { staticClass: "text-view-title" }, [
                          _vm._v(_vm._s(_vm.texts.title)),
                        ]),
                      ]),
                    ]),
                    _vm._l(
                      _vm.availablePhotoChoicesMeta,
                      function (photoChoiceMeta) {
                        return _c("div", { staticClass: "row step" }, [
                          _c(
                            "div",
                            { staticClass: "col s2 offset-s1 l4 offset-l1" },
                            [
                              _c("img", {
                                staticClass: "step-icon",
                                attrs: {
                                  src: photoChoiceMeta.iconUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("div", { staticClass: "col s8 l6" }, [
                            _c("div", { staticClass: "step-content" }, [
                              _c("h4", { staticClass: "step-title" }, [
                                _vm._v(_vm._s(photoChoiceMeta.title)),
                              ]),
                              _c("p", { staticClass: "step-text" }, [
                                _vm._v(_vm._s(photoChoiceMeta.text)),
                              ]),
                            ]),
                          ]),
                        ])
                      }
                    ),
                    _c("div", { staticClass: "row step" }, [
                      _c("div", {
                        staticClass: "col s2 offset-s1 l4 offset-l1",
                      }),
                      _c("div", { staticClass: "col s8 l6" }, [
                        _c("div", { staticClass: "step-content" }, [
                          _c(
                            "a",
                            {
                              staticClass: "photo-rule-link step-text-no-color",
                              attrs: {
                                href: "https://politi.dk/pas/krav-til-pas-og-koerekortfoto",
                                target: "_blank",
                                tabindex: "0",
                              },
                            },
                            [_vm._v(_vm._s(_vm.texts.rulesLinkText))]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "col s12 l6 pane stretch-children-horizontal" },
          [
            _vm._l(_vm.personPhotoChoices, function (personPhotoChoice) {
              return _c("div", { staticClass: "row" }, [
                _vm.personsCount > 1
                  ? _c(
                      "div",
                      { staticClass: "col s10 offset-s1 center-align" },
                      [
                        _c("h2", { staticClass: "text-view-title" }, [
                          _vm._v(_vm._s(personPhotoChoice.person.name)),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col s10 offset-s1" }, [
                  _c(
                    "ol",
                    {
                      staticClass: "scandi-list always-vertical",
                      attrs: {
                        role: "radiogroup",
                        "aria-label":
                          "Vælg om du vil fotograferes i borgerservice eller selv medbringe et foto?",
                      },
                    },
                    _vm._l(personPhotoChoice.choices, function (photoChoice) {
                      return _c(
                        "li",
                        {
                          staticClass:
                            "scandi-list-item scandi-list-minor-padding",
                          class: {
                            selected:
                              personPhotoChoice.photoChoice === photoChoice.key,
                          },
                          attrs: {
                            role: "radio",
                            tabindex: "0",
                            "aria-label": photoChoice.meta.title,
                            "aria-checked":
                              personPhotoChoice.photoChoice === photoChoice.key,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onPhotoChoiceSelected(
                                personPhotoChoice.person.id,
                                photoChoice.key
                              )
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.onPhotoChoiceSelected(
                                personPhotoChoice.person.id,
                                photoChoice.key
                              )
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "scandi-list-title" }, [
                            _vm._v(_vm._s(photoChoice.meta.titleWithPrice)),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "scandi-list-text hide-on-large-only",
                            },
                            [_vm._v(_vm._s(photoChoice.meta.text))]
                          ),
                          photoChoice.meta.key === "bringownphoto"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "step-content center-align hide-on-large-only",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "photo-rule-link step-text",
                                      attrs: {
                                        href: "https://politi.dk/pas/krav-til-pas-og-koerekortfoto",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.texts.rulesLinkText))]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            }),
            _c("navigation-buttons", {
              staticClass: "bottom-nav desktop hide-on-med-and-down",
              attrs: {
                nextEnabled: _vm.nextStepAllowed,
                onPreviousClick: _vm.onPreviousStepClicked,
                onNextClick: _vm.onNextStepClicked,
              },
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "row before-bottom-nav hide-on-large-only" }),
      _c("navigation-buttons", {
        staticClass: "bottom-nav hide-on-large-only",
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }