var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c(
        "div",
        { staticClass: "row before-bottom-nav before-bottom-nav-center-align" },
        [
          _c("div", { staticClass: "col s12" }, [
            _c(
              "h2",
              {
                staticClass:
                  "text-view-title rm-margin-bottom center-align mt-2",
              },
              [_vm._v(_vm._s(_vm.texts.title))]
            ),
            _c("div", { staticClass: "row center-align" }, [
              _c("h4", { staticClass: "text-view-subtitle" }, [
                _vm._v(_vm._s(_vm.texts.info1)),
              ]),
            ]),
            _c("div", { staticClass: "row center-align" }, [
              _c("h4", { staticClass: "text-view-subtitle" }, [
                _vm._v(_vm._s(_vm.texts.info2)),
              ]),
            ]),
            _c("div", { staticClass: "row center-align" }, [
              _c("h4", { staticClass: "text-view-subtitle" }, [
                _vm._v(_vm._s(_vm.texts.info3)),
              ]),
            ]),
          ]),
        ]
      ),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: { showNext: false, onPreviousClick: _vm.onPreviousStepClicked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }