import store from '@/store'
import router from '@/router'
import axios from 'axios'
import QueryString from 'qs'
import RouteNames from '@/models/routes'
import { v4 as uuidv4 } from 'uuid'

let mainAxios = axios.create({
  paramsSerializer: {
    serialize: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' })
  }
})

mainAxios.defaults.baseURL = '/api/'

mainAxios.interceptors.request.use(
  (config) => {
    config.headers['Correlation-Id'] = uuidv4()

    if (router.currentRoute.params.municipalityCode) {
      config.headers['Municipality-Code'] = router.currentRoute.params.municipalityCode
    }

    if (store.state.application.id) {
      config.headers['Application-Id'] = store.state.application.id
    }

    if (store.getters['oidc/oidcIsAuthenticated']) {
      config.headers['Authorization'] = `Bearer ${store.getters['oidc/oidcAccessToken']}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

mainAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('oidc/removeOidcUser')
      router.push({ name: RouteNames.Logout })
    } else {
      return Promise.reject(error)
    }
  }
)

export default mainAxios
