import { PhotoChoices } from '..'

const photoChoices = [
  {
    title: 'Brug mobiltelefon',
    text: 'Tag selv dit billede her og nu med mobiltelefon eller tablet',
    iconUrl: '/static/photo_app_logo.svg',
    showPrice: true,
    key: PhotoChoices.SelfTakenPhoto
  },
  {
    title: 'Fotografering i borgerservice',
    text: 'Du kan hurtigt og nemt få taget et godkendt foto hos dit lokale borgerservicecenter',
    iconUrl: '/static/center.svg',
    showPrice: true,
    key: PhotoChoices.CenterPhoto
  },
  {
    title: 'Medbring selv pasfoto',
    text: 'Du skal selv sørge for at få taget et gyldigt pasfoto',
    iconUrl: '/static/camera.svg',
    showPrice: false,
    key: PhotoChoices.BringOwnPhoto
  }
]

export default photoChoices
