import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import da from 'vee-validate/dist/locale/da'
import heightValidator from './height-validator.js'
import phoneNumberValidator from './phoneNumber-validator.js'
import phoneNumberOrEmailValidator from './phoneNumberOrEmail-validator.js'

const dic = {
  da: {
    custom: {
      emailRepeat: {
        confirmed: 'De to indtastede e-mail adresser skal være identiske'
      }
    }
  }
}

Validator.extend('height', heightValidator)
Validator.extend('phoneNumber', phoneNumberValidator)
Validator.extend('phoneNumberOrEmail', phoneNumberOrEmailValidator)
Validator.updateDictionary(dic)

Validator.addLocale(da)

Vue.use(VeeValidate, {
  locale: 'da',
  strict: false
})
