<template lang="pug">
include situation-stolen.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import NavigationButtons from '@/components/shared/navigationButtons'

import { mutationTypes } from '@/store/application'
const { mapMutations: mapApplicationMutations } = createNamespacedHelpers('application')
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'SituationStolen',
  components: {
    navigationButtons: NavigationButtons
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.situationStolen
    })
  },
  methods: {
    ...mapApplicationMutations({
      setPreviousDocumentLost: mutationTypes.SET_PREVIOUS_DOCUMENT_LOST
    }),
    onPreviousStepClicked: function () {
      this.setPreviousDocumentLost(false)
    }
  }
}
</script>
