var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _vm.personApplication && _vm.applicationType === "passport"
        ? _c("situation-details-passport")
        : _vm._e(),
      _vm.personApplication && _vm.applicationType === "drivinglicense"
        ? _c("situation-details-driving-license")
        : _vm._e(),
      _c("div", { staticClass: "before-bottom-nav" }),
      _c("navigation-buttons", {
        staticClass: "bottom-nav mt-2",
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }