var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c(
        "focus-loop",
        { attrs: { "is-visible": _vm.promptConfirmation } },
        [
          _c("existing-case-modal", {
            attrs: {
              onCancel: _vm.onConfirmationCancel,
              onAccept: _vm.acceptNextStepConfirmation,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
          _c("h2", { staticClass: "text-view-title mb-1 mt-2" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
          _c("h2", { staticClass: "text-view-subtitle mt-0 mb-1" }, [
            _vm._v(_vm._s(_vm.texts.subtitle)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row before-bottom-nav mb-2" }, [
        _c(
          "div",
          { staticClass: "col s10 offset-s1" },
          [
            _vm._l(_vm.existingCases, function (existingCase) {
              return [
                _c("div", { staticClass: "row my-1" }, [
                  _c("div", { staticClass: "col s12 center-align" }, [
                    _c("span", { staticClass: "text-view-title" }, [
                      _vm._v(_vm._s(existingCase.name)),
                    ]),
                    _c("span", { staticClass: "scandi-list-text" }, [
                      _vm._v(_vm._s(_vm._f("hideCpr")(existingCase.cprNumber))),
                    ]),
                    existingCase.hasSecondGuardianSignature !== null
                      ? _c("span", { staticClass: "scandi-list-text" }, [
                          _vm._v(
                            "Godkendt af anden værge: " +
                              _vm._s(
                                _vm._f("boolToYesNo")(
                                  existingCase.hasSecondGuardianSignature
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "scandi-list-text" }, [
                      _vm._v(
                        "Betaling reserveret: " +
                          _vm._s(_vm._f("boolToYesNo")(existingCase.hasPayment))
                      ),
                    ]),
                    existingCase.bookingTime
                      ? _c("span", { staticClass: "scandi-list-text" }, [
                          _vm._v(
                            "Mødetidspunkt " +
                              _vm._s(
                                _vm._f("moment")(
                                  existingCase.bookingTime,
                                  "DD. MMMM YYYY - HH:mm"
                                )
                              )
                          ),
                        ])
                      : _vm._e(),
                    _c("span", { staticClass: "scandi-list-text" }, [
                      _vm._v(
                        "Oprettet " +
                          _vm._s(
                            _vm._f("moment")(
                              existingCase.createdAt,
                              "DD. MMMM YYYY - HH:mm"
                            )
                          )
                      ),
                    ]),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm.paymentReservation
        ? _c("div", { staticClass: "row before-bottom-nav-bottom-align" }, [
            _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
              _c("h2", { staticClass: "text-view-subtitle mt-0 mb-1" }, [
                _vm._v(_vm._s(_vm.texts.warningCancelWithPayment)),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "row before-bottom-nav-bottom-align" }, [
            _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
              _c("h2", { staticClass: "text-view-subtitle mt-0 mb-1" }, [
                _vm._v(_vm._s(_vm.texts.warningCancelWithoutPayment)),
              ]),
            ]),
          ]),
      _vm.secondGuardianIsAlreadyApproved
        ? _c("div", { staticClass: "row before-bottom-nav-bottom-align" }, [
            _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
              _c("h2", { staticClass: "text-view-subtitle mt-0 mb-1" }, [
                _vm._v(
                  _vm._s(_vm.texts.warningCancelWithSecondGuardianSignature)
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: {
          onPreviousClick: _vm.onPreviousStepClicked,
          showPrevious: _vm.previousStepAvailable,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }