<template lang="pug">
include photo-acceptance.pug
</template>
<style lang="scss" scoped>
@import 'photo-acceptance';
</style>

<script>
import router from '@/router'
import { PhotoFlowSteps, PhotoFlowTypes } from '@/models'
import { createNamespacedHelpers } from 'vuex'
import { mutationTypes, actionTypes } from '@/store/photo'

const photoStore = createNamespacedHelpers('photo')

export default {
  name: 'PhotoAcceptance',
  props: {
    photoFlowType: {
      type: String
    },
    photoId: {
      type: String
    }
  },
  methods: {
    ...photoStore.mapActions({
      process: actionTypes.PROCESS,
      accept: actionTypes.ACCEPT,
      update: actionTypes.UPDATE
    }),
    ...photoStore.mapMutations({
      setAcceptedPhotoForCurrentPerson: mutationTypes.SET_ACCEPTED_PHOTO_FOR_CURRENT_PERSON
    }),
    onFileChange(e) {
      this.process(e.target.files[0])
    },
    onAcceptClicked: function () {
      if (this.photoFlowType === PhotoFlowTypes.PhotoUpdateFlow) {
        this.update({
          oldPhotoId: this.photoId,
          newPhotoId: this.possiblePhoto.id
        }).then(() => router.push({ name: `${PhotoFlowTypes.PhotoUpdateFlow}-${PhotoFlowSteps.Finish}` }))
      } else {
        this.setAcceptedPhotoForCurrentPerson()
        this.accept({
          personId: this.currentPhotoPersonId,
          photoId: this.possiblePhoto.id
        }).then(() => router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Take}` }))
      }
    }
  },
  computed: {
    ...photoStore.mapState({
      possiblePhoto: (state) => state.possiblePhoto,
      currentPhotoPersonId: (state) => state.currentPhotoPersonId,
      photoChoices: (state) => state.photoChoices
    }),
    name: function () {
      if (this.photoFlowType === PhotoFlowTypes.PhotoUpdateFlow) {
        return 'Dit nye billede:'
      }
      return this.photoChoices.filter((c) => c.id === this.currentPhotoPersonId)[0].name
    }
  },
  mounted: function () {
    if (this.possiblePhoto === undefined) {
      router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Guidelines}` })
    }
  }
}
</script>
