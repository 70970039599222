var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container view-content-centered" },
    [
      _c("label", { staticClass: "nameLabel mt-2" }, [
        _vm._v(_vm._s(_vm.name)),
      ]),
      _c("img", {
        attrs: {
          src: "data:image/jpeg;base64," + _vm.possiblePhoto.data,
          alt: "",
        },
      }),
      !_vm.possiblePhoto.assessment.compliant
        ? _c("span", { staticClass: "validation-error" }, [
            _vm._v(
              "Fejl: " +
                _vm._s(_vm.possiblePhoto.assessment.warnings.join("\n"))
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "bottom-nav multiple" }, [
        _c(
          "div",
          {
            staticClass: "bottom-nav-btn",
            attrs: { tabIndex: "0", role: "button", "aria-label": "Tag om" },
            on: {
              click: function ($event) {
                return _vm.$refs.file.click()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.$refs.file.click()
              },
            },
          },
          [
            _c("img", {
              staticClass: "bottom-nav-btn-icon",
              attrs: { src: "/static/refresh.svg", alt: "opdater" },
            }),
            _c("span", { staticClass: "bottom-nav-btn-text" }, [
              _vm._v("Tag om"),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "bottom-nav-btn",
            attrs: { tabIndex: "0", role: "button", "aria-label": "Godkend" },
            on: {
              click: _vm.onAcceptClicked,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onAcceptClicked.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "bottom-nav-btn-icon",
              attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
            }),
            _c("span", { staticClass: "bottom-nav-btn-text" }, [
              _vm._v("Godkend"),
            ]),
          ]
        ),
        _c("div", { staticClass: "image-upload" }, [
          _c(
            "label",
            {
              staticStyle: { display: "none" },
              attrs: { for: "cameraFileInput" },
            },
            [_vm._v("Foto")]
          ),
          _c("input", {
            ref: "file",
            attrs: {
              id: "cameraFileInput",
              type: "file",
              accept: "image/*",
              capture: "environment",
            },
            on: { change: _vm.onFileChange },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }