<template lang="pug">
include photo-finish.pug
</template>

<script>
import { PhotoFlowTypes } from '@/models'
import services from '@/services'

export default {
  name: 'PhotoFinish',
  props: {
    photoFlowType: {
      type: String
    }
  },
  computed: {
    message: function () {
      if (this.photoFlowType === PhotoFlowTypes.PhotoDeviceFlow) {
        return 'Du er nu færdig med at fotografere.'
      } else if (this.photoFlowType === PhotoFlowTypes.PhotoUpdateFlow) {
        return 'Dit foto er opdateret'
      }
    },
    minorMessage: function () {
      if (this.photoFlowType === PhotoFlowTypes.PhotoDeviceFlow) {
        return 'Fortsæt på din computer'
      }
    }
  },
  mounted: function () {
    if (this.photoFlowType === PhotoFlowTypes.PhotoDeviceFlow) {
      services.hub.abortSession()
    }
  }
}
</script>
