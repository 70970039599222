<template lang="pug">
include index.pug
</template>

<script>
export default {
  name: 'OrderInfo',
  props: {
    order: {
      type: Object
    }
  }
}
</script>
