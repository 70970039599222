<template lang="pug">
include index.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const globalStore = createNamespacedHelpers('globals')
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...globalStore.mapState({
      texts: (state) => state.texts.error
    }),
    ...oidcStore.mapGetters({
      isAuthenticated: 'oidcIsAuthenticated'
    })
  },
  methods: {
    ...oidcStore.mapActions({
      signOut: 'signOutOidc'
    }),
    onRetryClicked: function () {
      location.reload()
    }
  }
}
</script>
