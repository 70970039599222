var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "tradeTransition" } }, [
    _c("div", { staticClass: "box pa-2 scroll-box scandi-scrollbar" }, [
      _c("h4", [_vm._v("Betingelser")]),
      _c("h5", [_vm._v("Betaling")]),
      _c("span", [
        _vm._v(
          "I online-shoppen sker betalingen via en sikker forbindelse (SSL). Oplysningerne som indtastes bliver krypteret og kunden er den eneste, som kan læse oplysningerne. Den sikre forbindelse identificeres ved en lille hængelås ved adresselinjen i browseren. Ved betaling reserveres betalingen indtil handlen er godkendt af administrationen. Betalingen trækkes først fra Deres konto ved godkendelse."
        ),
      ]),
      _c("h5", [_vm._v("Pris")]),
      _c("span", [_vm._v("Prisen på den vare, der handles via  ")]),
      _c("a", { staticClass: "underlined-text", attrs: { tabindex: "0" } }, [
        _vm._v("selvbetjening.id-port.dk  "),
      ]),
      _c("span", [_vm._v("fremgår under den enkelte vare.")]),
      _c("h5", [_vm._v("Leveringsomkostninger")]),
      _c("span", [
        _vm._v(
          "Leveringsomkostningerne ved levering med almindelig post - B-post - afholdes af Kommunen."
        ),
      ]),
      _c("h5", [_vm._v("Levering")]),
      _c("span", [
        _vm._v(
          "Handel der er godkendt af administrationen leveres snarest og inden 8 dage."
        ),
      ]),
      _c("h5", [_vm._v("Fortrydelsesret")]),
      _c("span", [
        _vm._v(
          "Kunden har normalt fortrydelsesret ved handel over internettet i henhold til Fjernsalgsdirektivet og Forbrugeraftaleloven. Det indebærer at borgeren kan leveredet anmodede produkt retur inden 14 dage uden begrundelse, med mindre det drejer sig om et produkt der er tilpasset den enkelte kunde."
        ),
      ]),
      _c("h5", [_vm._v("Ansvar")]),
      _c("span", [
        _vm._v(
          'Kommunen har ikke erstatningsansvar ved bortkomst, tyveri eller lignende. Kommunen påtager sig ikke ansvar for eventuelle forsinkelser, indirekte skader og fejl medmindre disse er begået med forsæt eller grov uagtsomhed. Såfremt man modtager en beskadiget "varer" købt via  '
        ),
      ]),
      _c("a", { staticClass: "underlined-text", attrs: { tabindex: "0" } }, [
        _vm._v("selvbetjening.id-port.dk  "),
      ]),
      _c("span", [
        _vm._v(
          "skal man omgående rette henvendelse til kommunen. Det forudsættes at borgeren gør sig bekendt med kommunens handelsbetingelser ved anmodning og betaling over kommunens hjemmeside."
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }