<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
export default {
  name: 'TermsCheckbox',
  props: {
    checkBoxText: String,
    linkText: String,
    callback: Function,
    linkClicked: Function,
    injectedValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      termsAccepted: false
    }
  },
  methods: {
    onAcceptTermsClicked: function () {
      this.termsAccepted = !this.termsAccepted
      this.$emit('callback')
    },
    onLinkClicked: function () {
      this.$emit('linkClicked')
    }
  },
  mounted: function () {
    this.termsAccepted = this.injectedValue
  }
}
</script>
