var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align mt-1" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 center-align mb-1" }, [
          _c("h2", { staticClass: "text-view-subtitle mt-0" }, [
            _vm._v(_vm._s(_vm.texts.subtitle)),
          ]),
        ]),
      ]),
      _vm._l(_vm.guardians, function (guardian) {
        return [
          _c("div", { staticClass: "row scandi-list-item bg-light pt-2" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col s10 offset-s1 l6 offset-l3 center-align mb-1",
                },
                [
                  _c("span", { staticClass: "text-view-title" }, [
                    _vm._v(
                      _vm._s(
                        guardian.name ||
                          "Myndighedsindehaver for " +
                            guardian.children.join(",")
                      )
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col s10 offset-s1 l6 offset-l3 center-align mb-1",
                },
                [
                  guardian.hasFullCprNumber && guardian.isDigitalPostSupported
                    ? _c("span", { staticClass: "text-view-subtitle" }, [
                        _vm._v(_vm._s(_vm.texts.consentInfoDigitalPost)),
                      ])
                    : _vm._e(),
                  guardian.hasFullCprNumber && !guardian.isDigitalPostSupported
                    ? _c("span", { staticClass: "text-view-subtitle" }, [
                        _vm._v(_vm._s(_vm.texts.consentInfoEmail)),
                      ])
                    : _vm._e(),
                  !guardian.hasFullCprNumber
                    ? _c("span", { staticClass: "text-view-subtitle" }, [
                        _vm._v(_vm._s(_vm.texts.consentInfoCitizenCenter)),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
            guardian.hasFullCprNumber && !guardian.isDigitalPostSupported
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("email-input", {
                      staticClass:
                        "col s10 offset-s1 l6 xl4 offset-xl4 offset-l3 center-align my-1",
                      attrs: {
                        callback: function (data) {
                          return _vm.emailcallback(guardian.id, data)
                        },
                        injectedValue: _vm.emails[guardian.id],
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      }),
      _c("div", { staticClass: "row bg-light" }, [
        _c(
          "div",
          {
            staticClass:
              "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align my-2",
          },
          [
            _c("terms-checkbox", {
              attrs: {
                checkBoxText: _vm.texts.acceptTermsText,
                injectedValue: _vm.termsAccepted,
              },
              on: {
                callback: function ($event) {
                  return _vm.setTermsAccepted(!_vm.termsAccepted)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "before-bottom-nav" }),
      _c("navigation-buttons", {
        attrs: {
          nextEnabled: _vm.completed,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }