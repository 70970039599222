<template lang="pug">
include photo-choice.pug
</template>

<style lang="scss" scoped>
@import 'photo-choice';
</style>

<script>
import Notification from '@/components/shared/notification'
import NavigationButtons from '@/components/shared/navigationButtons'
import VuePerfectScrollbar from 'vue-perfect-scrollbar/index.vue'
import services from '@/services'
import { createNamespacedHelpers } from 'vuex'
import { PhotoChoices } from '@/models'

import { getterTypes, mutationTypes, actionTypes } from '@/store/application'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'PhotoChoice',
  components: {
    VuePerfectScrollbar,
    notification: Notification,
    navigationButtons: NavigationButtons
  },
  computed: {
    ...applictionStore.mapState({
      personApplications: (state) => state.personApplications.filter((i) => i.situation.newDocument)
    }),
    ...applictionStore.mapGetters({
      personsCount: getterTypes.PERSONS_COUNT,
      photoChoicesMeta: getterTypes.AVAILABLE_PHOTO_CHOICES_META
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.photoChoice
    }),
    personPhotoChoices: function () {
      var photoChoicesMeta = this.photoChoicesMeta
      return this.personApplications
        .filter((i) => i.availablePhotoChoices.length > 1)
        .map((i) => ({
          person: i.person,
          photoChoice: i.photoChoice,
          choices: i.availablePhotoChoices.map((c) => ({
            key: c,
            meta: photoChoicesMeta.find((m) => m.key === c)
          }))
        }))
    },
    availablePhotoChoicesMeta: function () {
      return this.photoChoicesMeta.filter((i) => this.personPhotoChoices.some((pc) => pc.choices.some((c) => c.key === i.key)))
    },
    nextStepAllowed: function () {
      return this.personApplications.every((i) => i.photoChoice !== PhotoChoices.Unknown)
    },
    centerPhotoBlocked: function () {
      return (
        this.photoChoicesMeta.some((i) => i.key === PhotoChoices.CenterPhoto) &&
        this.personApplications.some((i) => !i.availablePhotoChoices.includes(PhotoChoices.CenterPhoto))
      )
    }
  },
  methods: {
    ...applictionStore.mapActions({
      setPhotoChoices: actionTypes.SET_PHOTO_CHOICES
    }),
    ...applictionStore.mapMutations({
      setPhotoChoice: mutationTypes.SET_PHOTO_CHOICE
    }),
    onPhotoChoiceSelected: function (personId, photoChoice) {
      this.setPhotoChoice({ id: personId, photoChoice: photoChoice })
    },
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    onNextStepClicked: function () {
      this.setPhotoChoices().then(() => services.application.nextStep())
    }
  }
}
</script>
