var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      !this.noTimeslots
        ? _c("div", { staticClass: "scandi-container before-bottom-nav" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col s10 offset-s1 center-align my-1" },
                [
                  _c("h2", { staticClass: "text-view-title" }, [
                    _vm._v(_vm._s(_vm.texts.subtitle) + "          "),
                  ]),
                ]
              ),
            ]),
            !_vm.timeBookingRequired
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("switch-button", {
                      staticClass: "col s10 offset-s1 center-align mb-2",
                      attrs: {
                        injectedValue: _vm.bookTime,
                        onChange: _vm.setBookTime,
                        yesLabel: "Book en tid",
                        noLabel: "Book ikke en tid",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.bookTime
              ? _c("div", { staticClass: "div" }, [
                  _c(
                    "div",
                    { staticClass: "row horizontal-margin-2-percent" },
                    [
                      _c("div", { staticClass: "col s1 date-selected-fill" }),
                      _c("div", { staticClass: "col s10 selector-container" }, [
                        _c("div", { staticClass: "selector date" }, [
                          _c("img", {
                            staticClass: "selector-icon",
                            attrs: {
                              src: "/static/calendar.svg",
                              alt: "kalender",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "selector-datepicker datepicker-input",
                              attrs: { tabindex: "0" },
                              on: {
                                click: _vm.onChooseDate,
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onChooseDate.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("dateFormat")(_vm.selectedDay))
                              ),
                              _c("div", { staticClass: "arrow-down" }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.datePickerOpen,
                                  expression: "datePickerOpen",
                                },
                              ],
                              staticClass: "datepicker-overlay",
                              attrs: { tabindex: "0" },
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget) {
                                    return null
                                  }
                                  return _vm.closeDatePicker.apply(
                                    null,
                                    arguments
                                  )
                                },
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.closeDatePicker.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "FocusLoop",
                                { attrs: { "is-visible": _vm.datePickerOpen } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "datepicker-container" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "datepicker-exit-header",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "datepicker-btn",
                                              on: {
                                                click: _vm.closeDatePicker,
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "datepicker-btn-icon exit-btn",
                                                attrs: {
                                                  src: "/static/exit_timebooking.svg",
                                                  role: "button",
                                                  "aria-label":
                                                    "Luk datovælger",
                                                  tabindex: "0",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("v-date-picker", {
                                            ref: "dp",
                                            attrs: {
                                              tabindex: "-1",
                                              mode: "single",
                                              "is-expanded": "is-expanded",
                                              "is-required": "is-required",
                                              "available-dates":
                                                _vm.availableDates,
                                              locale: "da-DK",
                                              color: "green",
                                            },
                                            model: {
                                              value: _vm.selectedDay,
                                              callback: function ($$v) {
                                                _vm.selectedDay = $$v
                                              },
                                              expression: "selectedDay",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "row mt-1 px-0" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col px-0 s10 offset-s1 time-slot-selector",
                        attrs: {
                          role: "radiogroup",
                          "aria-label": "Vælg et tidspunkt på dagen",
                        },
                      },
                      [
                        _vm._l(
                          _vm.availableTimeSlots,
                          function (slot, slotIndex) {
                            return [
                              _vm.displayAllTimeslots || slotIndex < 12
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "time-slot scandi-btn",
                                      class: {
                                        selected:
                                          slot.id === _vm.selectedSlotId,
                                      },
                                      attrs: {
                                        role: "radio",
                                        "aria-label": slot,
                                        "aria-selected":
                                          slot == _vm.selectedSlotId,
                                        tabindex: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onTimeslotSelected(slot.id)
                                        },
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.onTimeslotSelected(slot.id)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "scandi-button-wrapper",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "scandi-text-wrapper",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("timeFormat")(
                                                      slot.datetime
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm.availableTimeSlots.length > 12
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col s10 offset-s1 l6 offset-l3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mx-0 my-1 scandi-button-more scandi-btn",
                                attrs: {
                                  role: "button",
                                  tabindex: "0",
                                  "aria-labelledby": "showAllTimeSlots",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showAllTimeSlots()
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.showAllTimeSlots()
                                  },
                                },
                              },
                              [_vm._m(0)]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.phoneRequired
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col s10 offset-s1 l6 offset-l3 mt-1 mb-0 center-align mb-1",
                    },
                    [
                      _c("h2", { staticClass: "ma-0 text-view-title" }, [
                        _vm._v(_vm._s(_vm.texts.phoneNumberHeader)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.phoneRequired
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col s10 offset-s1 l6 offset-l3 mb-1" },
                    [
                      _c("input-field", {
                        attrs: {
                          validationRules: "required|phoneNumber",
                          inputTextField: "Telefonnummer",
                          inputType: "number",
                          callback: _vm.phonenumbercallback,
                          injectedValue: _vm.phoneNumber,
                          ariaLabel: _vm.texts.phoneNumberHeader,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "scandi-container before-bottom-nav" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
                _c("h2", { staticClass: "text-view-title mb-2 mt-3 bold" }, [
                  _vm._v(_vm._s(_vm.texts.noAvailableTimeslots)),
                ]),
              ]),
            ]),
            _vm.phoneRequired
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col s10 offset-s1 l6 offset-l3 mt-1 mb-0 center-align mb-1",
                    },
                    [
                      _c("h2", { staticClass: "ma-0 text-view-title" }, [
                        _vm._v(_vm._s(_vm.texts.phoneNumberHeader)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.phoneRequired
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col s10 offset-s1 l6 offset-l3 mb-1" },
                    [
                      _c("input-field", {
                        attrs: {
                          validationRules: "required|phoneNumber",
                          inputTextField: "Telefonnummer",
                          inputType: "number",
                          callback: _vm.phonenumbercallback,
                          injectedValue: _vm.phoneNumber,
                          ariaLabel: _vm.texts.phoneNumberHeader,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-button-wrapper" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "scandi-text-wrapper",
            attrs: { id: "showMoreTimeslots" },
          },
          [_c("b", [_vm._v("Vis flere tider for dagen")])]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }