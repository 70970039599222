import Vue from 'vue'
import { mutationTypes } from './mutations'

const actionTypes = {
  LOAD_TEXTS: 'loadTexts',
  LOAD_GDPR_TEXT: 'loadGdprText'
}

const actions = {
  [actionTypes.LOAD_TEXTS]: async ({ commit }) => {
    const response = await Vue.axios.get(`texts`)
    commit(mutationTypes.SET_TEXTS, response.data)
  },
  [actionTypes.LOAD_GDPR_TEXT]: async ({ commit }) => {
    try {
      const response = await Vue.axios.get('texts/gdpr')
      commit(mutationTypes.SET_GDPR_TEXT, response.data)
    } catch {
      commit(mutationTypes.SET_ERROR, { message: 'Kommunes informationer omkring tekster kunne ikke findes' })
    }
  }
}

export { actionTypes }
export default actions
