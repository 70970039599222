import { PassportSituations } from '..'

const situations = [
  {
    title: 'Mit pas udløber eller er udløbet',
    text: 'og jeg vil gerne bestille et nyt',
    id: PassportSituations.Renewal
  },
  {
    title: 'jeg har ikke et pas',
    text: 'og jeg vil gerne bestille et',
    id: PassportSituations.New
  },
  {
    title: 'mit pas er beskadiget',
    text: 'og jeg vil gerne bestille et nyt',
    id: PassportSituations.Damaged
  },
  {
    title: 'mit pas er bortkommet',
    text: 'f.eks. tabt eller blevet væk',
    id: PassportSituations.Lost
  },
  {
    title: 'mit pas er blevet stjålet',
    text: 'og jeg vil gerne anmelde det',
    id: PassportSituations.Stolen
  }
]

export default situations
