<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
export default {
  name: 'Inputfield',
  props: {
    inputTextField: String,
    validationRules: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'Text'
    },
    injectedValue: {
      type: String,
      default: ''
    },
    callback: Function,
    editable: {
      type: Boolean,
      default: true
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  },
  Inject: ['$validator'],
  computed: {
    errorMessage: function () {
      return this.errors.first('inputfield') === null ? '-' : this.errors.first('inputfield')
    }
  },
  data() {
    return {
      inputValue: '',
      validCallbackSent: false
    }
  },
  mounted: function () {
    this.inputValue = this.injectedValue
  },
  watch: {
    injectedValue: function () {
      this.inputValue = this.injectedValue
      this.validCallbackSent = true
    },
    inputValue: function (val) {
      if (this.errors.first('inputfield') === null && this.inputValue !== '' && this.callback) {
        this.callback({
          fieldName: this.inputTextField,
          value: this.inputValue,
          validationAccepted: this.inputValue !== ''
        })
      }
    },
    errorMessage: function (val) {
      if (this.callback) {
        if (this.errors.first('inputfield') === null && this.inputValue !== '') {
          this.callback({
            fieldName: this.inputTextField,
            value: this.inputValue,
            validationAccepted: this.inputValue !== ''
          })
          this.validCallbackSent = true
        } else if (this.validCallbackSent && this.errors.first('inputfield') !== null) {
          this.callback({
            fieldName: this.inputTextField,
            value: this.inputValue,
            validationAccepted: false
          })
          this.validCallbackSent = false
        }
      }
    }
  }
}
</script>
