var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.applicationId ? "site-wrapper" : "view-content-centered"] },
    [
      _c(
        "focus-loop",
        { attrs: { "is-visible": _vm.promtLogout } },
        [_c("logout-modal", { attrs: { onCancel: _vm.onLogOffCancelled } })],
        1
      ),
      _c(
        "focus-loop",
        { attrs: { "is-visible": _vm.automaticLogout } },
        [
          _c("automatic-logout-modal", {
            attrs: { closeModal: _vm.closeAutomaticLogoutModal },
          }),
        ],
        1
      ),
      _vm.applicationId
        ? [
            _c("div", { staticClass: "scandi-nav" }, [
              _c("div", { staticClass: "nav-row" }, [
                _c("span", { staticClass: "user" }, [
                  _vm._v(_vm._s(_vm.person.name)),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "user logout",
                    attrs: { tabindex: "0" },
                    on: {
                      click: _vm.onLogOffClicked,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onLogOffClicked.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Log af")]
                ),
              ]),
              _c("div", { staticClass: "nav-row" }, [_c("progress-bar")], 1),
            ]),
            _c(
              "transition",
              { attrs: { name: _vm.transitionName, mode: "out-in" } },
              [
                _vm.isLoading
                  ? _c("loader", { attrs: { message: _vm.loadingMessage } })
                  : _c("router-view"),
              ],
              1
            ),
          ]
        : [
            _vm.isLoading
              ? [
                  _c(
                    "div",
                    { staticClass: "container" },
                    [_c("loader", { attrs: { message: _vm.loadingMessage } })],
                    1
                  ),
                ]
              : [
                  _c("div", { staticClass: "container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row",
                        attrs: {
                          role: "radiogroup",
                          "aria-label": _vm.texts.title,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "scandi-nav logout-section" },
                          [
                            _c("div", { staticClass: "nav-row" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "user logout",
                                  attrs: { tabindex: "0" },
                                  on: {
                                    click: _vm.onLogOffClicked,
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.onLogOffClicked.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Log af")]
                              ),
                            ]),
                          ]
                        ),
                        _vm.showApplicationSelection
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col s12 center-align width-limit-hundred my-2",
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "text-view-title width-limit-hundred",
                                      },
                                      [_vm._v(_vm._s(_vm.texts.title))]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "row mt-1" }, [
                                _c("div", { staticClass: "col s2 l4" }),
                                _vm.passportAccepted
                                  ? _c("div", { staticClass: "col s8 l4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "scandi-btn",
                                          class: {
                                            selected: _vm.flow == "passport",
                                          },
                                          attrs: {
                                            tabindex: "0",
                                            "aria-label": "Pas",
                                            role: "radio",
                                            "aria-checked":
                                              _vm.flow == "passport",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onFlowSelected(
                                                "passport"
                                              )
                                            },
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.onFlowSelected(
                                                "passport"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._m(0)]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "row mt-1" }, [
                                _c("div", { staticClass: "col s2 l4" }),
                                _vm.drivingLicenseAccepted
                                  ? _c("div", { staticClass: "col s8 l4" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "scandi-btn",
                                          class: {
                                            selected:
                                              _vm.flow == "drivinglicense",
                                          },
                                          attrs: {
                                            tabindex: "0",
                                            "aria-label": "Kørekort",
                                            role: "radio",
                                            "aria-checked":
                                              _vm.flow == "drivinglicense",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onFlowSelected(
                                                "drivinglicense"
                                              )
                                            },
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.onFlowSelected(
                                                "drivinglicense"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._m(1)]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          : [
                              _vm._m(2),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col s12 center-align width-limit-hundred my-2",
                                  },
                                  [
                                    _vm.applicationType === "passport" ||
                                    _vm.flow === "passport"
                                      ? _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "text-view-title width-limit-hundred",
                                          },
                                          [
                                            _vm._v(
                                              "Det er dit ansvar at overholde ind- og udrejseregler i de lande, du rejser til og bruger som transitland. Tyskland har f.eks. lige skærpet deres krav til pas ved ind- og udrejse."
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.applicationType === "passport" ||
                                    _vm.flow === "passport"
                                      ? _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "text-view-title width-limit-hundred",
                                          },
                                          [
                                            _vm._v(
                                              "Det er de lokale myndigheder i de enkelte lande, der fastlægger reglerne og afgør, om du overholder dem."
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.applicationType === "passport" ||
                                    _vm.flow === "passport"
                                      ? _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "text-view-title width-limit-hundred",
                                          },
                                          [
                                            _vm._v(
                                              "Du kan læse mere på Udenrigsministeriets hjemmeside "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "https://um.dk/rejse-og-ophold/rejse-til-udlandet/pas-og-visum",
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "pas og visum til udlandet"
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _c("navigation-buttons", {
                    staticClass: "bottom-nav",
                    attrs: {
                      showPrevious: false,
                      nextEnabled: _vm.nextStepAllowed,
                      onNextClick: _vm.onNextStepClicked,
                    },
                  }),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-button-wrapper" }, [
      _c("div", { staticClass: "scandi-icon-wrapper-hidden" }, [
        _c("img", {
          staticClass: "scandi-btn-icon",
          attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
        }),
      ]),
      _c("div", { staticClass: "scandi-text-wrapper" }),
      _vm._v("Pas"),
      _c("div", { staticClass: "scandi-icon-wrapper" }, [
        _c("img", {
          staticClass: "scandi-btn-icon",
          attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-button-wrapper" }, [
      _c("div", { staticClass: "scandi-icon-wrapper-hidden" }, [
        _c("img", {
          staticClass: "scandi-btn-icon",
          attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
        }),
      ]),
      _c("div", { staticClass: "scandi-text-wrapper" }),
      _vm._v("Kørekort"),
      _c("div", { staticClass: "scandi-icon-wrapper" }, [
        _c("img", {
          staticClass: "scandi-btn-icon",
          attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col s12 center-align width-limit-hundred my-2" },
        [
          _c(
            "h2",
            { staticClass: "text-view-bigtitle width-limit-hundred bold" },
            [_vm._v("Værd at vide")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }