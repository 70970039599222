<template lang="pug">
include index.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'LogoutModal',
  props: {
    onCancel: Function
  },
  methods: {
    ...oidcStore.mapActions({
      signOut: 'signOutOidc'
    })
  }
}
</script>
