import { render, staticRenderFns } from "./security-cabinet-documents.vue?vue&type=template&id=5e4a391a&lang=pug&"
import script from "./security-cabinet-documents.vue?vue&type=script&lang=js&"
export * from "./security-cabinet-documents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/src/SelfService/App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e4a391a')) {
      api.createRecord('5e4a391a', component.options)
    } else {
      api.reload('5e4a391a', component.options)
    }
    module.hot.accept("./security-cabinet-documents.vue?vue&type=template&id=5e4a391a&lang=pug&", function () {
      api.rerender('5e4a391a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/security-cabinet/security-cabinet-documents.vue"
export default component.exports