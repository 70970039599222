var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
        _c("h2", { staticClass: "text-view-title mb-1 mt-2" }, [
          _vm._v(_vm._s(_vm.texts.title)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
        _c("span", { staticClass: "text-info" }, [
          _vm._v(_vm._s(_vm.texts.subtitle)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
        _c("span", { staticClass: "text-info" }, [
          _vm._v(_vm._s(_vm.texts.experiencingProblems)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
        _c("span", { staticClass: "text-info-small" }, [
          _vm._v(_vm._s(_vm.texts.watchVideo)),
        ]),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
        _c("iframe", {
          attrs: {
            frameborder: "0",
            scrolling: "no",
            marginheight: "0",
            marginwidth: "0",
            type: "text/html",
            src: "https://www.youtube.com/embed/2ueACGd6FO0?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0",
            allowfullscreen: "allowfullscreen",
            seamless: "seamless",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }