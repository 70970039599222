<template lang="pug">
include take-photo.pug
</template>

<style lang="scss" scoped>
@import 'take-photo';
</style>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import router from '@/router'
import services from '@/services'
import { PhotoChoices, PhotoFlowSteps, PhotoFlowTypes } from '@/models'
import { createNamespacedHelpers } from 'vuex'

import { mutationTypes, actionTypes } from '@/store/photo'

const photoStore = createNamespacedHelpers('photo')
const { mapActions: mapApplicationActions } = createNamespacedHelpers('application')

export default {
  name: 'TakePhoto',
  props: {
    photoFlowType: {
      type: String
    }
  },
  components: {
    navigationButtons: NavigationButtons
  },
  methods: {
    ...photoStore.mapMutations({
      setCurrentPhotoPersonId: mutationTypes.SET_CURRENT_PHOTO_PERSON_ID
    }),
    ...photoStore.mapActions({
      process: actionTypes.PROCESS
    }),
    ...mapApplicationActions({
      getApplicationPhotos: 'getPhotos'
    }),
    async onFileChange(e) {
      await this.process(e.target.files[0])
      router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Accept}` })
    },
    onPreviousStepClicked: function () {
      router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Guidelines}` })
    },
    onNextStepClicked: function () {
      if (this.photoFlowType === PhotoFlowTypes.PhotoDeviceFlow && !this.allPhotosAreTaken) {
        this.showModal = true
        return
      } else if (this.allPhotosAreTaken) {
        this.proceedToNextStep()
      }
    },
    proceedToNextStepCancelled: function () {
      this.showModal = false
    },
    proceedToNextStep: function () {
      if (this.photoFlowType === PhotoFlowTypes.PhotoDeviceFlow) {
        router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Finish}` })
      } else {
        this.getApplicationPhotos().then(() => services.application.nextStep())
      }
    },
    onTakePhoto: function (photoChoice) {
      this.setCurrentPhotoPersonId(photoChoice.id)
      this.currentPhotoChoice = photoChoice
      this.showConfirmation = true
    },
    photoCancelled: function () {
      this.showConfirmation = false
    }
  },
  data() {
    return {
      currentPhotoChoice: null,
      showModal: false,
      showConfirmation: false
    }
  },
  computed: {
    ...photoStore.mapState({
      photoChoices: (state) => state.photoChoices.filter((p) => p.photoChoice === PhotoChoices.SelfTakenPhoto),
      photosAreLocked: (state) => state.photosAreLocked
    }),
    isPhotoApplicationFlow: function () {
      return this.photoFlowType === PhotoFlowTypes.PhotoApplicationFlow
    },
    photoChoicesNeedPhoto: function () {
      return this.photoChoices.filter((p) => !p.photo)
    },
    photoChoicesWithPhoto: function () {
      return this.photoChoices.filter((p) => p.photo)
    },
    allPhotosAreTaken: function () {
      return this.photoChoices.every((i) => i.photoId)
    }
  },
  mounted: function () {
    if (!this.photoChoices.length) {
      router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Guidelines}` })
    }
  }
}
</script>
