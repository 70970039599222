var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container view-content-centered" },
    [
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.showModal
          ? _c("div", { staticClass: "modal-mask" }, [
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm._t("default", function () {
                        return [
                          _vm._v(
                            "Er du sikker på du ønsker at fortsætte? Alle personer er ikke fotograferet"
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "button",
                      {
                        staticClass: "scandi-btn scandi-button-wrapper",
                        on: { click: _vm.proceedToNextStep },
                      },
                      [_vm._v("JA")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "scandi-btn scandi-button-wrapper",
                        on: { click: _vm.proceedToNextStepCancelled },
                      },
                      [_vm._v("NEJ")]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.showConfirmation
          ? _c("div", { staticClass: "modal-mask" }, [
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm.currentPhotoChoice.photo
                        ? _vm._t("default", function () {
                            return [
                              _vm._v(
                                "Du har allerede taget et foto af " +
                                  _vm._s(_vm.currentPhotoChoice.name) +
                                  ". Vil du tage et nyt?"
                              ),
                            ]
                          })
                        : _vm._t("default", function () {
                            return [
                              _vm._v(
                                "Du skal nu fotografere " +
                                  _vm._s(_vm.currentPhotoChoice.name)
                              ),
                            ]
                          }),
                    ],
                    2
                  ),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "button",
                      {
                        staticClass: "scandi-btn scandi-button-wrapper",
                        on: {
                          click: function ($event) {
                            return _vm.$refs.file.click()
                          },
                        },
                      },
                      [_vm._v("JA")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "scandi-btn scandi-button-wrapper",
                        on: { click: _vm.photoCancelled },
                      },
                      [_vm._v("NEJ")]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "image-upload" }, [
        _c(
          "label",
          {
            staticStyle: { display: "none" },
            attrs: { for: "cameraFileInput" },
          },
          [_vm._v("Foto")]
        ),
        _c("input", {
          ref: "file",
          attrs: {
            id: "cameraFileInput",
            type: "file",
            accept: "image/*",
            capture: "environment",
          },
          on: { change: _vm.onFileChange },
        }),
      ]),
      _vm.photosAreLocked
        ? _c("div", { staticClass: "row" }, [_vm._m(0)])
        : _c("div", { staticClass: "div" }, [
            _vm._m(1),
            _vm._m(2),
            _vm.photoChoices.length !== 0
              ? _c("div", { staticClass: "row before-bottom-nav" }, [
                  _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
                    _c(
                      "ol",
                      { staticClass: "scandi-list always-vertical" },
                      _vm._l(_vm.photoChoices, function (photoChoice) {
                        return _c(
                          "li",
                          {
                            staticClass:
                              "scandi-list-item-one-column scandi-list-minor-padding",
                            staticStyle: { "flex-direction": "row" },
                            on: {
                              click: function ($event) {
                                return _vm.onTakePhoto(photoChoice)
                              },
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onTakePhoto(photoChoice)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "scandi-list-title" }, [
                              _c("div", { staticClass: "image-upload" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "nameLabel",
                                    attrs: { for: photoChoice.id },
                                  },
                                  [_vm._v(_vm._s(photoChoice.name))]
                                ),
                              ]),
                            ]),
                            photoChoice.photo
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      "data:image/jpeg;base64," +
                                      photoChoice.photo,
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: "/static/silhouette.jpg",
                                    alt: "ukendt",
                                  },
                                }),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
      !_vm.photosAreLocked || _vm.isPhotoApplicationFlow
        ? _c("div", { staticClass: "row before-bottom-nav" })
        : _vm._e(),
      !_vm.photosAreLocked && !_vm.isPhotoApplicationFlow
        ? _c("navigation-buttons", {
            staticClass: "bottom-nav hide-on-large-only",
            attrs: { showPrevious: false, onNextClick: _vm.onNextStepClicked },
          })
        : _vm._e(),
      _vm.isPhotoApplicationFlow
        ? _c("navigation-buttons", {
            staticClass: "bottom-nav hide-on-large-only",
            attrs: {
              nextEnabled: _vm.allPhotosAreTaken,
              onPreviousClick: _vm.onPreviousStepClicked,
              onNextClick: _vm.onNextStepClicked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col s12 center-align" }, [
      _c("h2", { staticClass: "text-view-title mt-3" }, [
        _vm._v("This link is no longer valid."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row center-align" }, [
      _c("h2", { staticClass: "text-view-title mb-0 mt-3" }, [
        _vm._v("Vælg personen du ønsker at fotografere"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row center-align" }, [
      _c("h2", { staticClass: "text-view-subtitle my-1" }, [
        _vm._v('Tryk på "Færdig"-knappen når alle personer er fotograferet'),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }