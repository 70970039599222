import Vue from 'vue'
import { mutationTypes } from './mutations'
import { mutationTypes as globalMutationTypes } from './../globals/mutations'

const actionTypes = {
  LOAD_CHILDREN: 'loadChildren',
  SUBMIT: 'submit'
}

const actions = {
  [actionTypes.LOAD_CHILDREN]: async ({ commit }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      const response = await Vue.axios.get('verification')
      commit(mutationTypes.SET_CHILDREN, response.data)
    } catch {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.SUBMIT]: async ({ state, commit }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      await Vue.axios.post('verification', {
        cprNumberToAnswers: state.children
          .filter((i) => !i.isLocked && i.status !== undefined)
          .reduce((acc, curr) => ((acc[curr.cprNumber] = curr.status), acc), {})
      })

      commit(mutationTypes.LOCK_SUBMITTED)
    } catch {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  }
}

export { actionTypes }
export default actions
