import mutations, { mutationTypes } from './mutations'
import actions, { actionTypes } from './actions'

const state = {
  texts: {
    gdprAcceptance: {},
    landing: {},
    applicationUsageWarning: {},
    center: {},
    personList: {},
    existingCases: {},
    otherGuardianConsent: {},
    personProgress: {},
    situation: {},
    situationLost: {},
    situationStolen: {},
    situationDetailsPassport: {},
    situationDetailsDrivingLicense: {},
    photoChoice: {},
    selfTakenPhotoConnectDevice: {},
    selfTakenPhotoPending: {},
    selfTakenPhotoReceived: {},
    timeBooking: {},
    summary: {},
    childApplicationAcceptance: {},
    securityCabinetDocuments: {},
    error: {},
    receipt: {}
  },
  gdprText: undefined,
  gdprStatus: undefined,
  error: undefined,
  maintenance: undefined
}

export { actionTypes, mutationTypes }

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  namespaced: true
}
