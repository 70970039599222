var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "scandi-container before-bottom-nav" }, [
        _c("div", { staticClass: "content content-centered" }, [
          _c("div", { staticClass: "row width-limit-hundred" }, [
            _c("div", { staticClass: "text-view-title" }, [
              _vm._v(_vm._s(_vm.texts.unsuccessfulTimeReservation)),
            ]),
          ]),
        ]),
      ]),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: { showNext: false, onPreviousClick: _vm.onPreviousStepClicked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }