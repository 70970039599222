import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import store from '..'

export default vuexOidcCreateStoreModule(
  {
    authority: location.origin.replace(/^(https?:\/\/)(www\.)?([^.])*/, `$1$2${'identity'}`),
    clientId: 'self_service_client',
    client_secret: 'F671C12D-50F1-49BC-9D2F-3C4CAD96B741',
    responseType: 'code',
    response_mode: 'query',
    redirect_uri: `${location.origin}/signin-oidc`,
    post_logout_redirect_uri: `${location.origin}/logout`,
    scope: 'openid self_service.applications offline_access',
    loadUserInfo: false,
    extraQueryParams: {
      auth_type: 'nem_login'
    }
  },
  {
    namespaced: true,
    dispatchEventsOnWindow: true
  },
  {
    accessTokenExpired: () => {
      store.dispatch('oidc/signOutOidc')
    }
  }
)
