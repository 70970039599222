var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align my-1",
        },
        [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(
              _vm._s(
                _vm.personApplication.caseType === "passport"
                  ? _vm.texts.questionCitizenshipAdult
                  : _vm.texts.questionCitizenshipChild
              )
            ),
          ]),
          _c("switch-button", {
            attrs: {
              injectedValue:
                _vm.personApplication.situation.hasBeenCitizenOfAnotherCountry,
              onChange: _vm.setHasBeenCitizenOfAnotherCountry,
            },
          }),
        ],
        1
      ),
    ]),
    _vm.personApplication.situation.previouslyHadDanishPassport &&
    !_vm.personApplication.situation.previousDocumentLost
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align my-1",
            },
            [
              _c("h2", { staticClass: "text-view-title" }, [
                _vm._v(
                  _vm._s(
                    _vm.personApplication.caseType === "passport"
                      ? _vm.texts.questionInPossessionOfOldPassportAdult
                      : _vm.texts.questionInPossessionOfOldPassportChild
                  )
                ),
              ]),
              _c("switch-button", {
                attrs: {
                  injectedValue:
                    _vm.personApplication.situation.inPossessionOfOldPassport,
                  onChange: _vm.setInPossessionOfOldPassport,
                },
              }),
              _c("h2", { staticClass: "text-view-subtitle ma-0 mt-1" }, [
                _c("b", [_vm._v("Bemærk: ")]),
                _vm._v(_vm._s(_vm.texts.questionInPossessionOfOldPassportNote)),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.requiresPersonHeigth
      ? _c("div", { staticClass: "row my-1" }, [
          _c(
            "div",
            {
              staticClass:
                "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align",
            },
            [
              _c("h2", { staticClass: "text-view-title" }, [
                _vm._v(_vm._s(_vm.texts.heightHeader)),
              ]),
              _c("input-field", {
                attrs: {
                  validationRules: "required|height",
                  inputTextField: _vm.texts.heightPlaceholder,
                  inputType: "number",
                  callback: _vm.heightcallback,
                  injectedValue: _vm.height,
                  ariaLabel: _vm.texts.heightHeader,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }