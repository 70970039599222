var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-wrapper" },
    [
      _c("transition", { attrs: { name: _vm.transistion } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "notification",
                attrs: { "aria-label": _vm.text },
              },
              [
                _c("div", { staticClass: "notification-content" }, [
                  _c("div", { staticClass: "notification-icon" }, [
                    _c("img", {
                      attrs: { src: _vm.icon, alt: "notifikation" },
                    }),
                  ]),
                  _c("div", { staticClass: "notification-text" }, [
                    _vm._v(_vm._s(_vm.text)),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }