var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content-centered" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col s12 center-align" },
          [
            _c("qr-code", {
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { text: _vm.link, size: _vm.size, "error-level": "L" },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12 center-align" }, [
        _c("h4", [
          _vm._v(
            "Åbn venligst linket på din mobile enhed i stedet for computeren. Du kan også scanne QR koden."
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }