const mutationTypes = {
  SET_DOCUMENTS: 'setDocuments',
  SET_PINCODE: 'setPinCode'
}

const mutations = {
  [mutationTypes.SET_DOCUMENTS]: (state, documents) => {
    state.documents = documents
  },
  [mutationTypes.SET_PINCODE]: (state, { contentUuid, pinCode }) => {
    state.documents = state.documents.map((i) =>
      i.contentUuid === contentUuid
        ? {
            ...i,
            pinCode: pinCode
          }
        : i
    )
  }
}

export { mutationTypes }
export default mutations
