import mutations, { mutationTypes } from './mutations'
import actions, { actionTypes } from './actions'

const state = {
  loader: {
    isLoading: undefined,
    loadingMessage: undefined
  },
  photoChoices: [],
  currentPhotoPersonId: '',
  possiblePhoto: undefined,
  photosAreLocked: false
}

export { actionTypes, mutationTypes }
export default {
  state: state,
  namespaced: true,
  mutations: mutations,
  actions: actions
}
