<template lang="pug">
include index.pug
</template>

<script>
export default {
  name: 'ProgressBarItem',
  props: {
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  }
}
</script>
