const RouteNames = {
  Logout: 'logout',
  UnsupportedBrowser: 'unsupportedBrowser',
  Maintenance: 'maintenance',

  Landing: 'landing',
  ApplicationStart: 'applicationStart',
  SelectCenter: 'selectCenter',
  Personlist: 'personlist',
  OtherGuardianConsent: 'otherGuardianConsent',
  ExistingCases: 'existingCases',
  PersonProgress: 'personprogress',
  Situation: 'situation',
  SituationDetails: 'situationDetails',
  PhotoChoice: 'photoChoice',
  SelfTakenPhoto: 'selfTakenPhoto',
  Booking: 'booking',
  UnsuccessfulReservation: 'unsuccessfulReservation',
  Summary: 'summary',
  Payment: 'payment',
  Receipt: 'Receipt'
}

export default RouteNames
