<template lang="pug">
include index.pug
</template>

<script>
import Loader from '@/components/shared/loader'
import { createNamespacedHelpers } from 'vuex'

const photoStore = createNamespacedHelpers('photo')

export default {
  name: 'Photo',
  components: {
    loader: Loader
  },
  computed: {
    ...photoStore.mapState({
      isLoading: (state) => state.loader.isLoading,
      loadingMessage: (state) => state.loader.loadingMessage
    })
  }
}
</script>
