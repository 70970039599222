import Application from '@/components/application/application'

import Personlist from '@/components/application/personlist/personlist'
import OtherGuardianConsent from '@/components/application/otherGuardianConsent/other-guardian-consent'

import ExistingCases from '@/components/application/existingCases/existingCases'
import PersonProgress from '@/components/application/personprogress/personprogress'

import Center from '@/components/application/center/center'

import Situation from '@/components/application/situation/situation'
import SituationDetails from '@/components/application/situationDetails/situation-details'

import PhotoChoice from '@/components/application/photoChoice/photo-choice'
import TimeBooking from '@/components/application/booking/timeBooking'
import UnsuccessfulReservation from '@/components/application/booking/unsuccessfulReservation'

import SelfTakenPhoto from '@/components/application/selfTakenPhoto/selftaken-photo'

import Summary from '@/components/application/summary/summary'
import Payment from '@/components/application/payment/payment'
import Receipt from '@/components/application/receipt/receipt'

import { ApplicationSteps, ApplicationTypes } from '@/models'
import RouteNames from '@/models/routes'

const meta = {
  requiresGDPR: true,
  requiresInitializedApplication: true
}

export default [
  {
    path: '',
    component: Application,
    name: RouteNames.Landing,
    props: true,
    meta: {
      requiresGDPR: true
    }
  },
  {
    path: `:applicationType(${ApplicationTypes.Passport}|${ApplicationTypes.DrivingLicense})`,
    component: Application,
    name: RouteNames.ApplicationStart,
    props: true,
    meta: {
      requiresGDPR: true
    },
    children: [
      {
        path: 'center',
        name: RouteNames.SelectCenter,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Start
        },
        component: Center
      },
      {
        path: 'personlist',
        name: RouteNames.Personlist,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Start
        },
        component: Personlist
      },
      {
        path: 'guardian-consent',
        name: RouteNames.OtherGuardianConsent,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Start
        },
        component: OtherGuardianConsent
      },
      {
        path: 'existingcases',
        name: RouteNames.ExistingCases,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Start
        },
        component: ExistingCases
      },
      {
        path: 'personprogress',
        name: RouteNames.PersonProgress,
        props: true,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Situation
        },
        component: PersonProgress
      },
      {
        path: 'situation',
        name: RouteNames.Situation,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Situation
        },
        component: Situation
      },
      {
        path: 'situation-details',
        name: RouteNames.SituationDetails,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Situation
        },
        component: SituationDetails
      },
      {
        path: 'fotovalg',
        name: RouteNames.PhotoChoice,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.PhotoChoice
        },
        component: PhotoChoice
      },
      {
        path: 'selftakenphoto/:step?',
        name: RouteNames.SelfTakenPhoto,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.PhotoChoice,
          requiresHubConnection: true
        },
        props: true,
        component: SelfTakenPhoto
      },
      {
        path: 'booktid',
        name: RouteNames.Booking,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.TimeBooking
        },
        component: TimeBooking
      },
      {
        path: 'unsuccessfulReservation',
        name: RouteNames.UnsuccessfulReservation,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.TimeBooking
        },
        component: UnsuccessfulReservation
      },
      {
        path: 'opsummering',
        name: RouteNames.Summary,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Summary
        },
        component: Summary
      },
      {
        path: 'payment',
        name: RouteNames.Payment,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Summary
        },
        component: Payment
      },
      {
        path: 'receipt',
        name: RouteNames.Receipt,
        meta: {
          ...meta,
          applicationStep: ApplicationSteps.Summary
        },
        component: Receipt
      }
    ]
  },
  {
    path: `:applicationType(${ApplicationTypes.Passport}|${ApplicationTypes.DrivingLicense})/receipt/:applicationId`,
    component: Receipt,
    props: true
  }
]
