var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accept-container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "terms" }, [
        _c(
          "div",
          {
            staticClass: "terms-checkbox",
            class: { selected: _vm.termsAccepted },
            attrs: {
              tabindex: "0",
              role: "checkbox",
              "aria-labelledby": "acceptText",
              "aria-checked": _vm.termsAccepted,
            },
            on: {
              click: _vm.onAcceptTermsClicked,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onAcceptTermsClicked.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "terms-checkbox-icon",
              attrs: { src: "/static/checkmarkgreen.svg", alt: "afkrydsning" },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "text-value text-accept",
            attrs: { id: "acceptText" },
          },
          [
            _vm._v(_vm._s(_vm.checkBoxText)),
            _vm.linkText
              ? _c(
                  "a",
                  {
                    staticClass: "terms-link",
                    attrs: { tabindex: "0" },
                    on: {
                      click: _vm.onLinkClicked,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onLinkClicked.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.linkText))]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }