<template lang="pug">
include index.pug
</template>

<script>

export default {
  name: 'Maintenance'
}
</script>
