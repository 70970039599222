import { DrivingLicenseSituation } from '@/models'

const matched = (val) => ({
  on: () => matched(val),
  otherwise: () => val
})
const match = (val) => ({
  on: (predicate, func) => (predicate(val) ? matched(func(val)) : match(val)),
  otherwise: (func) => func(val)
})

const LicenseEnumBuild = {
  unknown: 0,
  regularDuplikat: 10,
  regularInternationalt: 11,
  knallert: 12,
  regularFornyelse: 13,
  regularMidlertidigFornyelse: 14,
  largeFornyelse: 15,
  largeDuplikat: 16,
  temporaryLicense: 17
}
const LicenseTypeEnum = {
  regularvehicle: 1,
  largevehicle: 2,
  smallvehicle: 3
}

const RegularLicenseSituationToLicenseType = {
  [DrivingLicenseSituation.Renewal]: LicenseEnumBuild.regularFornyelse,
  [DrivingLicenseSituation.Lost]: LicenseEnumBuild.regularDuplikat,
  [DrivingLicenseSituation.International]: LicenseEnumBuild.regularInternationalt,
  [DrivingLicenseSituation.TempRenewal]: LicenseEnumBuild.temporaryLicense,
  [DrivingLicenseSituation.NameChange]: LicenseEnumBuild.regularDuplikat,
  [DrivingLicenseSituation.Damaged]: LicenseEnumBuild.regularDuplikat,
  [DrivingLicenseSituation.Old]: LicenseEnumBuild.regularDuplikat
}
const LargeLicenseSituationToLicenseType = {
  [DrivingLicenseSituation.Renewal]: LicenseEnumBuild.regularFornyelse,
  [DrivingLicenseSituation.Lost]: LicenseEnumBuild.regularDuplikat,
  [DrivingLicenseSituation.NameChange]: LicenseEnumBuild.regularFornyelse,
  [DrivingLicenseSituation.Damaged]: LicenseEnumBuild.regularDuplikat,
  [DrivingLicenseSituation.Old]: LicenseEnumBuild.regularDuplikat
}
const SmallLicenseSituationToLicenseType = {
  [DrivingLicenseSituation.Renewal]: LicenseEnumBuild.knallert,
  [DrivingLicenseSituation.Lost]: LicenseEnumBuild.knallert
}

export default {
  calculateDriverLicenseType: function (vehicle, licenseSituation) {
    let situationToLicenseType = match(vehicle)
      .on(
        (vt) => vt === LicenseTypeEnum.smallvehicle,
        () => SmallLicenseSituationToLicenseType
      )
      .on(
        (vt) => vt === LicenseTypeEnum.largevehicle,
        () => LargeLicenseSituationToLicenseType
      )
      .otherwise(() => RegularLicenseSituationToLicenseType)

    return situationToLicenseType[licenseSituation]
  }
}
