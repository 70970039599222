var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { attrs: { name: "sitation", mode: "out-in", tag: "div" } },
    [
      !_vm.stolen && !_vm.lost
        ? _c("situation-default", { key: "default" })
        : _vm._e(),
      _vm.lost ? _c("situation-lost", { key: "lost" }) : _vm._e(),
      _vm.stolen ? _c("situation-stolen", { key: "stolen" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }