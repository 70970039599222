import Vue from 'vue'
import imageCompression from 'browser-image-compression'
import { mutationTypes } from './mutations'
import { mutationTypes as globalMutationTypes } from './../globals/mutations'

const compressingOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 3000,
  useWebWorker: true
}

const actionTypes = {
  ACCEPT: 'accept',
  LOAD_BY_ID: 'getById',
  LOAD_INFO: 'getInfo',
  UPDATE: 'update',
  PROCESS: 'process'
}

const actions = {
  [actionTypes.ACCEPT]: async ({ commit }, { personId, photoId }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      await Vue.axios.put(`photos/${personId}/accept/${photoId}`)
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.LOAD_BY_ID]: async ({ commit }, photoId) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      const response = await Vue.axios.get(`photos/${photoId}`)
      commit(mutationTypes.SET_POSSIBLE_PHOTO, response.data)
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, { retriable: false }, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.LOAD_INFO]: async ({ commit }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      const response = await Vue.axios.get(`photos/info`)
      commit(mutationTypes.SET_PHOTO_CHOICES, { photoChoices: response.data.items, locked: response.data.photosAreLocked })
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.UPDATE]: ({ commit }, { oldPhotoId, newPhotoId }) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      return Vue.axios.put(`photos/update/${oldPhotoId}/${newPhotoId}`)
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  },
  [actionTypes.PROCESS]: async ({ commit }, photo) => {
    try {
      commit(mutationTypes.SET_LOADING, 'Vent et øjeblik...')
      const compressedFile = await imageCompression(photo, compressingOptions)
      let formData = new FormData()
      formData.append('file', compressedFile)
      const response = await Vue.axios.post('photos', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      commit(mutationTypes.SET_POSSIBLE_PHOTO, response.data)
    } catch (e) {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, { message: 'Fejl ved behandling af billede' }, { root: true })
      throw e
    } finally {
      commit(mutationTypes.RESET_LOADING)
    }
  }
}

export { actionTypes }
export default actions
