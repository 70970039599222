<template lang="pug">
include verification.pug
</template>

<script>
import Loader from '@/components/shared/loader'
import Notification from '@/components/shared/notification'
import SwitchButton from '@/components/shared/switchButton'
import { createNamespacedHelpers } from 'vuex'
import { actionTypes, mutationTypes } from '@/store/verification'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const verificationStore = createNamespacedHelpers('verification')
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'Verification',
  components: {
    Loader,
    Notification,
    SwitchButton
  },
  computed: {
    ...verificationStore.mapState({
      loader: (state) => state.loader,
      children: (state) => state.children,
      anyPending: (state) => state.children.some((i) => !i.isLocked),
      anySelected: (state) => state.children.filter((i) => !i.isLocked).some((i) => i.status !== undefined),
      allSelected: (state) => state.children.filter((i) => !i.isLocked).every((i) => i.status !== undefined)
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.childApplicationAcceptance
    }),
    anyItems: function () {
      return this.children.length > 0
    }
  },
  data: function () {
    return {
      isSubmitting: false
    }
  },
  methods: {
    ...verificationStore.mapMutations({
      setStatus: mutationTypes.SET_STATUS
    }),
    ...verificationStore.mapActions({
      loadChildren: actionTypes.LOAD_CHILDREN,
      submit: actionTypes.SUBMIT
    }),
    ...oidcStore.mapActions({
      signOut: 'signOutOidc'
    }),
    trySubmit: function () {
      this.isSubmitting = true

      if (this.allSelected) {
        this.submit()
        this.isSubmitting = false
      }
    },
    cancelSubmitting: function () {
      this.isSubmitting = false
    },
    continueSubmitting: function () {
      this.submit()
      this.isSubmitting = false
    }
  },
  mounted: function () {
    this.loadChildren()
  }
}
</script>
