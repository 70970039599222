var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
          _c(
            "h2",
            {
              staticClass: "text-view-title",
              attrs: { id: "situationHeader" },
            },
            [_vm._v(_vm._s(_vm.texts.title))]
          ),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 xl6 offset-xl3" }, [
          _c(
            "ol",
            {
              staticClass: "scandi-list",
              attrs: {
                role: "radiogroup",
                "aria-labelledby": "situationHeader",
              },
            },
            _vm._l(_vm.situations, function (situation) {
              return _c(
                "li",
                {
                  staticClass: "scandi-list-item",
                  class: {
                    selected:
                      _vm.personApplication.situation.id === situation.id,
                  },
                  attrs: {
                    tabindex: "0",
                    role: "radio",
                    "aria-label": situation.title + ", " + situation.text,
                    "aria-checked":
                      _vm.personApplication.situation.id === situation.id,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setSituationId(situation.id)
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.setSituationId(situation.id)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "scandi-list-title sitation-title" },
                    [_vm._v(_vm._s(situation.title) + ",")]
                  ),
                  _c("span", { staticClass: "scandi-list-text" }, [
                    _vm._v(_vm._s(situation.text)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("div", { staticClass: "row before-bottom-nav" }),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          showPrevious: _vm.previousStepAvailable,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }