<template lang="pug">
include situation-default.pug
</template>

<style lang="scss" scoped>
@import 'situation-default';
</style>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import { ApplicationTypes, PassportSituations, DrivingLicenseSituation } from '@/models'
import services from '@/services'
import { createNamespacedHelpers } from 'vuex'
import situations from '@/models/situations'

import { getterTypes, mutationTypes } from '@/store/application'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'SituationDefault',
  components: {
    navigationButtons: NavigationButtons
  },
  computed: {
    ...applictionStore.mapState({
      applicationType: (state) => state.applicationType
    }),
    ...applictionStore.mapGetters({
      personApplication: getterTypes.CURRENT
    }),
    situations: function () {
      return this.personApplication ? situations.get(this.personApplication.caseType) : []
    },
    ...mapGlobalState({
      texts: (state) => state.texts.situation
    }),
    previousStepAvailable: function () {
      return services.application.previousStepAvailable()
    },
    nextStepAllowed: function () {
      return this.personApplication && !!this.personApplication.situation.id
    }
  },
  methods: {
    ...applictionStore.mapMutations({
      setSituationId: mutationTypes.SET_SITUATION_ID,
      setNewDocument: mutationTypes.SET_NEW_DOCUMENT,
      setPreviousDocumentLost: mutationTypes.SET_PREVIOUS_DOCUMENT_LOST,
      setPreviouslyHadDanishPassport: mutationTypes.SET_PREVIOUSLY_HAD_DANISH_PASSPORT,
      setInPossessionOfOldPassport: mutationTypes.SET_IN_POSESSION_OF_OLD_PASSPORT
    }),
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    onNextStepClicked: function () {
      const situationId = this.personApplication.situation.id
      var lostOrStolen

      switch (this.applicationType) {
        case ApplicationTypes.Passport:
          lostOrStolen = [PassportSituations.Stolen, PassportSituations.Lost].includes(situationId)

          this.setPreviouslyHadDanishPassport(situationId !== PassportSituations.New)

          if (lostOrStolen) {
            this.setInPossessionOfOldPassport(false)
          }
          break
        case ApplicationTypes.DrivingLicense:
          lostOrStolen = DrivingLicenseSituation.Lost === situationId
          break
      }

      this.setPreviousDocumentLost(lostOrStolen)

      if (!lostOrStolen) {
        this.setNewDocument(true)
        services.application.nextStep()
      }
    }
  }
}
</script>
