var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container view-content-centered" },
    [
      _c("div", { staticClass: "row panes" }, [
        _c("div", { staticClass: "pane col s12 l6 pa-0 pane" }, [
          _c("div", { staticClass: "pane-triangle" }),
          _c(
            "div",
            { staticClass: "steps" },
            [
              _c("vue-perfect-scrollbar", { staticClass: "steps-container" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col s12 center-align" }, [
                    _c("h2", { staticClass: "text-view-title" }, [
                      _vm._v("Vigtigt"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row step align-items-center" }, [
                  _c("div", { staticClass: "col s2 l4 offset-s1" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: { src: "/static/human.svg", alt: "menneske" },
                    }),
                  ]),
                  _c("div", { staticClass: "col s8 l6 offset-s1" }, [
                    _c("div", { staticClass: "step-content" }, [
                      _c("h4", { staticClass: "step-title" }, [
                        _vm._v("Afstand"),
                      ]),
                      _c("p", { staticClass: "step-text" }, [
                        _vm._v(
                          "Hold kameraet i en afstand, så ansigt og skuldre er synlige på fotoet."
                        ),
                        _c("br"),
                        _vm._v(
                          "Det gør ikke noget at resten af kroppen eller baggrunden kommer med - det skæres automatisk væk, når du trykker godkend."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row step align-items-center" }, [
                  _c("div", { staticClass: "col s2 l4 offset-s1" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: {
                        src: "/static/ruler-triangle.svg",
                        alt: "lineal-trekant",
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col s8 l6 offset-s1" }, [
                    _c("div", { staticClass: "step-content" }, [
                      _c("h4", { staticClass: "step-title" }, [
                        _vm._v("Vinkel"),
                      ]),
                      _c("p", { staticClass: "step-text" }, [
                        _vm._v(
                          "Hold kameraet i en vandret linie ud for ansigtet, så fotoet ikke tages oppefra eller nedefra."
                        ),
                        _c("br"),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row step align-items-center" }, [
                  _c("div", { staticClass: "col s2 l4 offset-s1" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: { src: "/static/hijab.svg", alt: "hijab" },
                    }),
                  ]),
                  _c("div", { staticClass: "col s8 l6 offset-s1" }, [
                    _c("div", { staticClass: "step-content" }, [
                      _c("h4", { staticClass: "step-title" }, [
                        _vm._v("Hovedbeklædning"),
                      ]),
                      _c("p", { staticClass: "step-text" }, [
                        _vm._v(
                          "Hovedbeklædning er kun tilladt, hvis den bæres af religiøse årsager."
                        ),
                        _c("br"),
                        _vm._v(
                          "Pande, hageparti og kind skal dog altid være synlige."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "row step align-items-center" }, [
                  _c("div", { staticClass: "col s2 l4 offset-s1" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: { src: "/static/baby-face.svg", alt: "baby" },
                    }),
                  ]),
                  _c("div", { staticClass: "col s8 l6 offset-s1" }, [
                    _c("div", { staticClass: "step-content" }, [
                      _c("h4", { staticClass: "step-title" }, [
                        _vm._v("Små børn (< 2 år)   "),
                      ]),
                      _c("p", { staticClass: "step-text" }, [
                        _vm._v(
                          "Små børn behøves ikke kigge direkte i kameraet eller have munden lukket. Børn over 2 år skal følge samme regler som voksne."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                !_vm.isPhotoDeviceFlow
                  ? _c("div", { staticClass: "row step" }, [
                      _c("div", { staticClass: "col s12 l12" }, [
                        _c("div", { staticClass: "step-content" }, [
                          _c("p", { staticClass: "step-text center-align" }, [
                            _vm._v(
                              "Tryk her for at se en kort instruktionsvideo"
                            ),
                          ]),
                          _c("iframe", {
                            attrs: {
                              frameborder: "0",
                              scrolling: "no",
                              marginheight: "0",
                              marginwidth: "0",
                              type: "text/html",
                              src: "https://www.youtube.com/embed/2ueACGd6FO0?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0",
                              allowfullscreen: "allowfullscreen",
                              seamless: "seamless",
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "col s12 l6 pane stretch-children-horizontal" },
          [
            _vm._m(0),
            _c("navigation-buttons", {
              staticClass: "bottom-nav desktop hide-on-med-and-down",
              attrs: {
                showPrevious: _vm.isPhotoApplicationFlow,
                onPreviousClick: _vm.onPreviousStepClicked,
                onNextClick: _vm.onNextStepClicked,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row before-bottom-nav hide-on-large-only" }),
      _c("navigation-buttons", {
        staticClass: "bottom-nav hide-on-large-only",
        attrs: {
          showPrevious: _vm.isPhotoApplicationFlow,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s12 px-2" }, [
        _c("h2", { staticClass: "text-question center-align my-2" }, [
          _vm._v("Tryk videre når du er klar til at fotografere"),
          _c("br"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }