<template lang="pug">
include situation-details.pug
</template>

<script>
import SwitchButton from '@/components/shared/switchButton'
import { createNamespacedHelpers } from 'vuex'
import { getterTypes, mutationTypes } from '@/store/application'

const applictionStore = createNamespacedHelpers('application')
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'DrivignLicenseSituationDetails',
  components: {
    switchButton: SwitchButton
  },
  computed: {
    ...applictionStore.mapGetters({
      personApplication: getterTypes.CURRENT
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.situationDetailsDrivingLicense
    })
  },
  methods: {
    ...applictionStore.mapMutations({
      setHasLicenseForLargeVehicle: mutationTypes.SET_HAS_LICENSE_FOR_LARGE_VEHICLE,
      setKeepCommercialLicense: mutationTypes.SET_KEEP_COMMERCIAL_LICENSE,
      setHasCommercialLicense: mutationTypes.SET_HAS_COMMERCIAL_LICENSE
    })
  }
}
</script>
