<template lang="pug">
include security-cabinet-documents.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Loader from '@/components/shared/loader'
import services from '@/services'
import { actionTypes } from '@/store/securityCabinet'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const securityCabinetStore = createNamespacedHelpers('securityCabinet')
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'SecurityCabinetDocuments',
  components: {
    loader: Loader
  },
  computed: {
    ...securityCabinetStore.mapState({
      documents: (state) => state.documents
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.securityCabinetDocuments
    }),
    isLoaded: function () {
      return this.documents === undefined
    },
    anyItems: function () {
      return this.documents !== undefined && this.documents.length > 0
    },
    centerNames: function () {
      return [...new Set(this.documents.map((data) => data.centerName))]
    }
  },
  methods: {
    ...securityCabinetStore.mapActions({
      loadDocuments: actionTypes.LOAD_DOCUMENTS,
      generatePinCode: actionTypes.GENERATE_PINCODE
    }),
    ...oidcStore.mapActions({
      signOut: 'signOutOidc'
    })
  },
  mounted: function () {
    this.loadDocuments()
  }
}
</script>
