<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginCallback',
  methods: {
    ...mapActions('oidc', ['oidcSignInCallback'])
  },
  created() {
    this.oidcSignInCallback().then((redirectPath) => {
      this.$router.push(redirectPath)
    })
  }
}
</script>
