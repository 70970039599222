var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row valign-wrapper" }, [
      _vm._m(0),
      _c("div", { staticClass: "col s9 l10" }, [
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "text-value" }, [
            _vm._v(_vm._s(_vm.center.name)),
          ]),
          _c("span", { staticClass: "text-value" }, [
            _vm._v(_vm._s(_vm.center.address.addressLine)),
          ]),
          _c("span", { staticClass: "text-value" }, [
            _vm._v(
              _vm._s(_vm.center.address.postalCode) +
                " " +
                _vm._s(_vm.center.address.city)
            ),
          ]),
          _vm.bookingTime
            ? _c("span", { staticClass: "text-info" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("moment")(_vm.bookingTime, "DD. MMMM YYYY - HH:mm")
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s3 l2" }),
      _c("div", { staticClass: "col s9 l10" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm.anyLostDocument
              ? _c("span", { staticClass: "text-value" }, [
                  _c("i", [_vm._v(_vm._s(_vm.texts.attandanseInfoLostId))]),
                ])
              : _vm._e(),
            _vm.applicationType === "passport"
              ? [
                  _c("span", { staticClass: "text-value" }, [
                    _c("i", [
                      _vm._v(
                        _vm._s(_vm.texts.passportAttendanceInfoInNotification)
                      ),
                    ]),
                  ]),
                  _vm.anyAdultPassport
                    ? _c("span", { staticClass: "text-value" }, [
                        _c("i", [
                          _vm._v(_vm._s(_vm.texts.attendanceInfoAdultPassport)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.anyChildPassport
                    ? _c("span", { staticClass: "text-value" }, [
                        _c("i", [
                          _vm._v(_vm._s(_vm.texts.attendanceInfoChildPassport)),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : [
                  _c("span", { staticClass: "text-value" }, [
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm.texts.drivingLicenseAttendanceInfoInNotification
                        )
                      ),
                    ]),
                  ]),
                  _c("span", { staticClass: "text-value" }, [
                    _c("i", [
                      _vm._v(_vm._s(_vm.texts.attendanceInfoDrivingLicense)),
                    ]),
                  ]),
                  _vm.requiresMedicalCertificate
                    ? _c("span", { staticClass: "text-value" }, [
                        _c("i", [
                          _vm._v(
                            _vm._s(_vm.texts.attendanceInfoMedicalCertificate)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col s2 l2 xl1" }, [
      _c("img", {
        staticClass: "attendance-icon",
        attrs: { src: "/static/attendance.svg", alt: "fremmødested" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }