<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
export default {
  name: 'TradeConditions'
}
</script>
