<template lang="pug">
include receipt.pug
</template>

<script>
import OrderInfo from '@/components/application/shared/orderInfo'
import AttendanceInfo from '@/components/application/shared/attendanceInfo'
import { createNamespacedHelpers } from 'vuex'
import Moment from 'moment'
import { actionTypes } from '@/store/application'
import { CaseTypes } from '@/models'
import Loader from '@/components/shared/loader'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'Receipt',
  props: {
    applicationId: String
  },
  components: {
    orderInfo: OrderInfo,
    attendanceInfo: AttendanceInfo,
    loader: Loader
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.receipt
    }),
    ...applictionStore.mapState({
      application: (state) => state,
      applicationType: (state) => state.applicationType,
      person: (state) => state.person,
      order: (state) => state.order,
      paymentId: function (state) {
        return state.payment.checkout ? state.payment.checkout.paymentId : this.$route.query.paymentid
      },
      bookingTime: (state) => (state.timeBooking ? Moment(state.timeBooking.datetime) : undefined),
      center: (state) => state.center,
      attendanceRequired: (state) => state.order || state.settings.isAttendanceAtLostIdRequired,
      lostApplicationsWithoutRenewal: (state) => state.personApplications.filter((i) => !i.situation.newDocument),
      anyChildPassport: (state) => state.personApplications.some((i) => i.caseType === CaseTypes.ChildPassport),
      anyChildPassportOrderLines: (state) => state.order.items.some((i) => i.name === "Dansk B-pas"), // Remove once all municipalities are using embeddedpayment + if/else in pug file
      anyAdultPassport: (state) => state.personApplications.some((i) => i.caseType === CaseTypes.Passport),
      anyAdultPassportOrderLines: (state) => state.order.items.some((i) => i.name === "Dansk A-pas" || i.name === "Dansk C-pas"), // Remove once all municipalities are using embeddedpayment + if/else in pug file
      requiresMedicalCertificate: (state) =>
        state.personApplications.some((i) => i.situation.keepCommercialLicense || i.situation.hasLicenseForLargeVehicle),
      isLoading: (state) => state.navigation.isLoading,
      loadingMessage: (state) => state.navigation.loadingMessage
    })
  },
  methods: {
    ...applictionStore.mapActions({
      loadReceiptData: actionTypes.LOAD_RECEIPT_DATA
    }),
    ...oidcStore.mapActions({
      signOut: 'signOutOidc'
    })
  },
  mounted: function () {
    if (this.applicationId) {
      this.loadReceiptData(this.applicationId)
    }
  }
}
</script>
