const mutationTypes = {
  SET_LOADING: 'setLoading',
  RESET_LOADING: 'resetLoading',
  SET_CHILDREN: 'setChildren',
  SET_STATUS: 'setStatus',
  LOCK_SUBMITTED: 'lockSubmitted'
}

const mutations = {
  [mutationTypes.SET_LOADING]: (state, loadingMessage) => {
    state.loader.isLoading = true
    state.loader.message = loadingMessage
  },
  [mutationTypes.RESET_LOADING]: (state) => {
    state.loader.isLoading = false
    state.loader.message = undefined
  },
  [mutationTypes.SET_CHILDREN]: (state, children) => {
    state.children = children.map((c) => ({
      ...c,
      status: undefined,
      isLocked: false
    }))
  },
  [mutationTypes.SET_STATUS]: (state, { cprNumber, status }) => {
    state.children = state.children.map((i) =>
      i.cprNumber === cprNumber
        ? {
            ...i,
            status: status
          }
        : i
    )
  },
  [mutationTypes.LOCK_SUBMITTED]: (state) => {
    state.children = state.children.map((i) =>
      i.status !== undefined
        ? {
            ...i,
            isLocked: true
          }
        : i
    )
  }
}

export { mutationTypes }
export default mutations
