import drivingLicense from './drivingLicense'
import passportSituation from './passport'
import childassportSituation from './childPassport'
import { CaseTypes } from '..'

export default new Map([
  [CaseTypes.DrivingLicense, drivingLicense],
  [CaseTypes.Passport, passportSituation],
  [CaseTypes.ChildPassport, childassportSituation]
])
