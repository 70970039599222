import Vue from 'vue'
import App from './App'
import router from '@/router'
import * as validation from '@/validation'
import store from '@/store'
import Moment from 'moment'
import browserDetect from 'vue-browser-detect-plugin'
import VCalendar from 'v-calendar'
import VueQRCodeComponent from 'vue-qrcode-component'
import VueAxios from 'vue-axios'
import mainAxios from '@/mainAxios'
import RouteNames from './models/routes'
import VeeValidate from 'vee-validate'
import filters from './utils/filters'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

require('materialize-css/dist/js/materialize.min.js')

Vue.config.productionTip = false
Vue.use(require('vue-moment'))

const environments = ['Development', 'AzTest', 'Test', 'PreProd', 'Production']
const hostMatch = /^selvbetjening\.(\w+)\.id-port.dk/.exec(location.hostname)
const environment = hostMatch ? hostMatch[1] : 'production'

Sentry.init({
  Vue: Vue,
  attachStacktrace: true,
  environment: environments.find((i) => i.toLocaleLowerCase() == environment.toLocaleLowerCase()),
  dsn: 'https://1ee3f28ca2b740e7b0befe2229427b19@sentry.id-port.dk/14',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })
  ],
  tracesSampleRate: 1.0
})

const VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo, {
  container: '.site-wrapper',
  duration: 2000,
  easing: 'ease-in-out'
})

Vue.use(browserDetect)
Vue.use(VCalendar, {})
Vue.use(VeeValidate)

Vue.filter('amountFormat', filters.amountFormat)
Vue.filter('timeFormat', filters.timeFormat)
Vue.filter('dateFormat', filters.dateFormat)
Vue.filter('boolToYesNo', filters.boolToYesNo)
Vue.filter('hideCpr', filters.hideCpr)

Vue.component('QrCode', VueQRCodeComponent)

Vue.use(VueAxios, mainAxios)

new Vue({
  el: '#app',
  validation,
  router,
  store,
  components: { App },
  created: function () {
    Moment.locale('da')
  },
  mounted: function () {
    if (this.$browserDetect.isIE) {
      router.push({ name: RouteNames.UnsupportedBrowser })
    }
  },
  template: '<App />'
})
