var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align my-1",
        },
        [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.texts.questionHasCommercialLicense)),
          ]),
          _c("switch-button", {
            attrs: {
              injectedValue:
                _vm.personApplication.situation.hasCommercialLicense,
              onChange: _vm.setHasCommercialLicense,
            },
          }),
        ],
        1
      ),
    ]),
    _vm.personApplication.situation.hasCommercialLicense
      ? _c("div", { staticClass: "row bg-light my-1" }, [
          _c(
            "div",
            {
              staticClass:
                "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align mt-0 mb-2",
            },
            [
              _c("h2", { staticClass: "text-view-title" }, [
                _vm._v(_vm._s(_vm.texts.questionKeepCommercialLicense)),
              ]),
              _c("switch-button", {
                attrs: {
                  injectedValue:
                    _vm.personApplication.situation.keepCommercialLicense,
                  onChange: _vm.setKeepCommercialLicense,
                },
              }),
              _c("h2", { staticClass: "text-view-subtitle ma-0 mt-1" }, [
                _c("b", [_vm._v("Bemærk: ")]),
                _vm._v(_vm._s(_vm.texts.questionKeepCommercialLicenseNote)),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align my-1",
        },
        [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.texts.questionHasLicenseForLargeVehicle)),
          ]),
          _c("switch-button", {
            attrs: {
              injectedValue:
                _vm.personApplication.situation.hasLicenseForLargeVehicle,
              onChange: _vm.setHasLicenseForLargeVehicle,
            },
          }),
          _c("h2", { staticClass: "text-view-subtitle ma-0 mt-1" }, [
            _c("b", [_vm._v("Bemærk: ")]),
            _vm._v(_vm._s(_vm.texts.questionHasLicenseForLargeVehicleNote)),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }