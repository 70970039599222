import { ApplicationTypes, CaseTypes, DrivingLicenseSituation, PassportSituations, PhotoChoices } from '@/models'
import ageUtils from '@/utils/ageUtils'

const mutationTypes = {
  SET_TRANSITION: 'setTransistion',
  SET_LOADING: 'setLoading',
  RESET_LOADING: 'resetLoading',

  SET_AVAILABLE_APPLICATION_TYPES: 'setAvailableApplicationsTypes',
  SET_APPLICATION_USAGE_WARNINGS: 'setApplicationUsageWarnings',
  SET_APPLICATION_ID: 'setApplicationId',
  SET_APPLICATION_TYPE: 'setApplicationType',
  SET_APPLICATION_INFO: 'setApplicationInfo',

  ADD_PERSON_APPLICATION: 'addPersonApplication',
  REMOVE_PERSON_APPLICATION: 'removePersonApplication',
  SET_CURRENT_PERSON: 'setCurrentPersonId',
  SET_OTHER_GUARDIAN_CONSENT_TERMS_ACCEPTED: 'setOtherGuardianConsentTermsAccepted',
  SET_OTHER_GUARDIAN_EMAIL: 'setOtherGuardianEmail',

  SET_CENTER: 'setCenter',
  SET_SETTINGS: 'setSettings',

  CLEAR_EXISTING_CASES: 'clearExistingCases',

  SET_SITUATION_ID: 'setSituationId',
  SET_NEW_DOCUMENT: 'setNewDocument',
  SET_PREVIOUS_DOCUMENT_LOST: 'setPreviousDocumentLost',
  SET_LOST_REASON: 'setLostReason',

  SET_PREVIOUSLY_HAD_DANISH_PASSPORT: 'setPreviouslyHadDanishPassport',
  SET_IN_POSESSION_OF_OLD_PASSPORT: 'setInPossessionOfOldPassport',
  SET_HAS_BEEN_CITIZEN_OF_OTHER_COUNTRY: 'setHasBeenCitizenOfAnotherCountry',
  SET_HEIGHT: 'setHeight',

  SET_HAS_COMMERCIAL_LICENSE: 'setHasCommercialLicense',
  SET_KEEP_COMMERCIAL_LICENSE: 'setKeepCommercialLicense',
  SET_HAS_LICENSE_FOR_LARGE_VEHICLE: 'setHasLicenseForLargeVehicle',

  SET_EMAIL: 'setEmail',
  SET_PHONE_NUMBER: 'setPhoneNumber',

  SET_AVAILABLE_PHOTO_CHOICES: 'setAvailablePhotoChoice',
  SET_PHOTO_CHOICE: 'setPhotoChoice',
  SET_PHOTO: 'setPhoto',

  SET_TIME_BOOKING: 'setTimeBooking',
  SET_BOOK_TIME: 'setBookTime',
  SET_BOOKING_RESERVATION: 'setBookingReservation',
  CLEAR_TIME_BOOKING: 'clearTimeBooking',
  CLEAR_AVAILABLE_TIMES: 'clearAvailableTimes',
  SET_AVAILABLE_TIMES: 'setAvailableTimes',

  SET_ORDER: 'setOrder',
  SET_PAY_ONLINE: 'setPayOnline',
  SET_TERMS_ACCEPTED: 'setTermsAccepted',
  SET_PAYMENT_CHECKOUT: 'setPaymentCheckout',
  SET_RESTRICTED_PHOTO_CONFIRMED: 'setRestrictedPhotoConfirmed'
}

const mutations = {
  [mutationTypes.SET_TRANSITION]: (state, transition) => {
    state.navigation.transitionName = transition
  },
  [mutationTypes.SET_LOADING]: (state, loadingMessage) => {
    state.navigation.isLoading = true
    state.navigation.loadingMessage = loadingMessage
  },
  [mutationTypes.RESET_LOADING]: (state) => {
    state.navigation.isLoading = false
    state.navigation.loadingMessage = undefined
  },
  [mutationTypes.SET_AVAILABLE_APPLICATION_TYPES](state, availableApplicationsTypes) {
    state.availableApplicationsTypes = availableApplicationsTypes
  },
  [mutationTypes.SET_APPLICATION_USAGE_WARNINGS](state, applicationUsageWarnings) {
    state.applicationUsageWarnings = applicationUsageWarnings
  },
  [mutationTypes.SET_APPLICATION_ID]: (state, id) => {
    state.id = id
  },
  [mutationTypes.SET_APPLICATION_TYPE]: (state, applicationType) => {
    state.applicationType = applicationType
  },
  [mutationTypes.SET_APPLICATION_INFO]: (state, applicationInfo) => {
    state.id = applicationInfo.id
    ;(state.applicationType = applicationInfo.applicationType), (state.centers = applicationInfo.centers)
    ;(state.person = {
      ...applicationInfo.family.person,
      email: undefined,
      phoneNumber: undefined,
      height: undefined
    }),
      (state.children = applicationInfo.family.children.map((c) => ({
        ...c,
        email: undefined,
        phoneNumber: undefined,
        height: undefined,
        guardian2Info: c.guardian2Info ? { ...c.guardian2Info, email: undefined } : undefined
      })))
  },
  [mutationTypes.ADD_PERSON_APPLICATION]: (state, personId) => {
    state.currentPersonId = undefined

    const isCurrentPerson = state.person.id === personId
    const person = isCurrentPerson ? state.person : state.children.find((p) => p.id === personId)
    const caseType =
      state.applicationType === ApplicationTypes.DrivingLicense
        ? CaseTypes.DrivingLicense
        : isCurrentPerson
        ? CaseTypes.Passport
        : CaseTypes.ChildPassport

    const personsApplication = {
      id: personId,
      person: person,
      caseType: caseType,
      situation: {
        id: undefined,
        newDocument: undefined,
        previousDocumentLost: false,
        lostReason: ''
      },
      photoChoice: PhotoChoices.Unknown,
      availablePhotoChoices: [],
      photo: undefined,
      bookingReservation: undefined
    }

    switch (state.applicationType) {
      case ApplicationTypes.Passport:
        personsApplication.situation = {
          ...personsApplication.situation,
          hasBeenCitizenOfAnotherCountry: undefined,
          previouslyHadDanishPassport: undefined,
          inPossessionOfOldPassport: undefined,
          previousPassportDamaged: undefined,
          height: undefined
        }
        break
      case ApplicationTypes.DrivingLicense:
        personsApplication.situation = {
          ...personsApplication.situation,
          keepCommercialLicense: undefined,
          hasCommercialLicense: undefined,
          hasLicenseForLargeVehicle: undefined
        }
        break
    }

    if (person.guardian2Info !== undefined && !person.guardian2Info.hasFullCprNumber) {
      state.otherGuardianConsentTermsAccepted = false
    }

    personsApplication.availablePhotoChoices = []

    if (state.settings.photo.selfTakenPhotoAvailable) {
      personsApplication.availablePhotoChoices.push(PhotoChoices.SelfTakenPhoto)
    }

    const photoRestrictions = state.settings.photo.ageRestrictions
    const age = ageUtils.getAgeFromCpr(personsApplication.person.cprNumber)

    if (state.settings.photo.centerPhotoAvailable) {
      if (!photoRestrictions || !photoRestrictions.enforceMinimumAge || age > photoRestrictions.minimumAge) {
        personsApplication.availablePhotoChoices.push(PhotoChoices.CenterPhoto)
      }
    }

    personsApplication.availablePhotoChoices.push(PhotoChoices.BringOwnPhoto)

    if (personsApplication.availablePhotoChoices.length === 1) {
      personsApplication.photoChoice = personsApplication.availablePhotoChoices[0]
    }

    state.personApplications.push(personsApplication)
    state.personApplications = state.personApplications.sort(
      (a, b) => state.children.map((i) => i.id).indexOf(a.id) - state.children.map((i) => i.id).indexOf(b.id)
    )
  },
  [mutationTypes.REMOVE_PERSON_APPLICATION]: (state, personId) => {
    state.currentPersonId = undefined

    state.personApplications = state.personApplications.filter((i) => i.id !== personId)
  },
  [mutationTypes.SET_CURRENT_PERSON]: (state, id) => {
    state.currentPersonId = id
  },
  [mutationTypes.SET_OTHER_GUARDIAN_EMAIL]: (state, { id, email }) => {
    let personApplicaitons = state.personApplications.filter((i) => i.person.guardian2Info && i.person.guardian2Info.id === id)

    let persons = state.children.filter((i) => i.guardian2Info && i.guardian2Info.id === id)

    for (let personApplicaiton of personApplicaitons) {
      personApplicaiton.person.guardian2Info.email = email
    }

    for (let person of persons) {
      person.guardian2Info.email = email
    }
  },
  [mutationTypes.SET_OTHER_GUARDIAN_CONSENT_TERMS_ACCEPTED]: (state, otherGuardianConsentTermsAccepted) => {
    state.otherGuardianConsentTermsAccepted = otherGuardianConsentTermsAccepted
  },
  [mutationTypes.CLEAR_EXISTING_CASES]: (state) => {
    for (let personApplication of state.personApplications) {
      personApplication.person.existingCases = []

      if (state.person.id === personApplication.id) {
        state.person.existingCases = []
      } else if (state.children.some((i) => i.id === personApplication.id)) {
        state.children.find((i) => i.id === personApplication.id).existingCases = []
      }
    }
  },
  [mutationTypes.SET_CENTER]: (state, center) => {
    state.center = center
  },
  [mutationTypes.SET_SETTINGS]: (state, settings) => {
    state.settings = settings

    if (state.settings.timeBooking) {
      state.bookTime = true
    }
  },
  [mutationTypes.SET_SITUATION_ID]: (state, situationId) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.id = situationId

    if (state.applicationType === ApplicationTypes.Passport) {
      state.personApplications.find((i) => i.id === state.currentPersonId).situation.previousPassportDamaged =
        situationId === PassportSituations.Damaged ? true : undefined

      state.personApplications.find((i) => i.id === state.currentPersonId).situation.inPossessionOfOldPassport =
        situationId === PassportSituations.New || situationId === PassportSituations.Lost ? false : undefined

      if (situationId !== PassportSituations.Lost) {
        state.personApplications.find((i) => i.id === state.currentPersonId).situation.lostReason = ''
      }
    } else if (state.applicationType === ApplicationTypes.DrivingLicense) {
      if (situationId !== DrivingLicenseSituation.Lost) {
        state.personApplications.find((i) => i.id === state.currentPersonId).situation.lostReason = ''
      }

      if (
        situationId == DrivingLicenseSituation.TempRenewal ||
        situationId == DrivingLicenseSituation.International ||
        situationId == DrivingLicenseSituation.Lost
      ) {
        state.personApplications.find((i) => i.id === state.currentPersonId).situation.hasCommercialLicense = undefined
        state.personApplications.find((i) => i.id === state.currentPersonId).situation.keepCommercialLicense = undefined
        state.personApplications.find((i) => i.id === state.currentPersonId).situation.hasLicenseForLargeVehicle = undefined
      }
    }
  },
  [mutationTypes.SET_NEW_DOCUMENT]: (state, newDocument) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.newDocument = newDocument

    if (newDocument) {
      if (state.personApplications.find((i) => i.id === state.currentPersonId).availablePhotoChoices.length === 1) {
        state.personApplications.find((i) => i.id === state.currentPersonId).photoChoice ===
          state.personApplications.find((i) => i.id === state.currentPersonId).availablePhotoChoices[0]
      }
    } else {
      state.personApplications.find((i) => i.id === state.currentPersonId).photoChoice = PhotoChoices.Unknown
    }
  },
  [mutationTypes.SET_PREVIOUS_DOCUMENT_LOST]: (state, previousDocumentLost) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.previousDocumentLost = previousDocumentLost
  },
  [mutationTypes.SET_LOST_REASON]: (state, lostReason) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.lostReason = lostReason
  },
  [mutationTypes.SET_HEIGHT]: (state, height) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.height = height
  },
  [mutationTypes.SET_PREVIOUSLY_HAD_DANISH_PASSPORT]: (state, previouslyHadDanishPassport) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.previouslyHadDanishPassport = previouslyHadDanishPassport
  },
  [mutationTypes.SET_IN_POSESSION_OF_OLD_PASSPORT]: (state, inPossessionOfOldPassport) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.inPossessionOfOldPassport = inPossessionOfOldPassport
  },
  [mutationTypes.SET_HAS_BEEN_CITIZEN_OF_OTHER_COUNTRY]: (state, hasBeenCitizenOfAnotherCountry) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.hasBeenCitizenOfAnotherCountry = hasBeenCitizenOfAnotherCountry
  },
  [mutationTypes.SET_HAS_COMMERCIAL_LICENSE]: (state, hasCommercialLicense) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.hasCommercialLicense = hasCommercialLicense

    state.personApplications.find((i) => i.id === state.currentPersonId).situation.keepCommercialLicense = !hasCommercialLicense ? false : undefined
  },
  [mutationTypes.SET_KEEP_COMMERCIAL_LICENSE]: (state, keepCommercialLicense) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.keepCommercialLicense = keepCommercialLicense
  },
  [mutationTypes.SET_HAS_LICENSE_FOR_LARGE_VEHICLE]: (state, hasLicenseForLargeVehicle) => {
    state.personApplications.find((i) => i.id === state.currentPersonId).situation.hasLicenseForLargeVehicle = hasLicenseForLargeVehicle
  },
  [mutationTypes.SET_EMAIL]: (state, email) => {
    state.person.email = email

    for (let child of state.children) {
      child.email = email
    }
  },
  [mutationTypes.SET_PHONE_NUMBER]: (state, phoneNumber) => {
    state.person.phoneNumber = phoneNumber

    for (let child of state.children) {
      child.phoneNumber = phoneNumber
    }
  },
  [mutationTypes.SET_PHOTO_CHOICE]: (state, { id, photoChoice }) => {
    state.personApplications.find((i) => i.id === id).photoChoice = photoChoice
  },
  [mutationTypes.SET_PHOTO]: (state, { id, photo }) => {
    state.personApplications.find((i) => i.id === id).photo = photo
  },
  [mutationTypes.SET_AVAILABLE_TIMES]: (state, times) => {
    state.bookingTimes = times
  },
  [mutationTypes.SET_BOOK_TIME]: (state, bookTime) => {
    state.bookTime = bookTime
  },
  [mutationTypes.SET_TIME_BOOKING]: (state, timeBooking) => {
    state.timeBooking = timeBooking

    if (!timeBooking) {
      for (let personApplication of state.personApplications) {
        personApplication.bookingReservation = undefined
      }
    }
  },
  [mutationTypes.SET_BOOKING_RESERVATION]: (state, bookingToReservationCodes) => {
    let reservationTargetIndex = 0

    for (let personApplication of state.personApplications.filter((i) => i.situation.newDocument || state.settings.isAttendanceAtLostIdRequired)) {
      personApplication.bookingReservation = {
        id: Object.entries(bookingToReservationCodes)[reservationTargetIndex][0],
        reservationCode: Object.entries(bookingToReservationCodes)[reservationTargetIndex][1]
      }

      if (Object.keys(bookingToReservationCodes).length > 1) {
        reservationTargetIndex++
      }
    }
  },
  [mutationTypes.CLEAR_AVAILABLE_TIMES]: (state) => {
    state.bookingTimes = undefined
  },
  [mutationTypes.CLEAR_TIME_BOOKING]: (state) => {
    state.timeBooking = undefined

    for (let personApplication of state.personApplications) {
      personApplication.bookingReservation = undefined
    }
  },
  [mutationTypes.SET_ORDER]: (state, order) => {
    state.order = order
  },
  [mutationTypes.SET_PAY_ONLINE]: (state, payOnline) => {
    state.payment.payOnline = payOnline
  },
  [mutationTypes.SET_TERMS_ACCEPTED]: (state, termsAccepted) => {
    state.termsAccepted = termsAccepted
  },
  [mutationTypes.SET_PAYMENT_CHECKOUT]: (state, paymentCheckout) => {
    state.payment.checkout = paymentCheckout
  },
  [mutationTypes.SET_RESTRICTED_PHOTO_CONFIRMED]: (state) => {
    state.restrictedPhotoConfirmed = true
  }
}

export { mutationTypes }
export default mutations
