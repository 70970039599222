<template lang="pug">
include other-guardian-consent.pug
</template>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import EmailInput from '@/components/shared/emailInput'
import TermsCheckbox from '@/components/shared/termsCheckbox'
import { createNamespacedHelpers } from 'vuex'
import services from '@/services'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

import { getterTypes, mutationTypes } from '@/store/application'
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'OtherGuardianConsent',
  components: {
    navigationButtons: NavigationButtons,
    emailInput: EmailInput,
    termsCheckbox: TermsCheckbox
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.otherGuardianConsent
    }),
    ...applictionStore.mapGetters({
      completed: getterTypes.OTHER_GUARDIAN_CONSENT_COMPLETED
    }),
    ...applictionStore.mapState({
      termsAccepted: (state) => state.otherGuardianConsentTermsAccepted,
      guardians: (state) =>
        state.personApplications
          .filter((i) => i.person.guardian2Info)
          .map((i) => i.person)
          .reduce((result, child) => {
            var existing = result.find((i) => i.id === child.guardian2Info.id)

            if (existing) {
              existing.children.push(child.name)
            } else {
              result.push({
                ...child.guardian2Info,
                children: [child.name]
              })
            }

            return result
          }, [])
    })
  },
  data: function () {
    return {
      emails: {}
    }
  },
  methods: {
    ...applictionStore.mapMutations({
      setGuardianEmail: mutationTypes.SET_OTHER_GUARDIAN_EMAIL,
      setTermsAccepted: mutationTypes.SET_OTHER_GUARDIAN_CONSENT_TERMS_ACCEPTED
    }),
    emailcallback: function (id, email) {
      this.emails[id] = email.value
      this.setGuardianEmail({ id: id, email: email.validationAccepted ? email.value : undefined })
    },
    getGuardianEmail: function (id) {
      return this.emails.get(id)
    },
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    onNextStepClicked: function () {
      services.application.nextStep()
    }
  },
  beforeMount: function () {
    for (const guardian of this.guardians) {
      this.emails[guardian.id] = guardian.email
    }
  }
}
</script>
