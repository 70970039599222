const mutationTypes = {
  SET_TEXTS: 'setTexts',
  SET_GDPR_TEXT: 'setGdrpText',
  SET_GDPR_STATUS: 'setGdprStatus',
  SET_ERROR: 'setError',
  SET_MAINTENANCE: 'setMaintenance'
}

const mutations = {
  [mutationTypes.SET_TEXTS]: (state, texts) => {
    state.texts = texts
  },
  [mutationTypes.SET_GDPR_TEXT](state, text) {
    state.gdprText = text
  },
  [mutationTypes.SET_GDPR_STATUS](state, gdprStatus) {
    state.gdprStatus = gdprStatus
  },
  [mutationTypes.SET_ERROR](state, error) {
    const { message = undefined, retriable = true } = error || {}
    state.error = {
      retriable: retriable,
      message: message
    }
  },
  [mutationTypes.SET_MAINTENANCE](state, maintenance) {
    state.maintenance = maintenance
  }
}

export { mutationTypes }
export default mutations
