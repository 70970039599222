import store from '@/store'
import router from '@/router'
import { ApplicationTypes, PhotoFlowSteps, PhotoFlowTypes } from '@/models'
import utils from '@/utils'
import RouteNames from '@/models/routes'
import { actionTypes, getterTypes, mutationTypes } from '@/store/application'

export default {
  getFlow: function () {
    return [
      {
        key: 'START',
        isAvailable: (forward) => forward
      },
      {
        key: 'INIT',
        isAvailable: (forward) => forward,
        handle: async () => {
          await store.dispatch(`application/${actionTypes.INIT}`, router.currentRoute.params.applicationType)

          if (store.state.application.centers.length === 1) {
            await store.dispatch(`application/${actionTypes.SET_CENTER}`, store.state.application.centers[0])
          }
        }
      },
      {
        key: RouteNames.SelectCenter,
        route: RouteNames.SelectCenter,
        isAvailable: () => store.state.application.centers.length > 1
      },
      {
        key: 'INIT_SINGLE_PERSON_APPLICATION',
        isAvailable: (forward) =>
          forward &&
          store.getters[`application/${getterTypes.PERSONS_COUNT}`] === 0 &&
          (store.state.application.applicationType === ApplicationTypes.DrivingLicense || store.state.application.children.length === 0),
        handle: () => {
          store.commit(`application/${mutationTypes.ADD_PERSON_APPLICATION}`, store.state.application.person.id)
        }
      },
      {
        key: RouteNames.Personlist,
        route: RouteNames.Personlist,
        isAvailable: () => store.state.application.applicationType === ApplicationTypes.Passport && store.state.application.children.length !== 0
      },
      {
        key: RouteNames.ExistingCases,
        route: RouteNames.ExistingCases,
        isAvailable: () => store.getters[`application/${getterTypes.ANY_PERSON_APPLICATION_WITH_EXISTING_CASE}`]
      },
      {
        key: RouteNames.OtherGuardianConsent,
        route: RouteNames.OtherGuardianConsent,
        isAvailable: () => store.getters[`application/${getterTypes.REQUIRES_OTHER_GUARDIAN_CONSENT}`]
      },
      {
        key: 'SWITCH_PERSON_START_SUB_FLOW',
        isAvailable: () => true,
        handle: (forward) => {
          const personId = forward ? store.getters[`application/${getterTypes.NEXT_PERSON_ID}`] : undefined

          store.commit(`application/${mutationTypes.SET_CURRENT_PERSON}`, personId)
        }
      },
      {
        key: `${RouteNames.PersonProgress}_START_SUB_FLOW`,
        route: RouteNames.PersonProgress,
        isAvailable: () => store.getters[`application/${getterTypes.PERSONS_COUNT}`] > 1 && store.getters[`application/${getterTypes.CURRENT}`]
      },
      {
        key: RouteNames.Situation,
        route: RouteNames.Situation,
        isAvailable: () => store.getters[`application/${getterTypes.CURRENT}`]
      },
      {
        key: RouteNames.SituationDetails,
        route: RouteNames.SituationDetails,
        isAvailable: () =>
          store.getters[`application/${getterTypes.CURRENT}`] && store.getters[`application/${getterTypes.REQUIRES_DETAIL_SITUATION}`]
      },
      {
        key: 'SWITCH_PERSON_END_SUB_FLOW',
        isAvailable: () => true,
        handle: (forward) => {
          const personId = forward
            ? store.getters[`application/${getterTypes.NEXT_PERSON_ID}`]
            : store.getters[`application/${getterTypes.CURRENT}`]
            ? store.getters[`application/${getterTypes.PREVIOUS_PERSON_ID}`]
            : store.getters[`application/${getterTypes.PERSONS}`].slice(-1)[0].id

          store.commit(`application/${mutationTypes.SET_CURRENT_PERSON}`, personId)
        }
      },
      {
        key: `${RouteNames.PersonProgress}_END_SUB_FLOW`,
        route: RouteNames.PersonProgress,
        isAvailable: () => store.getters[`application/${getterTypes.PERSONS_COUNT}`] > 1 && store.getters[`application/${getterTypes.CURRENT}`]
      },
      {
        key: RouteNames.PhotoChoice,
        route: RouteNames.PhotoChoice,
        isAvailable: () =>
          store.getters[`application/${getterTypes.ARE_ALL_SITUATIONS_COMPLTED}`] &&
          store.getters[`application/${getterTypes.PHOTO_CHOICE_AVAILABLE}`]
      },
      {
        key: RouteNames.SelfTakenPhoto,
        route: RouteNames.SelfTakenPhoto,
        isAvailable: () => !utils.isMobile() && store.getters[`application/${getterTypes.SELFTAKEN_PHOTO_PENDING}`]
      },
      {
        key: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Guidelines}`,
        route: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Guidelines}`,
        isAvailable: () => utils.isMobile() && store.getters[`application/${getterTypes.SELFTAKEN_PHOTO_PENDING}`]
      },
      {
        key: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Take}`,
        route: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Take}`,
        isAvailable: () => false
      },
      {
        key: RouteNames.Booking,
        route: RouteNames.Booking,
        isAvailable: () =>
          store.getters[`application/${getterTypes.ARE_ALL_SITUATIONS_COMPLTED}`] &&
          store.getters[`application/${getterTypes.TIMEBOOKING_AVAILABLE}`],
        handle: async () => await store.dispatch(`application/${actionTypes.LOAD_AVAILABLE_TIMES}`)
      },
      {
        key: RouteNames.UnsuccessfulReservation,
        route: RouteNames.UnsuccessfulReservation,
        isAvailable: () => false
      },
      {
        key: RouteNames.Summary,
        route: RouteNames.Summary,
        handle: async () => {
          if (store.getters[`application/${getterTypes.ANY_NEW_DOCUMENT}`]) {
            await store.dispatch(`application/${actionTypes.SUBMIT}`)
          }
        },
        isAvailable: () => store.getters[`application/${getterTypes.ARE_ALL_SITUATIONS_COMPLTED}`]
      },
      {
        key: 'FinalSubmit',
        isAvailable: () => true,
        handle: async () => {
          await store.dispatch(`application/${actionTypes.SUBMIT}`, true)
        }
      },
      {
        key: RouteNames.Payment,
        route: RouteNames.Payment,
        handle: async () => {
          if (store.state.application.payment.checkout.type === 'HostedPaymentPage') {
            window.location.href = store.state.application.payment.checkout.url
          }
        },
        isAvailable: () => store.state.application.payment.checkout
      },
      {
        key: RouteNames.Receipt,
        route: RouteNames.Receipt,
        isAvailable: () => true
      }
    ]
  },
  start: async function () {
    await this.switchStepByKey('START', true)
  },
  nextStepAvailable: function () {
    const stepKey = this.findCurrentStepKey(true)
    return this.findStepByCurrentKey(stepKey, true, true)
  },
  previousStepAvailable: function () {
    const stepKey = this.findCurrentStepKey(false)
    return this.findStepByCurrentKey(stepKey, false, true) !== undefined
  },
  nextStep: async function () {
    store.commit(`application/${mutationTypes.SET_TRANSITION}`, 'forward')
    const stepKey = this.findCurrentStepKey(true)
    await this.switchStepByKey(stepKey, true)
  },
  previousStep: async function () {
    store.commit(`application/${mutationTypes.SET_TRANSITION}`, 'back')
    const stepKey = this.findCurrentStepKey(false)
    await this.switchStepByKey(stepKey, false)
  },
  findCurrentStepKey: function (forward) {
    let flow = this.getFlow()

    if (!forward) {
      flow = flow.slice().reverse()
    }

    return flow.find((i) => i.route === router.currentRoute.name).key
  },
  switchStepByKey: async function (key, forward) {
    const step = this.findStepByCurrentKey(key, forward, false)

    if (step.handle) {
      await step.handle(forward)
    }

    if (step.route) {
      await router.push({ name: step.route })
    } else {
      await this.switchStepByKey(step.key, forward)
    }
  },
  findStepByCurrentKey: function (key, forward, withRoute) {
    const flow = this.getFlow()
    const currentIndex = flow.findIndex((i) => i.key === key)

    return forward
      ? flow.slice(currentIndex + 1).find((i) => i.isAvailable(true) && router.key !== i.key && (!withRoute || i.route))
      : flow
          .slice(0, currentIndex)
          .reverse()
          .find((i) => i.isAvailable(false) && key !== i.key && (!withRoute || i.route))
  }
}
