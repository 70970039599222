<template lang="pug">
include index.pug
</template>
<style lang="scss" scoped>
@import 'index';
</style>

<script>
import { FocusLoop } from '@vue-a11y/focus-loop'
import { createNamespacedHelpers } from 'vuex'

import { getterTypes, mutationTypes } from '@/store/application'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'PhotosReceived',
  components: {
    FocusLoop
  },
  computed: {
    ...applictionStore.mapState({
      personApplications: (state) => state.personApplications.filter((i) => i.situation.newDocument && i.availablePhotoChoices.length > 1)
    }),
    ...applictionStore.mapGetters({
      photoChoicesMeta: getterTypes.AVAILABLE_PHOTO_CHOICES_META
    }),
    personPhotoChoices: function () {
      var photoChoicesMeta = this.photoChoicesMeta
      return this.personApplications
        .filter((i) => i.situation.newDocument && i.availablePhotoChoices.length > 1)
        .map((i) => ({
          person: i.person,
          photoChoice: i.photoChoice,
          photo: i.photo,
          photoChoiceTitle: photoChoicesMeta.find((k) => k.key === i.photoChoice).title,
          choices: i.availablePhotoChoices.map((c) => ({
            ...photoChoicesMeta.find((m) => m.key === c)
          }))
        }))
    },
    editPersonPhotoChoices: function () {
      return this.editPersonId ? this.personPhotoChoices.find((i) => i.person.id === this.editPersonId) : undefined
    },
    ...mapGlobalState({
      texts: (state) => state.texts.selfTakenPhotoReceived
    })
  },
  methods: {
    ...applictionStore.mapMutations({
      setPhotoChoice: mutationTypes.SET_PHOTO_CHOICE
    }),
    onChangePhotoChoiceSelected: function (photoChoice) {
      this.setPhotoChoice({ id: this.editPersonId, photoChoice: photoChoice })
      this.editPersonId = undefined
      this.openEditWindow = false
    },
    onOpenEditWindow: function (personId) {
      this.editPersonId = personId
      this.openEditWindow = true
    }
  },
  data: function () {
    return {
      editPersonId: null,
      openEditWindow: false
    }
  }
}
</script>
