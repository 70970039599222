var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align mt-1" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align mb-1" }, [
          _c("h2", { staticClass: "text-view-subtitle mt-0" }, [
            _vm._v(_vm._s(_vm.texts.subtitle)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row before-bottom-nav" }, [
        _vm.centers !== ""
          ? _c("div", { staticClass: "col s10 offset-s1" }, [
              _c(
                "ol",
                {
                  staticClass: "scandi-list",
                  class: [
                    [1, 3].includes(_vm.centers.length)
                      ? "scandi-list-uneven"
                      : "",
                  ],
                  attrs: {
                    role: "radiogroup",
                    "aria-label": _vm.texts.title + " " + _vm.texts.subtitle,
                  },
                },
                _vm._l(_vm.centers, function (center) {
                  return _c(
                    "li",
                    {
                      staticClass: "scandi-list-item",
                      class: [
                        center.centerCode === _vm.selectedCenterCode
                          ? "selected"
                          : "",
                        [1, 3].includes(_vm.centers.length)
                          ? "scandi-list-uneven-item"
                          : "",
                      ],
                      attrs: {
                        tabindex: "0",
                        "aria-label":
                          center.name +
                          ", " +
                          center.address.addressLine +
                          ", " +
                          center.address.postalCode +
                          ", " +
                          center.address.city,
                        role: "radio",
                        "aria-checked":
                          center.centerCode === _vm.selectedCenterCode,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCenterSelected(center.centerCode)
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onCenterSelected(center.centerCode)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "scandi-list-title" }, [
                        _vm._v(_vm._s(center.name) + ","),
                      ]),
                      _c("span", { staticClass: "scandi-list-text" }, [
                        _vm._v(
                          _vm._s(center.address.addressLine) +
                            ", " +
                            _vm._s(center.address.postalCode) +
                            " " +
                            _vm._s(center.address.city)
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: {
          showPrevious: false,
          nextEnabled: _vm.nextStepAllowed,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }