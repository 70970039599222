<template lang="pug">
include situation-details.pug
</template>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import SituationDetailsPassport from './passport/situation-details'
import SituationDetailsDrivingLicense from './drivingLicense/situation-details'
import { createNamespacedHelpers } from 'vuex'
import services from '@/services'

import { getterTypes } from '@/store/application'
const applicatonStore = createNamespacedHelpers('application')

export default {
  name: 'SituationDetails',
  components: {
    navigationButtons: NavigationButtons,
    situationDetailsPassport: SituationDetailsPassport,
    situationDetailsDrivingLicense: SituationDetailsDrivingLicense
  },
  computed: {
    ...applicatonStore.mapState({
      applicationType: (state) => state.applicationType
    }),
    ...applicatonStore.mapGetters({
      personApplication: getterTypes.CURRENT,
      situationCompleted: getterTypes.SITUATION_COMPLETED
    }),
    nextStepAllowed: function () {
      return this.personApplication && this.situationCompleted(this.personApplication.id)
    }
  },
  methods: {
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    onNextStepClicked: function () {
      services.application.nextStep()
    }
  }
}
</script>
