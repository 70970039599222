<template lang="pug">
include photo-guidelines.pug
</template>
<style lang="scss" scoped>
@import 'photo-guidelines';
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import { actionTypes } from '@/store/photo'
import router from '@/router'
import NavigationButtons from '@/components/shared/navigationButtons'
import { PhotoFlowSteps, PhotoFlowTypes } from '@/models'
import VuePerfectScrollbar from 'vue-perfect-scrollbar/index.vue'
import RouteNames from '@/models/routes'

const photoStore = createNamespacedHelpers('photo')

export default {
  name: 'PhotoGuidelines',
  props: {
    photoFlowType: {
      type: String
    },
    photoId: {
      type: String
    }
  },
  components: {
    VuePerfectScrollbar,
    navigationButtons: NavigationButtons
  },
  methods: {
    ...photoStore.mapActions({
      getInfo: actionTypes.LOAD_INFO,
      getById: actionTypes.LOAD_BY_ID
    }),
    onNextStepClicked: async function () {
      if (this.photoId) {
        await this.getById(this.photoId)
      } else {
        await this.getInfo()
      }
      router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Take}` })
    },
    onPreviousStepClicked: function () {
      router.push({ name: RouteNames.PhotoChoice })
    }
  },
  computed: {
    isPhotoApplicationFlow: function () {
      return this.photoFlowType === PhotoFlowTypes.PhotoApplicationFlow
    },
    isPhotoDeviceFlow: function () {
      return this.photoFlowType === PhotoFlowTypes.PhotoDeviceFlow
    }
  }
}
</script>
