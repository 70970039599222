let Moment = require('moment')

export default {
  amountFormat: function (value) {
    return Number(value).toFixed(2)
  },
  dateFormat: function (selectedDay) {
    return new Moment(selectedDay).format(window.innerWidth < 450 ? 'DD-MM-YY' : 'D. MMMM YYYY')
  },
  timeFormat: function (time) {
    return new Moment(time).format('HH:mm')
  },
  hideCpr: function (value) {
    return value.substring(0, 6) + 'XXXX'
  },
  boolToYesNo: function (value) {
    return value ? 'Ja' : 'Nej'
  }
}
