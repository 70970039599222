var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-nav",
      class: { multiple: _vm.showPrevious && _vm.showNext },
    },
    [
      _vm.showPrevious
        ? _c(
            "div",
            {
              staticClass: "bottom-nav-btn",
              class: { disabled: !_vm.previousEnabled },
              attrs: { tabindex: "0", role: "button", "aria-label": "Tilbage" },
              on: {
                click: _vm.onPreviousButtonClicked,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onPreviousButtonClicked.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "bottom-nav-btn-icon",
                attrs: { src: "/static/previous.svg", alt: "tilbage pil" },
              }),
              _c("span", { staticClass: "bottom-nav-btn-text center-align" }, [
                _vm._v(_vm._s(_vm.previousLabel)),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showNext
        ? _c(
            "div",
            {
              staticClass: "bottom-nav-btn",
              class: { disabled: !_vm.nextEnabled },
              attrs: { tabindex: "0", role: "button", "aria-label": "Videre" },
              on: {
                click: _vm.onNextButtonClicked,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onNextButtonClicked.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "bottom-nav-btn-icon",
                attrs: { src: "/static/next.svg", alt: "videre pil" },
              }),
              _c("span", { staticClass: "bottom-nav-btn-text center-align" }, [
                _vm._v(_vm._s(_vm.nextLabel)),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }