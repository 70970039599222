<template lang="pug">
include not-found.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'NotFound',
  computed: {
    ...oidcStore.mapGetters({
      isAuthenticated: 'oidcIsAuthenticated'
    })
  },
  methods: {
    ...oidcStore.mapActions({
      signOut: 'signOutOidc'
    })
  }
}
</script>
