import { DrivingLicenseSituation } from '..'

const situations = [
  {
    title: 'Mit kørekort er udløbet',
    text: 'eller udløber inden for 3 måneder',
    id: DrivingLicenseSituation.Renewal
  },
  {
    title: 'Mit kørekort er bortkommet',
    text: 'f.eks tabt, stjålet, eller blevet væk',
    id: DrivingLicenseSituation.Lost
  },
  {
    title: 'Jeg skal til udlandet',
    text: 'og jeg skal bruge et internationalt kørekort',
    id: DrivingLicenseSituation.International
  },
  {
    title: 'mit kørekort er slidt/beskadiget',
    text: 'og jeg skal bruge et nyt kørekort',
    id: DrivingLicenseSituation.Damaged
  },
  {
    title: 'Mit midlertidige kørekort er udløbet',
    text: 'og jeg skal bruge et nyt',
    id: DrivingLicenseSituation.TempRenewal
  },
  {
    title: 'Jeg har skiftet navn',
    text: 'og jeg skal bruge et nyt kørekort',
    id: DrivingLicenseSituation.NameChange
  },
  {
    title: 'Mit kørekort skal ombyttes',
    text: 'til et kreditkorttype-kørekort',
    id: DrivingLicenseSituation.Old
  }
]

export default situations
