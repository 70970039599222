var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align mb-1" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
          _c("h5", { staticClass: "text-view-subtitle" }, [
            _vm._v(_vm._s(_vm.texts.subtitle)),
          ]),
        ]),
      ]),
      _vm.attendanceRequired
        ? [
            _c("div", { staticClass: "row my-1" }, [
              _c(
                "div",
                { staticClass: "col s12 l8 offset-l2 xl6 offset-xl3" },
                [
                  _c("attendance-info", {
                    attrs: {
                      center: _vm.center,
                      bookingTime: _vm.bookingTime,
                      anyLostDocument:
                        _vm.lostApplicationsWithoutRenewal.length > 0,
                      anyChildPassport: _vm.anyChildPassport,
                      requiresMedicalCertificate:
                        _vm.requiresMedicalCertificate,
                      applicationType: _vm.applicationType,
                      anyAdultPassport: _vm.anyAdultPassport,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.anyNewDocument
              ? [
                  _c("div", { staticClass: "row bg-light py-2 px-2 mt-1" }, [
                    _c(
                      "div",
                      { staticClass: "col s12 l8 offset-l2 xl6 offset-xl3" },
                      [
                        _c("order-info", { attrs: { order: _vm.order } }),
                        _c("terms-checkbox", {
                          staticClass: "mt-2",
                          attrs: {
                            checkBoxText:
                              "Jeg bekræfter at jeg har læst og accepterer",
                            linkText: " handels- og salgsbetingelserne",
                            injectedValue: _vm.termsAccepted,
                          },
                          on: {
                            callback: function ($event) {
                              return _vm.onAcceptTermsClicked()
                            },
                            linkClicked: function ($event) {
                              _vm.showTradeConditions = !_vm.showTradeConditions
                            },
                          },
                        }),
                        _vm.showTradeConditions
                          ? _c("trade-conditions", { staticClass: "my-2" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.paymentChoiceAvailable
                    ? _c("div", { staticClass: "row mx-2 my-1" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col s12 l8 offset-l2 xl6 offset-xl3 center-align",
                          },
                          [
                            _c(
                              "h2",
                              { staticClass: "text-view-subtitle mb-1" },
                              [_vm._v(_vm._s(_vm.texts.questionPayNow))]
                            ),
                            _c("span", { staticClass: "text-value mb-1" }, [
                              _vm._v(_vm._s(_vm.texts.questionPayNowNote)),
                            ]),
                            _c("switch-button", {
                              attrs: {
                                injectedValue: _vm.payOnline,
                                onChange: _vm.setPayOnline,
                                yesLabel: "Nu",
                                noLabel: "Ved Fremmøde",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        : [
            _c("div", { staticClass: "row my-1" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col s10 l8 offset-l2 xl6 offset-xl3 center-align",
                },
                [
                  _vm.lostApplicationsWithoutRenewal.length === 1 &&
                  _vm.lostApplicationsWithoutRenewal[0].id === _vm.person.id
                    ? [
                        _vm.applicationType === "passport"
                          ? [
                              _c("span", { staticClass: "text-info" }, [
                                _vm._v(
                                  _vm._s(_vm.texts.noAttandanseAtLostPassport)
                                ),
                              ]),
                            ]
                          : [
                              _c("span", { staticClass: "text-info" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.texts.noAttandanseAtLostDrivingLicense
                                  )
                                ),
                              ]),
                            ],
                      ]
                    : [
                        _c("span", { staticClass: "text-info" }, [
                          _vm._v(
                            _vm._s(_vm.texts.noAttandanseAtLostGroupOrder) +
                              ": "
                          ),
                        ]),
                        _vm._l(
                          _vm.lostApplicationsWithoutRenewal,
                          function (application) {
                            return [
                              _c("span", { staticClass: "text-info" }, [
                                _vm._v(_vm._s(application.person.name)),
                              ]),
                            ]
                          }
                        ),
                      ],
                ],
                2
              ),
            ]),
          ],
      _vm.requiresEmail
        ? [
            _c("div", { staticClass: "row bg-light my-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align mb-2",
                },
                [
                  _c("h2", { staticClass: "text-view-title" }, [
                    _vm._v(_vm._s(_vm.texts.emailTitle)),
                  ]),
                  _c("h2", { staticClass: "text-view-subtitle mt-1" }, [
                    _vm._v(_vm._s(_vm.texts.emailSubtitle)),
                  ]),
                  _c("email-input", {
                    attrs: {
                      callback: _vm.emailcallback,
                      injectedValue: _vm.email,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm.requiresPhoneNumber && !_vm.timeBookingAvailable
        ? [
            _c(
              "div",
              {
                staticClass: "row",
                class: { "bg-light my-2": !_vm.requiresEmail },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 mt-1 center-align mb-2",
                  },
                  [
                    _c("h2", { staticClass: "mt-0 mb-1 text-view-title" }, [
                      _vm._v(_vm._s(_vm.texts.phoneNumberHeader)),
                    ]),
                    _c("input-field", {
                      attrs: {
                        validationRules: "required|phoneNumber",
                        inputTextField: "Telefonnummer",
                        inputType: "number",
                        callback: _vm.phonenumbercallback,
                        injectedValue: _vm.phoneNumber,
                        ariaLabel: _vm.texts.phoneNumberHeader,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
      _c("div", { staticClass: "before-bottom-nav" }),
      _c("navigation-buttons", {
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }