<template lang="pug">
include index.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'AwaitingPhotos',
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.selfTakenPhotoPending
    })
  }
}
</script>
