<template lang="pug">
include situation.pug
</template>

<style lang="scss" scoped>
@import 'situation';
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const applictionStore = createNamespacedHelpers('application')
import { PassportSituations, DrivingLicenseSituation, ApplicationTypes } from '@/models'
import SituationDefault from './default/situation-default'
import SituationLost from './lost/situation-lost'
import SituationStolen from './stolen/situation-stolen'
import { getterTypes } from '@/store/application'

export default {
  name: 'PasSituation',
  components: {
    SituationDefault,
    SituationLost,
    SituationStolen
  },
  computed: {
    ...applictionStore.mapState({
      applicationType: (state) => state.applicationType
    }),
    ...applictionStore.mapGetters({
      personApplication: getterTypes.CURRENT
    }),
    drivingLicenseLost: function () {
      return (
        this.personApplication &&
        this.personApplication.situation.previousDocumentLost &&
        this.applicationType === ApplicationTypes.DrivingLicense &&
        this.personApplication.situation.id === DrivingLicenseSituation.Lost
      )
    },
    passportLost: function () {
      return (
        this.personApplication &&
        this.personApplication.situation.previousDocumentLost &&
        this.applicationType === ApplicationTypes.Passport &&
        this.personApplication.situation.id === PassportSituations.Lost
      )
    },
    lost: function () {
      return this.drivingLicenseLost || this.passportLost
    },
    passportStolen: function () {
      return (
        this.personApplication &&
        this.personApplication.situation.previousDocumentLost &&
        this.personApplication.situation.id === PassportSituations.Stolen
      )
    },
    stolen: function () {
      return this.passportStolen
    }
  },
  data() {
    return {
      transitionName: 'forward'
    }
  }
}
</script>
