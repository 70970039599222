var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _vm._t("default", function () {
                return [
                  _vm._v(
                    "Borgerservice tager ikke fotos af børn under " +
                      _vm._s(_vm.minimumPhotoAge) +
                      " år, og du skal derfor selv medbringe et foto."
                  ),
                ]
              }),
            ],
            2
          ),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              {
                staticClass: "scandi-btn scandi-button-wrapper",
                attrs: { role: "button", "aria-label": "Ok", tabIndex: "0" },
                on: {
                  click: _vm.onAccept,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onAccept.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Ok")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }