var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c("error", { attrs: { error: _vm.error } })
    : _vm.lowLoa
    ? _c("forbidden", {
        attrs: {
          message:
            "Du er logget ind med for lavt sikkerhedsniveau fra et andet site.",
          minorMessage:
            'Klik på "Log af" for at logge ind direkte på dette site',
        },
      })
    : _vm.employyeLogin
    ? _c("forbidden", {
        attrs: {
          message:
            "Det anvendte MitID er ikke et privat MitID og kan derfor ikke anvendes i denne løsning.",
          minorMessage:
            'Klik på "Log af" for at logge ind med dit private MitID',
        },
      })
    : _vm.childLogin
    ? _c("forbidden", {
        attrs: {
          message:
            "For selv at søge om pas skal du være fyldt 18 år. Er du under 18 år skal dine forældre udfylde ansøgningen.",
          minorMessage: 'Klik på "Log af" og lad en af dine forældre logge ind',
        },
      })
    : _vm.requiresMobile
    ? _c("open-on-mobile")
    : _vm.requiredGdprAcceptance
    ? _c("gdpr-accept")
    : _c("router-view")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }