<template lang="pug">
include summary.pug
</template>

<script>
import Moment from 'moment'
import EmailInput from '@/components/shared/emailInput'
import Notification from '@/components/shared/notification'
import SwitchButton from '@/components/shared/switchButton'
import NavigationButtons from '@/components/shared/navigationButtons'
import TermsCheckbox from '@/components/shared/termsCheckbox'
import TextInput from '@/components/shared/textInput'
import OrderInfo from '@/components/application/shared/orderInfo'
import AttendanceInfo from '@/components/application/shared/attendanceInfo'
import TradeConditions from '@/components/application/summary/tradeConditions'

import { createNamespacedHelpers } from 'vuex'
import services from '@/services'

import { getterTypes, mutationTypes, actionTypes } from '@/store/application'
import { CaseTypes } from '@/models'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'Summary',
  data() {
    return {
      showTradeConditions: false,
      email: '',
      phoneNumber: ''
    }
  },
  components: {
    notification: Notification,
    navigationButtons: NavigationButtons,
    switchButton: SwitchButton,
    emailInput: EmailInput,
    termsCheckbox: TermsCheckbox,
    orderInfo: OrderInfo,
    attendanceInfo: AttendanceInfo,
    inputField: TextInput,
    tradeConditions: TradeConditions
  },
  computed: {
    ...applictionStore.mapState({
      application: (state) => state,
      applicationType: (state) => state.applicationType,
      person: (state) => state.person,
      order: (state) => state.order,
      bookingTime: (state) => (state.timeBooking ? Moment(state.timeBooking.datetime) : undefined),
      center: (state) => state.center,
      attendanceRequired: (state) => state.personApplications.some((i) => i.situation.newDocument) || state.settings.isAttendanceAtLostIdRequired,
      lostApplicationsWithoutRenewal: (state) => state.personApplications.filter((i) => !i.situation.newDocument),
      anyChildPassport: (state) => state.personApplications.some((i) => i.caseType === CaseTypes.ChildPassport),
      anyAdultPassport: (state) => state.personApplications.some((i) => i.caseType === CaseTypes.Passport),
      requiresMedicalCertificate: (state) =>
        state.personApplications.some((i) => i.situation.newDocument && (i.situation.keepCommercialLicense || i.situation.hasLicenseForLargeVehicle)),
      payOnline: (state) => {
        return state.payment.payOnline
      },
      termsAccepted: (state) => state.termsAccepted
    }),
    ...applictionStore.mapGetters({
      anyNewDocument: getterTypes.ANY_NEW_DOCUMENT,
      paymentChoiceAvailable: getterTypes.PAYMENT_CHOICE_AVAILABLE,
      requiresEmail: getterTypes.REQUIRES_PERSON_EMAIL,
      timeBookingAvailable: getterTypes.TIMEBOOKING_AVAILABLE,
      requiresPhoneNumber: getterTypes.REQUIRES_PERSON_PHONE_NUMBER
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.summary
    }),
    nextStepAllowed: function () {
      return (
        (!this.anyNewDocument || (this.payOnline !== undefined && this.termsAccepted)) &&
         (!this.requiresEmail || this.person.email !== undefined) &&
         (this.timeBookingAvailable || !this.requiresPhoneNumber || this.person.phoneNumber !== undefined)
      )
    }
  },
  methods: {
    ...applictionStore.mapMutations({
      setTermsAccepted: mutationTypes.SET_TERMS_ACCEPTED,
      setPayOnline: mutationTypes.SET_PAY_ONLINE,
      setEmail: mutationTypes.SET_EMAIL,
      setPhoneNumber: mutationTypes.SET_PHONE_NUMBER
    }),
    emailcallback: function (email) {
      this.email = email.value
      this.setEmail(email.validationAccepted ? email.value : undefined)
    },
    phonenumbercallback: function (phoneNumber) {
      this.phoneNumber = phoneNumber.value
      this.setPhoneNumber(phoneNumber.validationAccepted ? phoneNumber.value : undefined)
    },
    onAcceptTermsClicked: function () {
      if (!this.loading) {
        this.setTermsAccepted(!this.termsAccepted)
      }
    },
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    onNextStepClicked: function () {
      services.application.nextStep()
    }
  },
  mounted: function () {
    this.email = this.person.email
    this.phoneNumber = this.person.phoneNumber
  }
}
</script>
