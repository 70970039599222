var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.isLoading ? "view-content-centered" : ""] },
    [
      _vm.isLoading
        ? _c("loader", { attrs: { message: _vm.loadingMessage } })
        : _c(
            "div",
            { staticClass: "content bottom-nav-container" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col s10 offset-s1 center-align mb-1" },
                  [
                    _c("h2", { staticClass: "text-view-title" }, [
                      _vm._v(_vm._s(_vm.texts.title)),
                    ]),
                  ]
                ),
              ]),
              _vm.attendanceRequired
                ? [
                    _c("div", { staticClass: "row my-1" }, [
                      _c(
                        "div",
                        { staticClass: "col s12 l8 offset-l2 xl6 offset-xl3" },
                        [
                          _vm.application.personApplications.length
                            ? [
                                _c("attendance-info", {
                                  attrs: {
                                    center: _vm.center,
                                    bookingTime: _vm.bookingTime,
                                    anyLostDocument:
                                      _vm.lostApplicationsWithoutRenewal
                                        .length > 0,
                                    anyChildPassport: _vm.anyChildPassport,
                                    requiresMedicalCertificate:
                                      _vm.requiresMedicalCertificate,
                                    applicationType: _vm.applicationType,
                                    anyAdultPassport: _vm.anyAdultPassport,
                                  },
                                }),
                              ]
                            : [
                                _c("attendance-info", {
                                  attrs: {
                                    center: _vm.center,
                                    bookingTime: _vm.bookingTime,
                                    anyLostDocument:
                                      _vm.lostApplicationsWithoutRenewal
                                        .length > 0,
                                    anyChildPassport:
                                      _vm.anyChildPassportOrderLines,
                                    requiresMedicalCertificate:
                                      _vm.requiresMedicalCertificate,
                                    applicationType: _vm.applicationType,
                                    anyAdultPassport:
                                      _vm.anyAdultPassportOrderLines,
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "row section pt-2 pb-1 mx-1" }, [
                      _c("div", { staticClass: "col s12 center-align" }, [
                        _c("span", { staticClass: "text-value" }, [
                          _vm._v("Tak for din registrering."),
                        ]),
                      ]),
                    ]),
                  ],
              _vm.order
                ? [
                    _c("div", { staticClass: "row bg-light py-2 px-2 mt-1" }, [
                      _c(
                        "div",
                        { staticClass: "col s12 l8 offset-l2 xl6 offset-xl3" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col s4 l3" }, [
                              _c("span", { staticClass: "text-info normal" }, [
                                _vm._v("Ordrenr:"),
                              ]),
                            ]),
                            _c("div", { staticClass: "col s8 l9" }, [
                              _c("span", { staticClass: "text-value" }, [
                                _vm._v(_vm._s(_vm.order.id)),
                              ]),
                            ]),
                          ]),
                          _vm.paymentId
                            ? [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col s4 l3" }, [
                                    _c(
                                      "span",
                                      { staticClass: "text-info normal" },
                                      [_vm._v("Status:")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col s8 l9" }, [
                                    _c("span", { staticClass: "text-value" }, [
                                      _vm._v("Betaling reserveret"),
                                    ]),
                                  ]),
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col s4 l3" }, [
                                    _c(
                                      "span",
                                      { staticClass: "text-info normal" },
                                      [_vm._v("Transaktions-id:")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col s8 l9" }, [
                                    _c("span", { staticClass: "text-value" }, [
                                      _vm._v(_vm._s(_vm.paymentId)),
                                    ]),
                                  ]),
                                ]),
                              ]
                            : [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col s4 l3" }, [
                                    _c(
                                      "span",
                                      { staticClass: "text-info normal" },
                                      [_vm._v("Status:")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col s8 l9" }, [
                                    _c("span", { staticClass: "text-value" }, [
                                      _vm._v("Betales ved fremmøde"),
                                    ]),
                                  ]),
                                ]),
                              ],
                        ],
                        2
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col s12 l8 offset-l2 xl6 offset-xl3 px-2",
                          },
                          [_c("hr")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col s12 l8 offset-l2 xl6 offset-xl3" },
                        [
                          _c("order-info", {
                            staticClass: "mt-1",
                            attrs: { order: _vm.order },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "row my-1" }, [
                _c("div", { staticClass: "col s12 center-align" }, [
                  _c("h2", { staticClass: "text-view-subtitle" }, [
                    _vm._v("Log af for at afslutte din MitID session"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row before-bottom-nav" }, [
                _c(
                  "div",
                  { staticClass: "col s10 offset-s1 l6 offset-l3 mb-2 px-3" },
                  [
                    _c("div", { staticClass: "col s8 offset-s2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "scandi-btn",
                          attrs: {
                            role: "button",
                            "aria-label": "Log af",
                            tabindex: "0",
                          },
                          on: {
                            click: _vm.signOut,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.signOut.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Log af")]
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }