var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-fade" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _vm._t("default", function () {
                return [
                  _vm._v(
                    "Er du sikker på du ønsker at fortsætte? Hvis du fortsætter bliver dine ansøgninger og bookinger annulleret. "
                  ),
                ]
              }),
              _c("p", { staticClass: "bold" }, [
                _vm._v("Dit valg kan ikke fortrydes!"),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              {
                staticClass: "scandi-btn scandi-button-wrapper",
                attrs: {
                  role: "button",
                  "aria-label": "Slet og fortsæt",
                  tabIndex: "0",
                },
                on: {
                  click: _vm.onAccept,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onAccept.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Slet mine ansøgninger")]
            ),
            _c(
              "div",
              {
                staticClass: "scandi-btn scandi-button-wrapper",
                attrs: {
                  role: "button",
                  "aria-label": "Afbryd",
                  tabIndex: "0",
                },
                on: {
                  click: _vm.onCancel,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onCancel.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Afbryd")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }