import RouteNames from '@/models/routes'
import store from '@/store'
import { mutationTypes } from '@/store/globals'
import router from '@/router'
import Vue from 'vue'

export default async (to, from, next) => {
  if (store.state.globals.maintenance === undefined) {
    const maintenance = await Vue.axios.get(`maintenance`)

    store.commit(`globals/${mutationTypes.SET_MAINTENANCE}`, maintenance.data)

    if (store.state.globals.maintenance) {
      router.push({
        name: RouteNames.Maintenance,
        params: to.params
      })
      return
    }
  }

  await next()
}
