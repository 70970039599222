import Vue from 'vue'
import Router from 'vue-router'

import applicationRoutes from './application'
import photosRoutes from './application-photos'
import redirects from './redirects'

import Municipality from '@/components/municipality'

import LoginCallback from '@/components/authentication/loginCallback/loginCallback'
import Logout from '@/components/authentication/logout/logout'

import terms from '@/components/terms/terms'
import ExternalGdpr from '@/components/externalGdpr/externalGdpr'
import GuardianVerificationLanding from '@/components/guardianVerificationLanding/guardianVerificationLanding'

import UnsupportedBrowser from '@/components/errors/unsupportedBrowser/unsupportedbrowser'
import NotFound from '@/components/errors/notFound/not-found'
import Maintenance from '@/components/errors/maintenance'

import Verification from '@/components/verification/verification'
import SecurityCabinetDocuments from '@/components/security-cabinet/security-cabinet-documents'

import middlewares from './middlewares'
import RouteNames from '@/models/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      component: NotFound,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/signin-oidc',
      component: LoginCallback,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/logout',
      name: RouteNames.Logout,
      component: Logout,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/salgsoghandelsbetingelser',
      component: terms,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/unsupportedbrowser',
      name: RouteNames.UnsupportedBrowser,
      component: UnsupportedBrowser,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/maintenance',
      name: RouteNames.Maintenance,
      component: Maintenance,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/:municipalityCode(\\d{3})',
      component: Municipality,
      props: true,
      children: [
        {
          path: 'gdpr',
          component: ExternalGdpr,
          meta: {
            isPublic: true
          }
        },
        {
          name: 'guardian-verification',
          path: 'guardian-verification',
          component: Verification,
          meta: {
            requiresGDPR: true
          }
        },
        {
          name: 'security-cabinet',
          path: 'pinkode',
          component: SecurityCabinetDocuments
        },
        {
          path: 'verification',
          component: GuardianVerificationLanding,
          meta: {
            isPublic: true
          }
        }
      ]
        .concat(photosRoutes)
        .concat(applicationRoutes)
    },
    {
      path: '*',
      redirect: '/',
      meta: {
        isPublic: true
      }
    }
  ].concat(redirects)
})

//order matters
router.beforeEach(middlewares.maintenance)
router.beforeEach(middlewares.authentication)
router.beforeEach(middlewares.application)

router.afterEach(middlewares.scroll)

export default router
