<template lang="pug">
include logout.pug
</template>

<style lang="scss" scoped>
@import 'logout';
</style>

<script>
export default {
  name: 'logout'
}
</script>
