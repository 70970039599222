import PhotoBase from '@/components/photos/base'
import PhotoGuidelines from '@/components/photos/guidelines/photo-guidelines'
import TakePhoto from '@/components/photos/take/take-photo'
import UpdatePhoto from '@/components/photos/update/update-photo'
import PhotoAcceptance from '@/components/photos/acceptance/photo-acceptance'
import PhotoFinish from '@/components/photos/finish/photo-finish'

import { ApplicationTypes, PhotoFlowSteps, PhotoFlowTypes } from '@/models'

export default [
  {
    path: `:applicationType(${ApplicationTypes.Passport}|${ApplicationTypes.DrivingLicense})/${PhotoFlowTypes.PhotoApplicationFlow}`,
    component: PhotoBase,
    children: [
      {
        path: '',
        name: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Guidelines}`,
        component: PhotoGuidelines,
        meta: {
          requiresGDPR: true,
          requiresInitializedApplication: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoApplicationFlow
        }
      },
      {
        path: 'take',
        name: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Take}`,
        component: TakePhoto,
        meta: {
          requiresGDPR: true,
          requiresInitializedApplication: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoApplicationFlow
        }
      },
      {
        path: 'accept',
        name: `${PhotoFlowTypes.PhotoApplicationFlow}-${PhotoFlowSteps.Accept}`,
        component: PhotoAcceptance,
        meta: {
          requiresGDPR: true,
          requiresInitializedApplication: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoApplicationFlow
        }
      }
    ]
  },
  {
    path: `:applicationId/${PhotoFlowTypes.PhotoDeviceFlow}`,
    component: PhotoBase,
    meta: {
      isPublic: true,
      requiresMobile: true,
      requiresHubConnection: true
    },
    children: [
      {
        path: '',
        name: `${PhotoFlowTypes.PhotoDeviceFlow}-${PhotoFlowSteps.Guidelines}`,
        component: PhotoGuidelines,
        meta: {
          isPublic: true,
          requiresMobile: true,
          requiresHubConnection: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoDeviceFlow
        }
      },
      {
        path: 'take',
        name: `${PhotoFlowTypes.PhotoDeviceFlow}-${PhotoFlowSteps.Take}`,
        component: TakePhoto,
        meta: {
          isPublic: true,
          requiresMobile: true,
          requiresHubConnection: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoDeviceFlow
        }
      },
      {
        path: 'accept',
        name: `${PhotoFlowTypes.PhotoDeviceFlow}-${PhotoFlowSteps.Accept}`,
        component: PhotoAcceptance,
        meta: {
          isPublic: true,
          requiresMobile: true,
          requiresHubConnection: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoDeviceFlow
        }
      },
      {
        path: 'finish',
        name: `${PhotoFlowTypes.PhotoDeviceFlow}-${PhotoFlowSteps.Finish}`,
        component: PhotoFinish,
        meta: {
          isPublic: true,
          requiresMobile: true
        },
        props: {
          photoFlowType: PhotoFlowTypes.PhotoDeviceFlow
        }
      }
    ]
  },
  {
    path: `:applicationId/${PhotoFlowTypes.PhotoUpdateFlow}/:photoId`,
    component: PhotoBase,
    children: [
      {
        path: '',
        name: `${PhotoFlowTypes.PhotoUpdateFlow}-${PhotoFlowSteps.Guidelines}`,
        component: PhotoGuidelines,
        meta: {
          requiresMobile: true
        },
        props: (route) => ({
          ...route.params,
          photoFlowType: PhotoFlowTypes.PhotoUpdateFlow
        })
      },
      {
        path: 'take',
        name: `${PhotoFlowTypes.PhotoUpdateFlow}-${PhotoFlowSteps.Take}`,
        component: UpdatePhoto,
        meta: {
          requiresMobile: true
        },
        props: (route) => ({
          ...route.params,
          photoFlowType: PhotoFlowTypes.PhotoUpdateFlow
        })
      },
      {
        path: 'accept',
        name: `${PhotoFlowTypes.PhotoUpdateFlow}-${PhotoFlowSteps.Accept}`,
        component: PhotoAcceptance,
        meta: {
          requiresMobile: true
        },
        props: (route) => ({
          ...route.params,
          photoFlowType: PhotoFlowTypes.PhotoUpdateFlow
        })
      },
      {
        path: 'finish',
        name: `${PhotoFlowTypes.PhotoUpdateFlow}-${PhotoFlowSteps.Finish}`,
        component: PhotoFinish,
        meta: {
          requiresMobile: true
        },
        props: (route) => ({
          ...route.params,
          photoFlowType: PhotoFlowTypes.PhotoUpdateFlow
        })
      }
    ]
  }
]
