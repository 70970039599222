<template lang="pug">
include externalGdpr.pug
</template>

<style lang="scss" scoped>
@import 'externalGdpr';
</style>

<script>
import { actionTypes as globalActionTypes } from '@/store/globals/actions'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapGlobalState, mapActions: mapGlobalActions } = createNamespacedHelpers('globals')

export default {
  name: 'externalGdpr',
  computed: {
    ...mapGlobalState({
      gdprText: (state) => (state.gdprText ? state.gdprText : 'Henter Gdpr betingelser')
    })
  },
  methods: {
    ...mapGlobalActions({
      loadGdprText: globalActionTypes.LOAD_GDPR_TEXT
    })
  },
  mounted: function () {
    this.loadGdprText()
  }
}
</script>
