const mutationTypes = {
  SET_LOADING: 'setLoading',
  RESET_LOADING: 'resetLoading',
  SET_PHOTO_CHOICES: 'setPhotoChoices',
  SET_CURRENT_PHOTO_PERSON_ID: 'setCurrentPhotoPersonId',
  SET_POSSIBLE_PHOTO: 'setPossiblePhoto',
  SET_ACCEPTED_PHOTO_FOR_CURRENT_PERSON: 'setAcceptedPhotoForCurrentPerson'
}

const mutations = {
  [mutationTypes.SET_LOADING]: (state, loadingMessage) => {
    state.loader.isLoading = true
    state.loader.loadingMessage = loadingMessage
  },
  [mutationTypes.RESET_LOADING]: (state) => {
    state.loader.isLoading = false
    state.loader.loadingMessage = undefined
  },
  [mutationTypes.SET_PHOTO_CHOICES]: (state, { photoChoices, locked }) => {
    state.photoChoices = []
    for (let i = 0; i < photoChoices.length; i++) {
      let photoChoice = {
        id: photoChoices[i].id,
        name: photoChoices[i].name,
        photoChoice: photoChoices[i].photoChoice,
        photoId: photoChoices[i].photoId
      }
      if (photoChoices[i].photo) {
        photoChoice.photo = photoChoices[i].photo.data
      }
      state.photoChoices.push(photoChoice)
    }

    state.photosAreLocked = locked
  },
  [mutationTypes.SET_CURRENT_PHOTO_PERSON_ID]: (state, currentPhotoPersonId) => {
    state.currentPhotoPersonId = currentPhotoPersonId
  },
  [mutationTypes.SET_POSSIBLE_PHOTO]: (state, possiblePhoto) => {
    state.possiblePhoto = possiblePhoto
  },
  [mutationTypes.SET_ACCEPTED_PHOTO_FOR_CURRENT_PERSON]: (state) => {
    let index = state.photoChoices.findIndex((c) => c.id === state.currentPhotoPersonId)
    state.photoChoices[index].photoId = state.possiblePhoto.id
    state.photoChoices[index].photo = state.possiblePhoto.data
  }
}

export { mutationTypes }
export default mutations
