var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
          _c("h2", { staticClass: "text-view-title mb-1 mt-2" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
          _c("h2", { staticClass: "text-view-subtitle mt-0 mb-2" }, [
            _vm._v(_vm._s(_vm.texts.subtitle)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 mb-1" },
          [
            _c("switch-button", {
              attrs: {
                injectedValue: _vm.useQRcode,
                onChange: _vm.showHideQRcode,
                yesLabel: "Brug QR kode",
                noLabel: "Send e-mail/SMS",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.useQRcode
        ? [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
                _c("h2", { staticClass: "text-view-title" }, [
                  _vm._v(_vm._s(_vm.texts.qrTitle)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col s12 center-align" },
                [
                  _c("qr-code", {
                    staticClass: "flex-center-align",
                    attrs: {
                      text: _vm.qrCodeLink,
                      size: _vm.size,
                      "error-level": "L",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        : [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 center-align mb-2",
                },
                [
                  _c("h2", { staticClass: "text-view-title" }, [
                    _vm._v(_vm._s(_vm.texts.emailOrPhoneNumberTitle)),
                  ]),
                  _c("h2", { staticClass: "text-view-subtitle mt-1" }, [
                    _vm._v(_vm._s(_vm.texts.emailOrPhoneNumberSubtitle)),
                  ]),
                  _c("input-field", {
                    attrs: {
                      validationRules: "required|phoneNumberOrEmail",
                      inputTextField: "E-mail eller telefonnummer",
                      inputType: "text",
                      callback: _vm.emailphonecallback,
                      injectedValue: _vm.emailOrPhone.value,
                      "aria-label": "Email eller telefonnummer",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.sendLinkAvailable && _vm.emailOrPhone.validationAccepted
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col s8 offset-s2 l6 offset-l3 xl4 offset-xl4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "scandi-btn",
                          attrs: {
                            role: "button",
                            disabled: "true",
                            "aria-label": "Send e-mail/SMS",
                            tabindex: "0",
                          },
                          on: {
                            click: _vm.onSendEmailSMSClicked,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.onSendEmailSMSClicked.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Send e-mail/SMS")]
                      ),
                    ]
                  ),
                ])
              : !_vm.sendLinkAvailable
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col s12 center-align" }, [
                    _c("h2", { staticClass: "text-view-subtitle" }, [
                      _vm._v(
                        _vm._s(_vm.texts.sendLinkRetryMessage) +
                          " " +
                          _vm._s(_vm.timerCount) +
                          " sekunder."
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }