import RouteNames from '@/models/routes'
import router from '@/router'
import store from '@/store'
import { mutationTypes } from '@/store/application'

export default (to, from, next) => {
  if (store.state.application.id != undefined) {
    to.params.applicationId = store.state.application.id
  }

  if (to.params.applicationId !== undefined) {
    store.commit(`application/${mutationTypes.SET_APPLICATION_ID}`, to.params.applicationId)
  }

  if (to.params.applicationType !== undefined) {
    store.commit(`application/${mutationTypes.SET_APPLICATION_TYPE}`, to.params.applicationType)
  }

  if (store.state.application.applicationType) {
    to.params.applicationType = store.state.application.applicationType
  }

  if (to.meta.requiresInitializedApplication && store.state.application.id === undefined) {
    router.push({
      name: RouteNames.ApplicationStart,
      params: to.params
    })

    return
  }

  next()
}
