import { ApplicationTypes, DrivingLicenseSituation, PassportSituations, PhotoChoices } from '@/models'
import photoChoices from '@/models/photoChoices'

const getterTypes = {
  CURRENT: 'current',
  GET_PERSON_APPLICATION: 'getPersonApplication',
  PERSONS: 'persons',
  PERSONS_COUNT: 'personsCount',

  ANY_PERSON_APPLICATION_WITH_EXISTING_CASE: 'anyPersonAPplicationWithExistingCase',

  NEXT_PERSON_ID: 'nextPersonId',
  PREVIOUS_PERSON_ID: 'previousPersonId',

  REQUIRES_OTHER_GUARDIAN_CONSENT: 'requiresOtherGuardianConsent',
  OTHER_GUARDIAN_CONSENT_COMPLETED: 'otherGuardianConsentCompleted',

  REQUIRES_DETAIL_SITUATION: 'requiresDetailSituation',
  REQUIRES_PERSON_HEIGHT: 'requiresPersonHeigth',
  SITUATION_COMPLETED: 'situationCompleted',
  ARE_ALL_SITUATIONS_COMPLTED: 'allSituationsCompleted',
  ANY_NEW_DOCUMENT: 'anyNewDocument',

  AVAILABLE_PHOTO_CHOICES_META: 'availablePhotoChoicesMeta',
  PHOTO_CHOICE_AVAILABLE: 'photoChoiceAvailable',
  SELFTAKEN_PHOTO_PENDING: 'selfTakenPhotoPending',

  TIMEBOOKING_AVAILABLE: 'timebookingAvailable',
  TIMEBOOKING_REQUIRED: 'timeBookingRequired',
  TIMEBOOKING_PEOPLE_AMMOUNT: 'timebookingPeopleAmount',
  REQUIRES_PERSON_PHONE_NUMBER: 'requiresPersonPhoneNumber',

  REQUIRES_PERSON_EMAIL: 'requiresPersonEmail',

  PAYMENT_CHOICE_AVAILABLE: 'paymentChoiceAvailable',
  PAY_ONLINE_AVAILABLE: 'payOnlineAvailable',
  PAY_AT_ATTENDANCE_AVAILABLE: 'payAtAttendanceAvailable'
}

const getters = {
  [getterTypes.CURRENT]: (state) => {
    return state.personApplications.find((i) => i.id === state.currentPersonId)
  },
  [getterTypes.GET_PERSON_APPLICATION]: (state) => (personId) => {
    return state.personApplications.find((i) => i.id === personId)
  },
  [getterTypes.PERSONS]: (state) => {
    return state.personApplications.map((i) => i.person)
  },
  [getterTypes.PERSONS_COUNT]: (state) => {
    return state.personApplications.length
  },
  [getterTypes.REQUIRES_OTHER_GUARDIAN_CONSENT]: (state) => {
    return state.personApplications.some((i) => i.person.guardian2Info)
  },
  [getterTypes.OTHER_GUARDIAN_CONSENT_COMPLETED]: (state) => {
    if (state.personApplications.filter((i) => i.person.guardian2Info).length === 0) {
      return true
    }

    return (
      state.otherGuardianConsentTermsAccepted === true &&
      state.personApplications
        .filter((i) => i.person.guardian2Info && i.person.guardian2Info.hasFullCprNumber)
        .every((i) => i.person.guardian2Info.isDigitalPostSupported || i.person.guardian2Info.email)
    )
  },
  [getterTypes.NEXT_PERSON_ID]: (state) => {
    const currentApplicationIndex = state.personApplications.findIndex((i) => i.id === state.currentPersonId)

    if (currentApplicationIndex + 1 < state.personApplications.length) {
      return state.personApplications[currentApplicationIndex + 1].id
    }

    return undefined
  },
  [getterTypes.PREVIOUS_PERSON_ID]: (state) => {
    const currentApplicationIndex = state.personApplications.findIndex((i) => i.id === state.currentPersonId)

    if (currentApplicationIndex > 0) {
      return state.personApplications[currentApplicationIndex - 1].id
    }
  },
  [getterTypes.ANY_PERSON_APPLICATION_WITH_EXISTING_CASE]: (state) => {
    return state.personApplications.some((i) => i.person.existingCases.length)
  },
  [getterTypes.REQUIRES_DETAIL_SITUATION]: (state, getters) => {
    if (getters[getterTypes.CURRENT] === undefined) {
      return false
    }

    if (state.applicationType === ApplicationTypes.Passport) {
      return getters[getterTypes.CURRENT].situation.newDocument
    }

    if (state.applicationType === ApplicationTypes.DrivingLicense) {
      return (
        getters.current.situation.newDocument &&
        ![DrivingLicenseSituation.International, DrivingLicenseSituation.TempRenewal].includes(getters.current.situation.id)
      )
    }
  },
  [getterTypes.REQUIRES_PERSON_HEIGHT]: (state) => {
    return state.settings.isHeightRequired
  },
  [getterTypes.ANY_NEW_DOCUMENT]: (state) => {
    return state.personApplications.some((i) => i.situation.newDocument || i.situation.newDocument === undefined)
  },
  [getterTypes.SITUATION_COMPLETED]: (state, getters) => (personId) => {
    const situation = state.personApplications.find((i) => i.id === personId).situation

    if (!situation.id) {
      return false
    }

    if (state.applicationType === ApplicationTypes.DrivingLicense) {
      if ([DrivingLicenseSituation.International, DrivingLicenseSituation.TempRenewal].includes(situation.id)) {
        return true
      }

      if (situation.id === DrivingLicenseSituation.Lost && (!situation.lostReason || situation.newDocument === undefined)) {
        return false
      }

      if (situation.newDocument) {
        return (
          situation.hasLicenseForLargeVehicle != undefined &&
          situation.hasCommercialLicense != undefined &&
          situation.keepCommercialLicense != undefined
        )
      }
    }

    if (state.applicationType === ApplicationTypes.Passport) {
      if (situation.id === PassportSituations.Lost && (!situation.lostReason || situation.newDocument === undefined)) {
        return false
      }

      if (situation.newDocument) {
        if (getters[getterTypes.REQUIRES_PERSON_HEIGHT] && situation.height === undefined) {
          return false
        }

        return (
          situation.hasBeenCitizenOfAnotherCountry !== undefined &&
          situation.previouslyHadDanishPassport !== undefined &&
          situation.inPossessionOfOldPassport !== undefined
        )
      }
    }

    return true
  },
  [getterTypes.ARE_ALL_SITUATIONS_COMPLTED]: (state, getters) => {
    return state.personApplications.every((i) => getters[getterTypes.SITUATION_COMPLETED](i.id))
  },
  [getterTypes.AVAILABLE_PHOTO_CHOICES_META]: (state) => {
    const choices = []

    let ageText = ''

    if (state.settings.photo.ageRestrictions) {
      ageText = `. Kommunen anbefaler at man anvender denne løsning til børn i alderen 0-${state.settings.photo.ageRestrictions.minimumAge} år`
    }

    if (state.settings.photo.selfTakenPhotoAvailable) {
      choices.push({
        ...photoChoices.find((i) => i.key === PhotoChoices.SelfTakenPhoto),
        text: photoChoices.find((i) => i.key === PhotoChoices.SelfTakenPhoto).text + ageText
      })
    }

    if (state.settings.photo.centerPhotoAvailable) {
      choices.push(photoChoices.find((i) => i.key === PhotoChoices.CenterPhoto))
    }

    choices.push({
      ...photoChoices.find((i) => i.key === PhotoChoices.BringOwnPhoto),
      text: photoChoices.find((i) => i.key === PhotoChoices.BringOwnPhoto).text + (!state.settings.photo.selfTakenPhotoAvailable ? ageText : '')
    })

    for (let choice of choices) {
      choice.titleWithPrice = choice.title + (choice.showPrice ? ` (${state.settings.photo.price} kr.)` : '')
    }

    return choices
  },
  [getterTypes.PHOTO_CHOICE_AVAILABLE]: (state) => {
    return state.personApplications.some((i) => i.availablePhotoChoices.length > 1 && i.situation.newDocument)
  },
  [getterTypes.SELFTAKEN_PHOTO_PENDING]: (state) => {
    return state.personApplications.some((i) => i.photoChoice === PhotoChoices.SelfTakenPhoto && i.photo === undefined)
  },
  [getterTypes.TIMEBOOKING_AVAILABLE]: (state, getters) => {
    return state.settings.timeBooking && (state.settings.isAttendanceAtLostIdRequired || getters[getterTypes.ANY_NEW_DOCUMENT])
  },
  [getterTypes.TIMEBOOKING_REQUIRED]: (state) => {
    return state.settings.timeBooking && state.settings.timeBooking.required
  },
  [getterTypes.TIMEBOOKING_PEOPLE_AMMOUNT]: (state) => {
    return state.personApplications.filter((i) => i.situation.newDocument || state.settings.isAttendanceAtLostIdRequired).length
  },
  [getterTypes.REQUIRES_PERSON_PHONE_NUMBER]: (state) => {
    return state.settings.isPhoneNumberRequired
  },
  [getterTypes.REQUIRES_PERSON_EMAIL]: (state) => {
    return !state.person.isDigitalPostSupported
  },
  [getterTypes.PAYMENT_CHOICE_AVAILABLE]: (state) => {
    return state.settings.payment.payAtAttendance && state.settings.payment.payOnline
  },
  [getterTypes.PAY_AT_ATTENDANCE_AVAILABLE]: (state) => {
    return state.settings.payment.payAtAttendance
  },
  [getterTypes.PAY_ONLINE_AVAILABLE]: (state) => {
    return state.settings.payment.payOnline
  }
}

export { getterTypes }
export default getters
