<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
import { FocusLoop } from '@vue-a11y/focus-loop'
import Loader from '@/components/shared/loader'
import LogoutModal from '@/components/shared/modals/logoutModal'
import AutomaticLogoutModal from '@/components/shared/modals/automaticLogoutModal'
import ProgressBar from './progressBar'

import { createNamespacedHelpers } from 'vuex'
import NavigationButtons from '@/components/shared/navigationButtons'
import { ApplicationTypes } from '@/models'
import { actionTypes } from '@/store/application'
import services from '@/services'
import router from '@/router'
import RouteNames from '@/models/routes'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applicationStore = createNamespacedHelpers('application')
const oidcStore = createNamespacedHelpers('oidc')
const Moment = require('moment')

export default {
  name: 'Application',
  props: ['applicationType'],
  components: {
    FocusLoop,
    Loader,
    NavigationButtons,
    LogoutModal,
    AutomaticLogoutModal,
    ProgressBar
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.landing,
      warningTexts: (state) => state.texts.applicationUsageWarning
    }),
    ...applicationStore.mapState({
      applicationId: (state) => state.id,
      person: (state) => state.person,
      availableApplicationsTypes: (state) => state.availableApplicationsTypes,
      passportAccepted: (state) => state.availableApplicationsTypes.includes(ApplicationTypes.Passport),
      drivingLicenseAccepted: (state) => state.availableApplicationsTypes.includes(ApplicationTypes.DrivingLicense),
      isLoading: (state) => state.navigation.isLoading,
      transitionName: (state) => state.navigation.transitionName,
      loadingMessage: (state) => state.navigation.loadingMessage,
      warnings: (state) => state.applicationUsageWarnings
    }),
    ...oidcStore.mapGetters({
      tokenExpiration: 'oidcAccessTokenExp'
    }),
    nextStepAllowed: function () {
      return !!this.flow || !this.showApplicationSelection
    }
  },
  data: function () {
    return {
      flow: undefined,
      promtLogout: false,
      automaticLogout: false,
      showApplicationSelection: !this.applicationType
    }
  },
  methods: {
    ...applicationStore.mapActions({
      loadAvailableApplicationsTypes: actionTypes.LOAD_AVAILABLE_APPLICATION_TYPES
    }),
    onLogOffClicked: function () {
      this.promtLogout = true
    },
    onLogOffCancelled: function () {
      this.promtLogout = false
    },
    closeAutomaticLogoutModal: function () {
      this.automaticLogout = false
    },
    onFlowSelected: function (flow) {
      this.flow = flow
    },
    onNextStepClicked: async function () {
      if(this.warnings.includes(this.flow) && this.showApplicationSelection) {
        this.showApplicationSelection = false
      } else {
        await this.startApplication(this.flow)
      }
    },
    startApplication: async function (applicationType) {
      if(!this.applicationType) {
        await router.push({ name: RouteNames.ApplicationStart, params: { applicationType: applicationType } })
      }
      services.application.start()
    }
  },
  beforeMount: function () {
    if (!this.applicationId) {
      this.loadAvailableApplicationsTypes().then(async () => {
        if (this.applicationType && !this.warnings.includes(this.applicationType)) {
          services.application.start()
        } else if (this.availableApplicationsTypes.length === 1 && !this.warnings.includes(this.availableApplicationsTypes[0])) {
          await this.startApplication(this.availableApplicationsTypes[0])
        } else if(this.availableApplicationsTypes.length === 1 && this.warnings.includes(this.availableApplicationsTypes[0])) {
          this.flow = this.availableApplicationsTypes[0]
          this.showApplicationSelection = false
        }
      })
    }
  },
  mounted: function () {
    setInterval(() => {
      this.automaticLogout = Moment(this.tokenExpiration).diff(Moment(), 'seconds') < 120
    }, 1000)
  }
}
</script>
