<template lang="pug">
include personprogress.pug
</template>

<style lang="scss" scoped>
@import 'personprogress';
</style>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar/index.vue'
import NavigationButtons from '@/components/shared/navigationButtons'
import { createNamespacedHelpers } from 'vuex'
import services from '@/services'

import { getterTypes } from '@/store/application'
const applicationStore = createNamespacedHelpers('application')
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'personprogress',
  props: {
    forward: {
      type: Boolean
    },
    skip: {
      type: Boolean
    }
  },
  components: {
    VuePerfectScrollbar,
    navigationButtons: NavigationButtons
  },
  computed: {
    ...applicationStore.mapGetters({
      personApplication: getterTypes.CURRENT,
      situationCompleted: getterTypes.SITUATION_COMPLETED,
      people: getterTypes.PERSONS
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.personProgress
    })
  },
  methods: {
    onPreviousStepClicked: async function () {
      await services.application.previousStep()
    },
    onNextStepClicked: async function () {
      await services.application.nextStep()
    }
  }
}
</script>
