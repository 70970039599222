<template lang="pug">
include selftaken-photo.pug
</template>

<script>
import services from '@/services'
import ConnectDevice from './connectDevice'
import AwaitingPhotos from './awaitingPhotos'
import PhotosReceived from './photosReceived'
import NavigationButtons from '@/components/shared/navigationButtons'
import { actionTypes, getterTypes } from '@/store/application'
import { createNamespacedHelpers } from 'vuex'
import router from '@/router'
import RouteNames from '@/models/routes'

const applictionStore = createNamespacedHelpers('application')

const Steps = {
  ConnectDevice: 'connectDevice',
  AwaitingPhotos: 'awaitingPhotos',
  PhotosReceived: 'photosReceived'
}

export default {
  name: 'ConnectDevice',
  props: ['municipalityCode', 'step'],
  components: {
    navigationButtons: NavigationButtons,
    connectDevice: ConnectDevice,
    awaitingPhotos: AwaitingPhotos,
    photosReceived: PhotosReceived
  },
  computed: {
    ...applictionStore.mapGetters({
      photoPending: getterTypes.SELFTAKEN_PHOTO_PENDING
    }),
    steps: function () {
      return Steps
    }
  },
  methods: {
    ...applictionStore.mapActions({
      setPhotoChoices: actionTypes.SET_PHOTO_CHOICES,
      getPhotos: actionTypes.GET_PHOTOS
    }),
    changePhotoStep: async function (step) {
      await router.push({ name: RouteNames.SelfTakenPhoto, params: { step: step } })
    },
    onPreviousStepClicked: async function () {
      if (this.step === Steps.ConnectDevice) {
        services.application.previousStep()
      } else if (this.step === Steps.AwaitingPhotos) {
        await this.changePhotoStep(Steps.ConnectDevice)
        await services.hub.abortDeviceSession()
      }
    },
    onNextStepClicked: async function () {
      if (this.photoPending) {
        await this.changePhotoStep(Steps.ConnectDevice)
      } else {
        this.setPhotoChoices()
        services.application.nextStep()
      }
    }
  },
  mounted: function () {
    if (!this.step) {
      this.changePhotoStep(Steps.ConnectDevice)
    }

    services.hub.subscribeEvent('device-connected', async () => {
      if (this.step === Steps.ConnectDevice) {
        await this.changePhotoStep(Steps.AwaitingPhotos)
      }
    })

    services.hub.subscribeEvent('device-disconnected', async (withPhoto) => {
      if (this.step === Steps.AwaitingPhotos) {
        if (withPhoto) {
          await this.changePhotoStep(Steps.PhotosReceived)
          this.getPhotos()
        } else {
          await this.changePhotoStep(Steps.ConnectDevice)
        }
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    services.hub.abortSession()
    next()
  }
}
</script>
