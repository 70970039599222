var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "row panes" }, [
        _c("div", { staticClass: "pane col s12 l6 pa-0 pane" }, [
          _c("div", { staticClass: "pane-triangle" }),
          _c(
            "div",
            { staticClass: "steps" },
            [
              _c("vue-perfect-scrollbar", { staticClass: "steps-container" }, [
                _c("ol", { staticClass: "progresslist s12" }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.people, function (person) {
                      return _c("li", [
                        _c("div", { staticClass: "row row-person" }, [
                          _c("div", { staticClass: "col s3" }, [
                            _vm.situationCompleted(person.id)
                              ? _c("img", {
                                  staticClass: "img-check step-icon",
                                  attrs: { src: "/static/checkmarkdark.svg" },
                                })
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "col s9" }, [
                            _vm.situationCompleted(person.id)
                              ? _c("div", { staticClass: "step-content" }, [
                                  _c("h5", { staticClass: "step-title bold" }, [
                                    _vm._v(_vm._s(person.name)),
                                  ]),
                                ])
                              : _c("div", { staticClass: "step-content" }, [
                                  _c("h5", { staticClass: "step-title" }, [
                                    _vm._v(_vm._s(person.name)),
                                  ]),
                                ]),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          {
            staticClass:
              "col s12 l6 pane stretch-children-horizontal hide-on-med-and-down",
          },
          [
            _c("div", { staticClass: "row before-bottom-nav valign-wrapper" }, [
              _c("div", { staticClass: "col s10 offset-s1 px-3" }, [
                _c("h2", { staticClass: "text-question center-align my-2" }, [
                  _vm._v(_vm._s(_vm.texts.nextPerson)),
                  _c("br"),
                  _c("span", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.personApplication
                          ? _vm.personApplication.person.name
                          : ""
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("navigation-buttons", {
              staticClass: "bottom-nav desktop hide-on-med-and-down",
              attrs: {
                onPreviousClick: _vm.onPreviousStepClicked,
                onNextClick: _vm.onNextStepClicked,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row before-bottom-nav hide-on-large-only" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s12" }, [
            _c("h2", { staticClass: "text-question center-align my-2" }, [
              _vm._v(_vm._s(_vm.texts.nextPerson)),
              _c("br"),
              _c("span", { staticStyle: { "font-weight": "700" } }, [
                _vm._v(
                  _vm._s(
                    _vm.personApplication
                      ? _vm.personApplication.person.name
                      : ""
                  )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("navigation-buttons", {
        staticClass: "bottom-nav hide-on-large-only",
        attrs: {
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }