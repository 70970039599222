var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.openEditWindow
        ? _c(
            "focus-loop",
            { attrs: { "is-visible": _vm.openEditWindow } },
            [
              _c("transition", { attrs: { name: "slide-fade" } }, [
                _c("div", { staticClass: "modal-mask" }, [
                  _c("div", { staticClass: "modal-wrapper" }, [
                    _c("div", { staticClass: "modal-container" }, [
                      _c(
                        "div",
                        { staticClass: "modal-header" },
                        [
                          _vm._t("default", function () {
                            return [_vm._v("Ændr fotovalg?")]
                          }),
                        ],
                        2
                      ),
                      _c("div", { staticClass: "modal-body" }, [
                        _c(
                          "ol",
                          {
                            staticClass: "scandi-list always-vertical",
                            attrs: { role: "radiogroup" },
                          },
                          _vm._l(
                            _vm.editPersonPhotoChoices.choices,
                            function (photoChoiceMeta) {
                              return _c(
                                "li",
                                {
                                  staticClass:
                                    "scandi-list-item-one-column scandi-list-minor-padding",
                                  class: {
                                    selected:
                                      _vm.editPersonPhotoChoices.photoChoice ===
                                      photoChoiceMeta.key,
                                  },
                                  staticStyle: { color: "white" },
                                  attrs: {
                                    role: "radio",
                                    "aria-label": photoChoiceMeta.title,
                                    tabindex: "0",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onChangePhotoChoiceSelected(
                                        photoChoiceMeta.key
                                      )
                                    },
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.onChangePhotoChoiceSelected(
                                        photoChoiceMeta.key
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "scandi-list-title" },
                                    [_vm._v(_vm._s(photoChoiceMeta.title))]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align my-1" }, [
          _c("h2", { staticClass: "text-view-title mb-1 mt-2" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
          _c("h2", { staticClass: "text-view-subtitle mt-0 mb-2" }, [
            _vm._v(_vm._s(_vm.texts.subtitle)),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "row before-bottom-nav flex-center-align",
          staticStyle: { width: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "col center-align",
              staticStyle: { width: "inherit", "max-width": "fit-content" },
            },
            [
              _c(
                "ol",
                { staticClass: "scandi-list" },
                _vm._l(_vm.personPhotoChoices, function (personPhotoChoice) {
                  return _c(
                    "li",
                    {
                      staticClass: "scandi-list-item-one-column",
                      staticStyle: { "border-bottom": "2px solid white" },
                    },
                    [
                      _c("span", { staticClass: "scandi-list-title bold" }, [
                        _vm._v(_vm._s(personPhotoChoice.person.name)),
                      ]),
                      _c("span", {
                        staticClass: "scandi-list-title",
                        attrs: { for: personPhotoChoice.photoChoice },
                      }),
                      personPhotoChoice.photo
                        ? _c("img", {
                            staticClass: "photo",
                            attrs: {
                              src:
                                "data:image/jpeg;base64," +
                                personPhotoChoice.photo.data,
                              alt: personPhotoChoice.person.name,
                            },
                          })
                        : _c("img", {
                            staticClass: "photo",
                            attrs: {
                              src: "/static/silhouette.jpg",
                              alt: "ukendt",
                            },
                          }),
                      _c(
                        "ol",
                        { staticClass: "scandi-list always-vertical pt-1" },
                        [
                          _c(
                            "li",
                            {
                              staticClass:
                                "scandi-list-item-one-column scandi-list-minor-padding",
                              staticStyle: { "flex-direction": "row" },
                              attrs: {
                                "aria-label":
                                  personPhotoChoice.photoChoiceTitle,
                                tabindex: "0",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onOpenEditWindow(
                                    personPhotoChoice.person.id
                                  )
                                },
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onOpenEditWindow(
                                    personPhotoChoice.person.id
                                  )
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "scandi-list-title" }, [
                                _vm._v(
                                  _vm._s(personPhotoChoice.photoChoiceTitle)
                                ),
                              ]),
                              _c("img", {
                                staticClass: "edit-icon",
                                attrs: {
                                  src: "/static/edit_pencil_icon.svg",
                                  alt: "redigere",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }