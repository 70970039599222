<template lang="pug">
include existingCases.pug
</template>

<script>
import { FocusLoop } from '@vue-a11y/focus-loop'
import { createNamespacedHelpers } from 'vuex'
import NavigationButtons from '@/components/shared/navigationButtons'
import { actionTypes } from '@/store/application'
import services from '@/services'
import ExistingCaseModal from '@/components/shared/modals/existingCasesModal'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applicationStore = createNamespacedHelpers('application')

export default {
  name: 'ExistingCases',
  components: {
    navigationButtons: NavigationButtons,
    FocusLoop,
    ExistingCaseModal
  },
  computed: {
    ...applicationStore.mapState({
      personApplications: (state) => state.personApplications
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.existingCases
    }),
    existingCases: function () {
      return this.personApplications.flatMap((i) => i.person.existingCases)
    },
    paymentReservation: function () {
      return this.existingCases.some((c) => c.hasPayment)
    },
    secondGuardianIsAlreadyApproved: function () {
      return this.existingCases.some((c) => c.hasSecondGuardianSignature)
    },
    previousStepAvailable: function () {
      return services.application.previousStepAvailable()
    }
  },
  data: function() {
    return {
      promptConfirmation: false
    }
  },
  methods: {
    ...applicationStore.mapActions({
      cancelExistingCases: actionTypes.CANCEL_EXISTING_CASE
    }),
    onConfirmationCancel: function () {
      this.promptConfirmation = false
    },
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    acceptNextStepConfirmation: async function () {
      await this.cancelExistingCases()
      services.application.nextStep()
    },
    onNextStepClicked: function () {
      this.promptConfirmation = true
    }
  }
}
</script>
