<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'AttendanceInfo',
  props: {
    center: {
      type: Object,
      required: true
    },
    bookingTime: {
      type: Object,
      required: false
    },
    anyLostDocument: {
      type: Boolean,
      required: true
    },
    anyChildPassport: {
      type: Boolean,
      required: true
    },
    anyAdultPassport: {
      type: Boolean,
      required: true
    },
    requiresMedicalCertificate: {
      type: Boolean,
      required: true
    },
    applicationType: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.summary
    })
  }
}
</script>
