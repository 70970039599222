import Vue from 'vue'
import { mutationTypes } from './mutations'
import { mutationTypes as globalMutationTypes } from './../globals/mutations'

const actionTypes = {
  LOAD_DOCUMENTS: 'loadDocuments',
  GENERATE_PINCODE: 'generatePinCode'
}

const actions = {
  [actionTypes.LOAD_DOCUMENTS]: async ({ commit }) => {
    try {
      const response = await Vue.axios.get('security-cabinet')
      commit(mutationTypes.SET_DOCUMENTS, response.data)
    } catch {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
    }
  },
  [actionTypes.GENERATE_PINCODE]: async ({ commit }, contentUuid) => {
    try {
      const response = await Vue.axios.post(`security-cabinet/${contentUuid}/pin-code`)
      commit(mutationTypes.SET_PINCODE, { contentUuid: contentUuid, pinCode: response.data })
    } catch {
      commit(`globals/${globalMutationTypes.SET_ERROR}`, null, { root: true })
    }
  }
}

export { actionTypes }
export default actions
