<template lang="pug">
include index.pug
</template>

<style lang="scss" scoped>
@import 'index';
</style>

<script>
export default {
  name: 'Notification',
  props: {
    icon: String,
    text: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      transistion: 'slide-fade'
    }
  }
}
</script>
