var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content-centered" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "row width-limit-hundred" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row width-limit-hundred" }, [
        _c("div", { staticClass: "col s12 center-align" }, [
          _c("h2", { staticClass: "text-view-subtitle" }, [
            _vm._v(_vm._s(_vm.minorMessage)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 l6 offset-l3" }, [
          _c(
            "div",
            {
              staticClass: "scandi-btn",
              attrs: { tabIndex: "0", role: "button", "aria-label": "Log af" },
              on: {
                click: _vm.onLogOff,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.onLogOff.apply(null, arguments)
                },
              },
            },
            [_vm._v("Log af")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }