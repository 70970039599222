var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content bottom-nav-container" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align mt-2 mb-1" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.texts.title)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col s10 offset-s1 l8 offset-l2 xl4 offset-xl4 my-1" },
          [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reason,
                  expression: "reason",
                },
              ],
              ref: "reasonTextarea",
              staticClass: "scandi-textarea",
              attrs: {
                placeholder: _vm.texts.reasonPlaceholder,
                "aria-label": _vm.texts.reasonPlaceholder,
                tabindex: "0",
              },
              domProps: { value: _vm.reason },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.reason = $event.target.value
                },
              },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "row before-bottom-nav" }, [
        _c(
          "div",
          {
            staticClass:
              "col s10 offset-s1 l6 offset-l3 xl4 offset-xl4 mb-1 center-align",
          },
          [
            _c("h2", { staticClass: "text-question center-align my-2" }, [
              _vm._v(_vm._s(_vm.texts.questionRenew)),
            ]),
            _c("switch-button", {
              attrs: {
                injectedValue: _vm.personApplication.situation.newDocument,
                onChange: _vm.setNewDocument,
              },
            }),
            _vm.isAttendanceAtLostIdRequired &&
            _vm.personApplication.situation.newDocument === false
              ? _c("h2", { staticClass: "text-view-subtitle ma-0 mt-1" }, [
                  _c("b", [_vm._v("Bemærk: ")]),
                  _vm._v(_vm._s(_vm.texts.questionRenewNote)),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("navigation-buttons", {
        staticClass: "bottom-nav",
        attrs: {
          nextEnabled: _vm.nextStepAllowed,
          onPreviousClick: _vm.onPreviousStepClicked,
          onNextClick: _vm.onNextStepClicked,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }