var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content-centered" }, [
    _c("div", { staticClass: "center-align" }, [
      _c("h3", [_vm._v(_vm._s(_vm.message))]),
    ]),
    _vm.minorMessage
      ? _c("div", { staticClass: "center-align" }, [
          _c("h4", [_vm._v(_vm._s(_vm.minorMessage))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }