var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content bottom-nav-container" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s10 offset-s1 center-align mb-1" }, [
        _c("h2", { staticClass: "text-view-title" }, [
          _vm._v(_vm._s(_vm.texts.title)),
        ]),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col s10 offset-s1 l8 offset-l2 xl6 offset-xl3 center-align",
        },
        [_c("div", { attrs: { id: "checkout-container-div" } })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }