import mutations, { mutationTypes } from './mutations'
import actions, { actionTypes } from './actions'

const state = {
  loader: {
    isLoading: false,
    message: undefined
  },
  children: []
}

export { actionTypes, mutationTypes }
export default {
  state: state,
  mutations: mutations,
  actions: actions,
  namespaced: true
}
