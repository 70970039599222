const ApplicationTypes = {
  Passport: 'passport',
  DrivingLicense: 'drivinglicense'
}

const CaseTypes = {
  DrivingLicense: 'drivinglicense',
  Passport: 'passport',
  ChildPassport: 'childpas'
}

const PhotoFlowTypes = {
  PhotoApplicationFlow: 'photo',
  PhotoDeviceFlow: 'photodevice',
  PhotoUpdateFlow: 'photoupdate'
}

const PhotoFlowSteps = {
  Guidelines: 'guidelines',
  Take: 'take',
  Accept: 'accept',
  Finish: 'finish'
}

const ApplicationSteps = {
  Start: 'start',
  Situation: 'situation',
  PersonalInformation: 'personalInformation',
  PhotoChoice: 'photochoice',
  TimeBooking: 'timebooking',
  Summary: 'summary'
}

const PhotoChoices = {
  Unknown: 'unknown',
  CenterPhoto: 'centerphoto',
  BringOwnPhoto: 'bringownphoto',
  SelfTakenPhoto: 'selftakenphoto'
}

const PassportSituations = {
  Renewal: 1,
  New: 2,
  Lost: 3,
  Damaged: 4,
  Stolen: 5
}

export const DrivingLicenseSituation = {
  Renewal: 1,
  Lost: 2,
  International: 3,
  TempRenewal: 4,
  NameChange: 5,
  Damaged: 6,
  Old: 7
}

export { ApplicationTypes, CaseTypes, PhotoFlowTypes, ApplicationSteps, PhotoChoices, PassportSituations, PhotoFlowSteps }
