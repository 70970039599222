import mutations, { mutationTypes } from './mutations'
import actions, { actionTypes } from './actions'
import appGetters, { getterTypes } from './getters'

const state = {
  navigation: {
    isLoading: undefined,
    loadingMessage: undefined,
    transitionName: 'forward'
  },
  availableApplicationsTypes: [],
  applicationUsageWarnings: [],
  id: undefined,
  applicationType: undefined,
  center: undefined,
  settings: {
    isAttendanceAtLostIdRequired: undefined,
    photo: {
      ageRestrictions: undefined,
      selfTakenPhotoAvailable: undefined,
      centerPhotoAvailable: undefined,
      price: undefined
    },
    timeBooking: undefined
  },
  person: undefined,
  children: [],
  personApplications: [],
  currentPersonId: undefined,
  termsAccepted: false,
  otherGuardianConsentTermsAccepted: undefined,
  payment: {
    payOnline: undefined,
    checkout: undefined
  },
  bookTime: undefined,
  bookingTimes: undefined,
  timeBooking: undefined,
  order: undefined,
  restrictedPhotoConfirmed: false
}

export { actionTypes, mutationTypes, getterTypes }
export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: appGetters,
  namespaced: true
}
