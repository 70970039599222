<template lang="pug">
include index.pug
</template>

<script>
import TextInput from '@/components/shared/textInput'
import SwitchButton from '@/components/shared/switchButton'
import { createNamespacedHelpers } from 'vuex'
import { PhotoFlowTypes } from '@/models'
import Vue from 'vue'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const { mapState: mapApplicationState } = createNamespacedHelpers('application')

export default {
  name: 'Connect',
  props: ['municipalityCode'],
  components: {
    inputField: TextInput,
    switchButton: SwitchButton
  },
  methods: {
    onSendEmailSMSClicked: function () {
      if (this.emailOrPhone.validationAccepted) {
        Vue.axios.post(`photos/send-link`, { emailOrMobileNumber: this.emailOrPhone.value })
        this.sendLinkAvailable = false
        this.timerEnabled = true
      }
    },
    emailphonecallback: function (response) {
      this.emailOrPhone = response
    },
    showHideQRcode: function (value) {
      this.useQRcode = value
    }
  },
  data: function () {
    return {
      useQRcode: true,
      sendLinkAvailable: true,
      size: 300,
      emailOrPhone: {
        value: '',
        validationAccepted: false
      },
      timerEnabled: false,
      timerCount: 30
    }
  },
  computed: {
    qrCodeLink: function () {
      return `${location.origin}/${this.municipalityCode}/${this.applicationId}/${PhotoFlowTypes.PhotoDeviceFlow}`
    },
    ...mapGlobalState({
      texts: (state) => state.texts.selfTakenPhotoConnectDevice
    }),
    ...mapApplicationState({
      person: (state) => state.person,
      applicationId: (state) => state.id
    })
  },
  mounted: function () {
    if (this.person.phoneNumber !== null && this.person.phoneNumber !== undefined && this.person.phoneNumber !== '') {
      this.emailOrPhone.value = this.person.phoneNumber
      this.emailOrPhone.validationAccepted = true
    } else if (this.person.email !== null && this.person.email !== undefined && this.person.email !== '') {
      this.emailOrPhone.value = this.person.email
      this.emailOrPhone.validationAccepted = true
    }
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--
        }, 1000)
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
        if (value === 0) {
          this.sendLinkAvailable = true
          this.timerEnabled = false
          this.timerCount = 30
        }
      }
    }
  }
}
</script>
