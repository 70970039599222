var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row", attrs: { role: "radiogroup" } }, [
    _c("div", { staticClass: "col s6" }, [
      _c(
        "div",
        {
          staticClass: "scandi-btn",
          class: { selected: _vm.injectedValue === true },
          attrs: {
            tabindex: "0",
            role: "radio",
            "aria-label": "Ja",
            "aria-checked": _vm.injectedValue === true,
          },
          on: {
            click: function ($event) {
              return _vm.onChange(true)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onChange(true)
            },
          },
        },
        [
          _c("div", { staticClass: "scandi-button-wrapper" }, [
            _vm._m(0),
            _c("div", { staticClass: "scandi-text-wrapper" }, [
              _vm._v(_vm._s(_vm.yesLabel)),
            ]),
            _vm._m(1),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "col s6" }, [
      _c(
        "div",
        {
          staticClass: "scandi-btn",
          class: { selected: _vm.injectedValue === false },
          attrs: {
            tabindex: "0",
            role: "radio",
            "aria-label": "Nej",
            "aria-checked": _vm.injectedValue === false,
          },
          on: {
            click: function ($event) {
              return _vm.onChange(false)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onChange(false)
            },
          },
        },
        [
          _c("div", { staticClass: "scandi-button-wrapper" }, [
            _vm._m(2),
            _c("div", { staticClass: "scandi-text-wrapper" }, [
              _vm._v(_vm._s(_vm.noLabel)),
            ]),
            _vm._m(3),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-icon-wrapper-hidden" }, [
      _c("img", {
        staticClass: "scandi-btn-icon",
        attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-icon-wrapper" }, [
      _c("img", {
        staticClass: "scandi-btn-icon",
        attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-icon-wrapper-hidden" }, [
      _c("img", {
        staticClass: "scandi-btn-icon",
        attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scandi-icon-wrapper" }, [
      _c("img", {
        staticClass: "scandi-btn-icon",
        attrs: { src: "/static/checkmark.svg", alt: "afkrydsning" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }