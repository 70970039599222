<template lang="pug">
include index.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const oidcStore = createNamespacedHelpers('oidc')

export default {
  name: 'Forbidden',
  props: {
    message: {
      type: String
    },
    minorMessage: {
      type: String
    }
  },
  methods: {
    ...oidcStore.mapActions({
      signOut: 'signOutOidcSilent'
    }),
    onLogOff: async function () {
      await this.signOut()

      location.reload()
    }
  }
}
</script>
