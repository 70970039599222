import mutations, { mutationTypes } from './mutations'
import actions, { actionTypes } from './actions'

const state = {
  documents: undefined
}

export { actionTypes, mutationTypes }
export default {
  state: state,
  mutations: mutations,
  actions: actions,
  namespaced: true
}
