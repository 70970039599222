<template lang="pug">
include unsuccessfulReservation.pug
</template>

<script>
import NavigationButtons from '@/components/shared/navigationButtons'
import { createNamespacedHelpers } from 'vuex'
import services from '@/services'

import { mutationTypes } from '@/store/application'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const { mapMutations: mapApplicationMutations } = createNamespacedHelpers('application')

export default {
  name: 'UnsuccessfulReservation',
  components: {
    navigationButtons: NavigationButtons
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.timeBooking
    })
  },
  methods: {
    ...mapApplicationMutations({
      clearAvailableTimes: mutationTypes.CLEAR_AVAILABLE_TIMES
    }),
    onPreviousStepClicked: function () {
      this.clearAvailableTimes()
      services.application.previousStep()
    }
  }
}
</script>
