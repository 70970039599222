<template lang="pug">
include index.pug
</template>

<script>

export default {
  name: 'ExistingCaseModal',
  props: {
    onCancel: Function,
    onAccept: Function
  }
}
</script>
