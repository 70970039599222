var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loader-wrapper" }, [
    _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col s12 center-align" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col s6 offset-s3" }, [
        _c("div", { staticClass: "progress scandi-progress" }, [
          _c("div", { staticClass: "indeterminate" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }