<template lang="pug">
include index.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const applicationStore = createNamespacedHelpers('application')

export default {
  name: 'RestrictedPhotoWarningModal',
  props: {
    onAccept: Function
  },
  computed: {
    ...applicationStore.mapState({
      minimumPhotoAge: (state) => (state.settings.photo.ageRestrictions ? state.settings.photo.ageRestrictions.minimumAge : 0)
    })
  }
}
</script>
