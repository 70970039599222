<template lang="pug">
include guardianVerificationLanding.pug
</template>

<style lang="scss" scoped>
@import 'guardianVerificationLanding';
</style>

<script>
import router from '@/router'
import { createNamespacedHelpers } from 'vuex'

const oidcStore = createNamespacedHelpers('oidc')
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')

export default {
  name: 'guardianVerificationLanding',
  methods: {
    proceedToVerification: function () {
      router.push({ path: 'guardian-verification' })
    }
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.guardianVerificationLanding
    }),
    ...oidcStore.mapGetters({
      isAuthenticated: 'oidcIsAuthenticated'
    })
  },
  beforeMount: function(){
    if(this.isAuthenticated) {
      router.push({ path: 'guardian-verification' })
    }
  }
}
</script>
