var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("loader", { attrs: { message: _vm.loadingMessage } })
    : _c("router-view")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }