var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content-centered" }, [
    _c("div", { staticClass: "row width-limit-hundred" }, [
      _c("div", { staticClass: "width-limit-hundred" }, [
        _c("h3", [
          _vm._v(
            _vm._s("Denne side er ikke understøttet af Internet Explorer")
          ),
        ]),
        _c("p", [
          _vm._v(
            _vm._s(
              "Vi anbefaler, at du i stedet bruger en af de følgende browsere:"
            )
          ),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.google.dk/chrome/browser/desktop/index.html",
            },
          },
          [_vm._v("Google Chrome")]
        ),
      ]),
      _c("li", [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.microsoft.com/da-dk/windows/microsoft-edge",
            },
          },
          [_vm._v("Microsoft Edge")]
        ),
      ]),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "https://support.apple.com/downloads/safari" } },
          [_vm._v("Apple Safari")]
        ),
      ]),
      _c("li", [
        _c(
          "a",
          { attrs: { href: "https://www.mozilla.org/da/firefox/new/" } },
          [_vm._v("Mozilla Firefox")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }