<template lang="pug">
include index.pug
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const oidcStore = createNamespacedHelpers('oidc')
const Moment = require('moment')

export default {
  name: 'RefreshTokenModal',
  props: {
    closeModal: Function
  },
  computed: {
    ...oidcStore.mapGetters({
      tokenExpiration: 'oidcAccessTokenExp'
    })
  },
  data: function () {
    return {
      tokenExpiresIn: 60
    }
  },
  methods: {
    ...oidcStore.mapActions({
      refreshToken: 'authenticateOidcSilent',
      signOut: 'signOutOidc'
    }),
    contniueApplication: function () {
      this.closeModal()
      this.refreshToken()
    }
  },
  mounted: function () {
    setInterval(() => {
      this.tokenExpiresIn = Moment(this.tokenExpiration).diff(Moment(), 'seconds')
    }, 1000)
  }
}
</script>
