<template lang="pug">
include terms.pug
</template>
<style lang="scss" scoped>
@import 'terms';
</style>

<script>
export default {
  name: 'terms'
}
</script>
