const phoneNumberValidator = {
  messages: {
    da: (field) => field + ' skal indeholde 8 karakterer.'
  },
  getMessage: {
    da: (field) => field + ' skal indeholde 8 karakterer.'
  },
  validate(value) {
    return /^[0-9]{8}$/.test(value)
  }
}
export default phoneNumberValidator
