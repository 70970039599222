<template lang="pug">
include index.pug
</template>

<script>
export default {
  name: 'NavigationButtons',
  props: {
    showNext: {
      type: Boolean,
      default: true,
      required: false
    },
    showPrevious: {
      type: Boolean,
      default: true,
      required: false
    },
    onNextClick: {
      type: Function,
      default: () => {},
      required: false
    },
    onPreviousClick: {
      type: Function,
      default: () => {},
      required: false
    },
    nextEnabled: {
      type: Boolean,
      default: true,
      required: false
    },
    previousEnabled: {
      type: Boolean,
      default: true,
      required: false
    },
    nextLabel: {
      type: String,
      default: 'Videre',
      required: false
    },
    previousLabel: {
      type: String,
      default: 'Tilbage',
      required: false
    }
  },
  methods: {
    onNextButtonClicked: function () {
      if (this.nextEnabled) {
        this.onNextClick()
      }
    },
    onPreviousButtonClicked: function () {
      if (this.previousEnabled) {
        this.onPreviousClick()
      }
    }
  }
}
</script>
