var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content-centered" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "row width-limit-hundred" }, [
        _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
          _c("h2", { staticClass: "text-view-title" }, [
            _vm._v(
              _vm._s(
                _vm.error.message ? _vm.error.message : _vm.texts.defaultMessage
              )
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row width-limit-hundred" }, [
        _c("div", { staticClass: "col s12 center-align" }, [
          _c("h2", { staticClass: "text-view-subtitle" }, [
            _vm._v(_vm._s(_vm.error.minorMessage)),
          ]),
        ]),
      ]),
      _vm.error.retriable
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s12 l6 offset-l3" }, [
              _c(
                "div",
                {
                  staticClass: "scandi-btn my-2",
                  attrs: {
                    role: "button",
                    tabindex: "0",
                    "aria-label": "Prøv igen",
                  },
                  on: {
                    click: _vm.onRetryClicked,
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onRetryClicked.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Prøv igen")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isAuthenticated
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col s12 l6 offset-l3" }, [
              _c(
                "div",
                {
                  staticClass: "scandi-btn",
                  attrs: {
                    tabindex: "0",
                    role: "button",
                    "aria-label": "Log af",
                  },
                  on: {
                    click: _vm.signOut,
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.signOut.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Log af")]
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }