var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-content-centered" }, [
    _c("div", { staticClass: "container" }, [
      _vm.isLoaded
        ? _c("div", { staticClass: "row" }, [_c("loader")], 1)
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s12 center-align my-2" }, [
                _c("span", { staticClass: "text-view-title normal" }, [
                  _vm._v(_vm._s(_vm.texts.title)),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s12 center-align" }, [
                _vm.anyItems
                  ? _c("span", { staticClass: "text-info" }, [
                      _vm._v(_vm._s(_vm.texts.instructions)),
                    ])
                  : _c("span", { staticClass: "text-info" }, [
                      _vm._v(_vm._s(_vm.texts.noData)),
                    ]),
              ]),
            ]),
            _c("div", { staticClass: "row py-3 section" }, [
              _c(
                "div",
                { staticClass: "col s12" },
                [
                  _vm._l(_vm.centerNames, function (centerName) {
                    return [
                      _c("h4", [_vm._v(_vm._s(centerName))]),
                      _vm._l(
                        _vm.documents.filter(function (doc) {
                          return doc.centerName === centerName
                        }),
                        function (document) {
                          return [
                            _c("div", { staticClass: "row py-1" }, [
                              _c("div", { staticClass: "col s7" }, [
                                _c("span", { staticClass: "text-view-title" }, [
                                  _vm._v(_vm._s(document.name)),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col s5" },
                                [
                                  document.pinCode == null
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "scandi-btn right",
                                            attrs: {
                                              tabindex: "0",
                                              role: "button",
                                              "aria-label": "Se pinkode",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.generatePinCode(
                                                  document.contentUuid
                                                )
                                              },
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.generatePinCode(
                                                  document.contentUuid
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Se pinkode")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  document.pinCode != null
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-view-title right",
                                          },
                                          [_vm._v(_vm._s(document.pinCode))]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]
                        }
                      ),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col s12 l6 offset-l3" }, [
                _c(
                  "div",
                  {
                    staticClass: "scandi-btn",
                    attrs: {
                      role: "button",
                      "aria-label": "Log af",
                      tabindex: "0",
                    },
                    on: {
                      click: _vm.signOut,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.signOut.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Log af")]
                ),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }