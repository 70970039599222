var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "progressbar-item", class: { active: _vm.selected } },
    [
      _c("img", {
        staticClass: "progressbar-item-icon",
        attrs: { src: _vm.icon, alt: "" },
      }),
      _c("img", {
        staticClass: "progressbar-item-icon completed",
        attrs: { src: "/static/checkmarkgray.svg", alt: "afkrydsning" },
      }),
      _c("span", { staticClass: "progressbar-item-title" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }