<template lang="pug">
include payment.pug
</template>

<script>
import services from '@/services'
import { loadScript } from 'vue-plugin-load-script'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applictionStore = createNamespacedHelpers('application')

export default {
  name: 'Payment',
  computed: {
    ...applictionStore.mapState({
      checkout: (state) => state.payment.checkout
    }),
    ...mapGlobalState({
      texts: (state) => state.texts.payment
    })
  },
  mounted: function () {
    loadScript(this.checkout.url).then(() => {
      const checkoutOptions = {
        checkoutKey: this.checkout.checkoutKey,
        paymentId: this.checkout.paymentId,
        containerId: 'checkout-container-div',
        language: 'da-DK'
      }

      const checkout = new Dibs.Checkout(checkoutOptions)

      checkout.on('payment-completed', function () {
        services.application.nextStep()
      })
    })
  }
}
</script>
