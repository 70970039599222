var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "row mx-1" }, [
      _c("h1", { staticClass: "text-view-title" }, [
        _vm._v(_vm._s(_vm.texts.title)),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("p", { staticClass: "text-view-subtitle" }, [
          _vm._v(_vm._s(_vm.texts.description)),
        ]),
      ]),
      _c("div", { staticClass: "row valign-wrapper" }, [
        _vm._m(0),
        _c("div", { staticClass: "col s10 l10" }, [
          _c("div", { staticClass: "row" }, [
            _c("p", { staticClass: "text-view-subtitle text-align-left" }, [
              _vm._v(_vm._s(_vm.texts.returnToPageWarning)),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col s12 l4 offset-l4" }, [
          _c(
            "div",
            {
              staticClass: "scandi-btn",
              attrs: { role: "button", "aria-label": "Log ind", tabindex: "0" },
              on: {
                click: _vm.proceedToVerification,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.proceedToVerification.apply(null, arguments)
                },
              },
            },
            [_vm._v("Log ind")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col s2 l2" }, [
      _c("img", {
        staticClass: "exclamation-icon",
        attrs: { src: "/static/exclamation-diamond.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }