var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-wrapper",
      class: { invalid: _vm.errors.has("inputfield") },
    },
    [
      _c("div", { staticClass: "input-field" }, [
        _vm.inputType === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.validationRules,
                  expression: "validationRules",
                },
              ],
              staticClass: "validate",
              attrs: {
                id: "inputdescription",
                name: "inputfield",
                id: _vm.inputTextField,
                tabindex: "0",
                "data-vv-as": _vm.inputTextField,
                readonly: _vm.editable == 1 ? false : true,
                autocomplete: "on",
                "aria-label": _vm.ariaLabel,
                "aria-disabled": _vm.editable == 1 ? false : true,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.inputValue)
                  ? _vm._i(_vm.inputValue, null) > -1
                  : _vm.inputValue,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
              },
            })
          : _vm.inputType === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.validationRules,
                  expression: "validationRules",
                },
              ],
              staticClass: "validate",
              attrs: {
                id: "inputdescription",
                name: "inputfield",
                id: _vm.inputTextField,
                tabindex: "0",
                "data-vv-as": _vm.inputTextField,
                readonly: _vm.editable == 1 ? false : true,
                autocomplete: "on",
                "aria-label": _vm.ariaLabel,
                "aria-disabled": _vm.editable == 1 ? false : true,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.inputValue, null) },
              on: {
                change: function ($event) {
                  _vm.inputValue = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.validationRules,
                  expression: "validationRules",
                },
              ],
              staticClass: "validate",
              attrs: {
                id: "inputdescription",
                name: "inputfield",
                id: _vm.inputTextField,
                tabindex: "0",
                "data-vv-as": _vm.inputTextField,
                readonly: _vm.editable == 1 ? false : true,
                autocomplete: "on",
                "aria-label": _vm.ariaLabel,
                "aria-disabled": _vm.editable == 1 ? false : true,
                type: _vm.inputType,
              },
              domProps: { value: _vm.inputValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                },
              },
            }),
        _c(
          "span",
          {
            staticClass: "error",
            attrs: { role: "alert", "aria-label": _vm.errorMessage },
          },
          [_vm._v(_vm._s(_vm.errorMessage))]
        ),
        _c(
          "label",
          {
            class: { active: _vm.inputValue != "" },
            attrs: { for: "inputdescription" },
          },
          [_vm._v(_vm._s(_vm.inputTextField))]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }