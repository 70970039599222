<template lang="pug">
include update-photo.pug
</template>

<style lang="scss" scoped>
@import 'update-photo';
</style>

<script>
import { createNamespacedHelpers } from 'vuex'

import { actionTypes } from '@/store/photo'
import { PhotoFlowSteps } from '@/models'
import router from '@/router'
const photoStore = createNamespacedHelpers('photo')

export default {
  name: 'UpdatePhoto',
  props: {
    photoFlowType: {
      type: String
    },
    photoId: {
      type: String
    }
  },
  computed: {
    ...photoStore.mapState({
      possiblePhoto: (state) => state.possiblePhoto
    })
  },
  methods: {
    ...photoStore.mapActions({
      process: actionTypes.PROCESS
    }),
    async onFileChange(e) {
      await this.process(e.target.files[0])
      router.push({
        name: `${this.photoFlowType}-${PhotoFlowSteps.Accept}`,
        params: { photoId: this.photoId }
      })
    },
    onTakePhoto: function () {}
  },
  mounted: function () {
    if (!this.possiblePhoto) {
      router.push({ name: `${this.photoFlowType}-${PhotoFlowSteps.Guidelines}` })
    }
  }
}
</script>
