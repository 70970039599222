<template lang="pug">
include personlist.pug
</template>

<script>
import Notification from '@/components/shared/notification'
import NavigationButtons from '@/components/shared/navigationButtons'
import services from '@/services'
import { createNamespacedHelpers } from 'vuex'
import { getterTypes, mutationTypes, actionTypes } from '@/store/application'
import ageUtils from '@/utils/ageUtils'
import { PhotoChoices } from '@/models'
import { FocusLoop } from '@vue-a11y/focus-loop'
import RestrictedPhotoWarningModal from '@/components/shared/modals/restrictedPhotoWarningModal'

const { mapState: mapGlobalState } = createNamespacedHelpers('globals')
const applicationStore = createNamespacedHelpers('application')

export default {
  name: 'personlist',
  components: {
    notification: Notification,
    navigationButtons: NavigationButtons,
    FocusLoop,
    RestrictedPhotoWarningModal
  },
  computed: {
    ...mapGlobalState({
      texts: (state) => state.texts.personList
    }),
    ...applicationStore.mapState({
      person: (state) => state.person,
      children: (state) => state.children,
      minimumPhotoAge: (state) => (state.settings.photo.ageRestrictions ? state.settings.photo.ageRestrictions.minimumAge : 0),
      people: (state) => [state.person, ...state.children],
      personApplications: (state) => state.personApplications,
      restrictedPhotoConfirmed: (state) => state.restrictedPhotoConfirmed
    }),
    ...applicationStore.mapGetters({
      personsCount: getterTypes.PERSONS_COUNT,
      photoChoicesMeta: getterTypes.AVAILABLE_PHOTO_CHOICES_META
    }),
    childPhotoBlocked: function () {
      return (
        this.photoChoicesMeta.some((i) => i.key === PhotoChoices.CenterPhoto) &&
        this.personApplications.some((i) => i.availablePhotoChoices.length === 1)
      )
    },
    previousStepAvailable: function () {
      return services.application.previousStepAvailable()
    },
    nextStepAllowed: function () {
      return this.personsCount > 0
    },
    ageRestrictionNotificationText: function () {
      return this.texts.centerPhotoBlockedNotificationPre + this.minimumPhotoAge + this.texts.centerPhotoBlockedNotificationPost
    },
    showRestrictedPhotoWarning: function () {
      return this.childPhotoBlocked && !this.restrictedPhotoConfirmed
    }
  },
  methods: {
    ...applicationStore.mapActions({
      initPersonApplications: actionTypes.INIT_PERSON_APPLICATION
    }),
    ...applicationStore.mapMutations({
      addPersonApplication: mutationTypes.ADD_PERSON_APPLICATION,
      removePersonApplication: mutationTypes.REMOVE_PERSON_APPLICATION,
      setRestrictedPhotoConfirmed: mutationTypes.SET_RESTRICTED_PHOTO_CONFIRMED
    }),
    isPersonSelected: function (personId) {
      return this.personApplications.some((i) => i.id === personId)
    },
    isSoonEighteen: function (cprNumber) {
      return ageUtils.turnsEighteenInNextfourtytwoDays(cprNumber)
    },
    onPersonToggled: function (personId) {
      if (this.isPersonSelected(personId)) {
        this.removePersonApplication(personId)
      } else {
        this.addPersonApplication(personId)
      }
    },
    acceptRestrictedPhotoWarning: async function () {
      this.setRestrictedPhotoConfirmed()
    },
    onPreviousStepClicked: function () {
      services.application.previousStep()
    },
    onNextStepClicked: function () {
      this.initPersonApplications()
      services.application.nextStep()
    }
  }
}
</script>
