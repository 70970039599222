var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "view-content-centered" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "row width-limit-hundred" }, [
          _c("div", { staticClass: "col s10 offset-s1 center-align" }, [
            _c("h2", { staticClass: "text-view-title" }, [
              _vm._v("Selvbetjeningen vedligeholdes "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row width-limit-hundred" }, [
          _c("div", { staticClass: "col s12 center-align" }, [
            _c("h2", { staticClass: "text-view-subtitle" }, [
              _vm._v("Sitet er nede lige nu. Beklager ulejligheden."),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }